import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetNotificationsByWorkspace } from '@/api/messages/useGetNotificationsByWorkspace';
import { Button } from '@/component-library/primitives/Button/Button';
import BellRingIcon from '@/component-library/primitives/Icons/BellRingIcon/BellRingIcon';
import ChatIcon from '@/component-library/primitives/Icons/ChatIcon/ChatIcon';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import EmailIcon from '@/component-library/primitives/Icons/EmailIcon/EmailIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import SelectCustom, { SelectCustomOption } from '@/component-library/widgets/SelectCustom/SelectCustom';
import Table from '@/component-library/widgets/Table/Table';
import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';
import { AppRoutes } from '@/utils/routes/router';

import { useDeliveryTable } from './useDeliveryTable';

const Delivery = () => {
  const { columns, selectOptions } = useDeliveryTable();
  const { data, isLoading } = useGetNotificationsByWorkspace();
  const [filter, setFilter] = useState<SelectCustomOption | null>(selectOptions[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filteredData = data?.filter((notif) => {
    if (filter?.value === '') {
      return data;
    } else {
      return notif.channel === filter?.value.toUpperCase();
    }
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const globalFilteredData = useMemo(() => {
    return filteredData?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(globalFilterValue.toLowerCase()),
      ),
    );
  }, [filteredData, globalFilterValue]);

  const menuOptions = [
    {
      icon: <ChatIcon />,
      name: t('DeliveryPage.buttonSms'),
      route: AppRoutes.newSMS,
    },
    { icon: <EmailIcon />, name: t('DeliveryPage.buttonEmail'), route: AppRoutes.newEmail },
    {
      icon: <BellRingIcon color="#F5F7FC" width="24" height="24" />,
      name: t('DeliveryPage.buttonPushNotification'),
      route: AppRoutes.newPush,
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <SearchField initialValue={globalFilterValue} setSearchValue={setGlobalFilterValue} />
        {globalFilteredData && globalFilteredData.length > 0 && (
          <div className="relative justify-end">
            <Button onClick={toggleDropdown} ref={dropdownRef} className="group !w-[10.5rem]">
              <span className="flex flex-row gap-3 justify-center items-center">
                <span>{t('DeliveryPage.buttonNewMessage')}</span>
                <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                  <ChevronIcon color="#1C222A" type={isDropdownOpen ? 'up' : 'down'} />
                </span>
              </span>
            </Button>
            {isDropdownOpen && (
              <div className="absolute right-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                {menuOptions.map((option, index) => (
                  <div
                    key={index}
                    className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                    onClick={() => navigate(option.route)}
                  >
                    <span className="group-hover:[&>*_path]:stroke-primary-400">{option.icon}</span>
                    <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : globalFilteredData && globalFilteredData.length > 0 ? (
        <>
          <SelectCustom options={selectOptions} filter={filter} setFilter={setFilter} />
          <Table
            columns={columns}
            data={globalFilteredData ?? []}
            className={{
              row: 'h-[3.300rem]',
              cell: 'overflow-visible',
            }}
          />
        </>
      ) : (
        <>
          <SelectCustom options={selectOptions} filter={filter} setFilter={setFilter} />
          <NoDataBoard
            description={t('NoDataBoard.sendYourFirstMessage')}
            actionButton={
              <div className="relative justify-end mt-4">
                <Button onClick={toggleDropdown} ref={dropdownRef} className="group !w-[10.5rem]">
                  <span className="flex flex-row gap-3 justify-center items-center">
                    <span>{t('DeliveryPage.buttonNewMessage')}</span>
                    <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                      <ChevronIcon color="#1C222A" type={isDropdownOpen ? 'up' : 'down'} />
                    </span>
                  </span>
                </Button>
                {isDropdownOpen && (
                  <div className="absolute left-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                    {menuOptions.map((option, index) => (
                      <div
                        key={index}
                        className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                        onClick={() => navigate(option.route)}
                      >
                        <span className="group-hover:[&>*_path]:stroke-primary-400">{option.icon}</span>
                        <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export default Delivery;
