import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionMeta, MultiValue } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useReviewSendSms } from '@/api/messages/useReviewSendSms';
import { useSendSms } from '@/api/messages/useSendSms';
import { useGetSegments } from '@/api/segments/useGetSegments';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import Chip from '@/component-library/primitives/Chip/Chip';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import GearIcon from '@/component-library/primitives/Icons/GearIcon/GearIcon';
import ImageIcon from '@/component-library/primitives/Icons/ImageIcon/ImageIcon';
import LoadingSpinnerIcon from '@/component-library/primitives/Icons/LoadingSpinner/LoadingSpinner';
import UsersIcon from '@/component-library/primitives/Icons/UsersIcon/UsersIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import PhoneDevice from '@/component-library/primitives/PhoneDevice/PhoneDevice';
import { RadioButton } from '@/component-library/primitives/RadioButton/RadioButton';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import { Input } from '@/component-library/widgets/Input/Input';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import SelectField, { OptionType } from '@/component-library/widgets/SelectField/SelectField';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import { Audience } from '@/models/NewEmail';
import { Segment } from '@/models/Segments';
import { SMSDetails, SMSReviewResponse } from '@/models/SendSMSDetails';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import protectedRoute from '@/utils/routes/PrivateRoute';
import { AppRoutes } from '@/utils/routes/router';

const NewSMSPage = () => {
  const sendSMSMutation = useSendSms();
  const smsReviewMutation = useReviewSendSms();
  const { data: segmentData, isFetched } = useGetSegments();
  const workspace = useAppSelector(selectCurrentWorkspace);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [activeStep, setActiveStep] = useState(0);
  const divWhereToScroll = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [deliveryScheduleType, setDeliveryScheduleType] = useState('Immediately');
  const [isEveryoneShouldReceiveMsgActive, setIsEveryoneShouldReceiveMsgActive] = useState(false);
  const [isSegmentsIconHovered, setIsSegmentsIconHovered] = useState(false);
  const [isDeliveryScheduleIconHovered, setIsIDeliveryScheduleconHovered] = useState(false);
  const [isAdvancedSettingsOptionSelected, setIsAdvancedSettingsOptionSelected] = useState(true);
  const [sharedOptionsState, setSharedOptionsState] = useState<OptionType[] | undefined>([]);
  const isActionAllowed = usePermissionChecker(PermissionsEnum.NOTIFICATIONS, HttpMethodsEnum.POST);
  const [reviewResponse, setReviewResponse] = useState<SMSReviewResponse | null>(null);

  const steps = [t('NewSMSPage.smsInfoStep'), t('NewSMSPage.messageEditorStep'), t('NewSMSPage.previewStep')];

  const availableSegments = segmentData?.map((segment) => {
    return {
      label: segment.name,
      value: segment.id,
    };
  });

  interface GenerateSendDataParams {
    formData: any;
    workspaceId: string | undefined;
    isEveryoneShouldReceiveMsgActive: boolean;
    segmentData: Segment[] | undefined;
  }

  const generateSendData = ({
    formData,
    workspaceId,
    isEveryoneShouldReceiveMsgActive,
    segmentData,
  }: GenerateSendDataParams) => {
    const sendData = {
      workspaceId: workspaceId ?? '',
      title: formData.title.trim(),
      segmentsShouldInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, formData.segmentsShouldInclude),
      segmentsShouldNotInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, formData.segmentsShouldNotInclude),
      message: formData.message,
      deliveryScheduleType: formData.deliveryScheduleType,
      specificTime:
        formData.deliveryScheduleType === 'Specific Time' ? new Date(formData.scheduleTime).toISOString() : '',
    };
    return sendData;
  };

  const schema = useMemo(() => {
    return [
      yup.object({
        title: yup.string().trim().required(t('CommonErrors.messageTitleRequired')),
        segmentsShouldInclude: !isEveryoneShouldReceiveMsgActive
          ? yup
              .array()
              .required(t('NewSMSPage.atLeastOneSegmentToReceiveMsgRequired'))
              .nullable()
              .min(1, t('NewSMSPage.atLeastOneSegmentToReceiveMsgRequired'))
          : yup.array(),
        scheduleTime:
          deliveryScheduleType === 'Specific Time'
            ? yup
                .date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .required(t('NewSMSPage.scheduleDateTimeRequired'))
                .min(new Date(new Date().getTime() + 15 * 60000), t('NewSMSPage.scheduleDateTimeInFutureRequired'))
            : yup.string(),
        eventBasedTrigger:
          deliveryScheduleType === 'Event Based'
            ? yup.string().trim().required(t('NewSMSPage.eventNameRequired'))
            : yup.string(),
      }),
      yup.object({
        message: yup.string().required(t('CommonErrors.textRequired')),
      }),
      yup.object({}),
    ];
  }, [deliveryScheduleType, isEveryoneShouldReceiveMsgActive, t]);

  const currentValidationSchema = schema[activeStep];

  const methods = useForm<SMSDetails>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
    defaultValues: {
      deliveryScheduleType: 'Immediately',
    },
  });

  const {
    control,
    handleSubmit,
    trigger,
    register,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = methods;

  const watchMessage = watch('message');

  useEffect(() => {
    isFetched && setSharedOptionsState(availableSegments);
  }, [isFetched]);

  const sharedOptions = useRef<OptionType[]>([]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.delivery), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.delivery) : setActiveStep((prevActiveStep) => prevActiveStep - 1);
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onNext = async () => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
      if (activeStep === 1) {
        const formData = getValues();
        const workspaceId = workspace?.id;
        const sendData = generateSendData({ formData, workspaceId, isEveryoneShouldReceiveMsgActive, segmentData });

        smsReviewMutation.mutate(sendData, {
          onSuccess: (data) => {
            setReviewResponse(data);
          },
          onError: () => {
            setToastInfo({ message: t('NewSMSPage.toastError'), toastType: 'error' });
            setOpen(true);
          },
        });
      }
    }
  };

  const formatSegmentObject = (segmentData: Segment[] | undefined, selectedOptions: Audience[] | undefined) => {
    const arrayData = [];

    if (segmentData && selectedOptions) {
      for (let i = 0; i < segmentData.length; i++) {
        for (let j = 0; j < selectedOptions.length; j++) {
          if (segmentData[i].id === selectedOptions[j].value) {
            arrayData.push(segmentData[i]);
          }
        }
      }
    }
    return arrayData.map((item) => {
      return {
        id: item.id,
        name: item.name,
        PK: item.PK,
        SK: item.SK,
      };
    });
  };

  const onSubmit = (data: SMSDetails) => {
    const sendData = {
      workspaceId: workspace?.id ?? '',
      title: data.title.trim(),
      segmentsShouldInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldInclude),
      segmentsShouldNotInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldNotInclude),
      message: data.message,
      deliveryScheduleType: data.deliveryScheduleType,
      specificTime:
        deliveryScheduleType === 'Specific Time' ? new Date(getValues('scheduleTime') as string).toISOString() : '',
      eventBasedTrigger: deliveryScheduleType === 'Event Based' ? data.eventBasedTrigger : '',
    };
    sendSMSMutation.mutate(sendData, {
      onSuccess: () => {
        setToastInfo({ message: t('NewSMSPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('NewSMSPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const handleShouldEveryoneReceiveMsgToggle = (value: boolean) => {
    setIsEveryoneShouldReceiveMsgActive(value);
    if (!isEveryoneShouldReceiveMsgActive) {
      setValue('segmentsShouldInclude', []);
      setValue('segmentsShouldNotInclude', []);
      setSharedOptionsState(availableSegments);
      clearErrors('segmentsShouldInclude');
    }
  };

  const updateAvailableSegmentOptions = (multiValue: MultiValue<Audience>, action: ActionMeta<Audience>) => {
    if (sharedOptionsState) {
      if (action.removedValue) {
        const filteredSelectedOptions = sharedOptions.current.filter((i) => i.value !== action.removedValue?.value);
        sharedOptions.current = filteredSelectedOptions;
        setSharedOptionsState(availableSegments);
      } else if (action.removedValues) {
        for (let i = 0; i < sharedOptions.current.length; i++) {
          for (let j = 0; j < action.removedValues.length; j++) {
            if (sharedOptions.current[i].value === action.removedValues[j].value) {
              sharedOptions.current.splice(i, 1);
            }
          }
        }
        setSharedOptionsState(availableSegments);
      } else {
        sharedOptions.current.push(multiValue[multiValue.length - 1]);
        setSharedOptionsState(availableSegments);
      }
      for (const x of sharedOptions.current) {
        setSharedOptionsState((prev: OptionType[] | undefined) =>
          prev?.filter((item: OptionType) => item.value !== x.value),
        );
      }
    }
  };

  return (
    <div className={`${sendSMSMutation.isLoading ? 'relative' : 'static'} mb-[150px]`}>
      {sendSMSMutation.isLoading && (
        <div className="absolute w-full h-[80vh] z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div className="flex min-w-[40rem] items-start -ml-6" ref={divWhereToScroll}>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-[40rem]">
          <FormProvider {...methods}>
            <form className="space-y-6 min-w-[40rem] w-1/2">
              {/* SMS info step */}
              {activeStep === 0 && (
                <div>
                  <div className="mb-7">
                    <h1 className="text-white font-medium text-xl">{t('NewSMSPage.setUpSmsLabel')}</h1>
                  </div>
                  <div className="space-y-7">
                    <Input
                      placeholder={t('NewSMSPage.titlePlaceholder')}
                      label={t('NewSMSPage.titleLabel')}
                      {...register('title')}
                      error={errors.title?.message as string}
                    />

                    {/* Segments Section */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">{t('NewSMSPage.segmentsLabel')}</h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">{t('NewSMSPage.segmentsTooltip')}</div>
                          }
                          open={isSegmentsIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsSegmentsIconHovered(true);
                            } else {
                              setIsSegmentsIconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="flex flex-row items-center gap-x-2 mb-7">
                        <span className="font-normal text-sm leading-[24px] text-gray-200">
                          {t('NewSMSPage.everyoneShouldReceiveThisMessageLabel')}
                        </span>
                        <ToggleSwitch
                          value={isEveryoneShouldReceiveMsgActive}
                          onChange={handleShouldEveryoneReceiveMsgToggle}
                        />
                      </div>
                      <div
                        className={`space-y-3 ${
                          isEveryoneShouldReceiveMsgActive ? 'opacity-50 pointer-events-none' : ''
                        }`}
                      >
                        <SelectField
                          name="segmentsShouldInclude"
                          label={
                            <Trans
                              i18nKey="NewSMSPage.segmentsReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldInclude?.message ? 'text-errorRed-500' : 'text-primary-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('NewSMSPage.writeOrSelectLabel')}
                        />
                        <SelectField
                          name="segmentsShouldNotInclude"
                          label={
                            <Trans
                              i18nKey="NewSMSPage.segmentsNotReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldNotInclude?.message ? 'text-errorRed-500' : 'text-red-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldNotInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          multiValueChipColor="red"
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('NewSMSPage.writeOrSelectLabel')}
                        />
                      </div>
                      <div className="flex justify-start mt-5">
                        <Button
                          type="button"
                          variant="transparent"
                          iconLeft={
                            <span className="group-active:[&>*_path]:stroke-white">
                              <GearIcon color="#7898FB" />
                            </span>
                          }
                          className="group px-2"
                          onClick={() => navigate(AppRoutes.segments)}
                        >
                          {t('NewSMSPage.linkManageSegments')}
                        </Button>
                      </div>
                    </section>

                    {/* Delivery schedule */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">
                          {t('NewSMSPage.deliveryScheduleLabel')}
                        </h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">{t('NewSMSPage.deliveryTooltip')}</div>
                          }
                          open={isDeliveryScheduleIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsIDeliveryScheduleconHovered(true);
                            } else {
                              setIsIDeliveryScheduleconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className={`flex flex-col`}>
                        <div
                          className="w-fit flex items-center h-12"
                          onClick={() => setDeliveryScheduleType('Immediately')}
                        >
                          <RadioButton
                            label={t('NewSMSPage.sendNowLabel')}
                            {...register('deliveryScheduleType')}
                            value="Immediately"
                            className="w-fit"
                            checked={deliveryScheduleType === 'Immediately'}
                          />
                        </div>
                        <div className="flex flex-row gap-x-5 items-center h-12">
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Specific Time')}>
                            <RadioButton
                              label={t('NewSMSPage.scheduleDateAndTimeLabel')}
                              {...register('deliveryScheduleType')}
                              value="Specific Time"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Specific Time'}
                            />
                          </div>
                          {deliveryScheduleType === 'Specific Time' && (
                            <Input
                              type="datetime-local"
                              className="!w-[15.25rem]"
                              {...register('scheduleTime')}
                              error={errors.scheduleTime?.message as string}
                            />
                          )}
                        </div>
                        <div className={`flex flex-row gap-x-5 items-center h-12`}>
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Event Based')}>
                            <RadioButton
                              label={t('NewSMSPage.eventLabel')}
                              {...register('deliveryScheduleType')}
                              value="Event Based"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Event Based'}
                            />
                          </div>
                          {deliveryScheduleType === 'Event Based' && (
                            <Input
                              placeholder={t('NewSMSPage.eventNamePlaceholder')}
                              className="!w-[15.25rem]"
                              {...register('eventBasedTrigger')}
                              error={errors.eventBasedTrigger?.message as string}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewSMSPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          iconRight={
                            <span className="startFromScratchLabel group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('NewSMSPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Message editor step */}
              {activeStep === 1 && (
                <div className="flex justify-center">
                  <div className="flex flex-col items-center -ml-64">
                    <div className="flex flex-row gap-x-2 -mt-6">
                      <div className="3xl:w-[34.375rem] lg:w-[25rem] flex flex-col">
                        {/* Message */}
                        <section className="mt-6 space-y-3">
                          <h2 className="text-xs font-semibold text-yellow-500">{t('NewSMSPage.messageLabel')}</h2>
                          <div className="relative">
                            <Textarea
                              {...register('message')}
                              placeholder={t('NewSMSPage.messagePlaceholder')}
                              error={errors.message?.message}
                              label={t('NewSMSPage.typeMessageHereLabel')}
                            />
                            <button
                              type="button"
                              className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group absolute -top-1 right-0"
                            >
                              <span className="group-hover:[&>*_path]:stroke-gray-100">
                                <ImageIcon />
                              </span>
                            </button>
                            <span
                              className={`absolute ${
                                errors.message ? 'bottom-0' : '-bottom-5'
                              } right-0 font-normal text-xs leading-[14px] tracking-[0.02em] text-secondary-400`}
                            >
                              {t('NewSMSPage.smsCountLabel')} :{' '}
                              {watchMessage ? Math.ceil(watchMessage.length / 160) : 1}
                            </span>
                          </div>
                        </section>
                      </div>
                      <div className="3xl:w-[27.063rem] lg:w-[25rem] flex flex-col items-end">
                        <PhoneDevice>
                          <>
                            {watchMessage && (
                              <div className="absolute top-20 left-[3.125rem]">
                                <div className="relative max-w-[16.5rem] min-h-[2rem] max-h-[30rem] bg-gray-600 rounded-2xl px-[10px] py-4 text-white font-normal text-xs leading-[14px] tracking-[0.02em] mb-1 [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                                  <div className="bg-gray-600 relative z-50 p-[1px] overflow-y-auto max-h-[28rem] [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                                    {watchMessage}
                                  </div>
                                  <div className="absolute bottom-0 -left-1">
                                    <svg
                                      width="27"
                                      height="27"
                                      viewBox="0 0 27 27"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.05094 11.7131C7.83637 10.5843 7.7151 9.40885 7.7151 8.21474V0.0145416H26.0466V26.5463C21.606 26.5463 17.5385 24.9697 14.3667 22.3389C11.2041 24.4472 6.12916 26.453 0 25.3335C1.68855 24.6058 8.2002 20.2678 7.95765 11.5825C7.98564 11.6198 8.01363 11.6665 8.05094 11.7038V11.7131Z"
                                        fill="#47546B"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <span className="font-normal text-xs leading-[14px] tracking-[0.02em] text-secondary-400">
                                  {t('NewSMSPage.smsCountLabel')} :{' '}
                                  {watchMessage ? Math.ceil(watchMessage.length / 160) : 1}
                                </span>
                              </div>
                            )}
                          </>
                        </PhoneDevice>
                      </div>
                    </div>
                  </div>

                  {/* Advanced settings */}
                  <div className="absolute top-[8.25rem] right-0 px-8 h-attributesSection 3xl:w-[23rem] lg:w-[20rem] border-l border-gray-800 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                    <h2 className="text-xs font-semibold text-yellow-500 mb-4">
                      {t('NewSMSPage.advancedSettingsLabel')}
                    </h2>
                    <div className="mb-3">
                      <div className="bg-darkBlue w-fit p-1 rounded-xl flex flex-row gap-x-2 relative">
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'primary' : 'transparent'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(true)}
                        >
                          {t('NewSMSPage.buttonEmailAddress')}
                        </Button>
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'transparent' : 'primary'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(false)}
                        >
                          {t('NewSMSPage.buttonFiltering')}
                        </Button>
                      </div>
                    </div>
                    {isAdvancedSettingsOptionSelected ? (
                      <>
                        <SearchField withFilter={false} className="3xl:!w-[16.8rem] lg:!w-[13.5rem]" />
                        <h2 className="text-xs font-semibold text-yellow-500 mt-8">{t('NewSMSPage.resultsLabel')}</h2>
                      </>
                    ) : (
                      <h2 className="text-xs font-semibold text-yellow-500 mt-8">FILTERS</h2>
                    )}
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewSMSPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('NewSMSPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('NewSMSPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Preview step */}
              {activeStep === 2 && (
                <div className="w-[31.938rem]">
                  <h1 className="text-white font-medium text-xl mb-2">{t('NewSMSPage.previewLabel')}</h1>
                  <Input
                    placeholder={t('NewSMSPage.titlePlaceholder')}
                    label={t('NewSMSPage.titleLabel')}
                    {...register('title')}
                    error={errors.title?.message as string}
                    value={getValues('title')}
                    className="pointer-events-none"
                  />
                  <section className="mt-3 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('NewSMSPage.audienceReceivingSmsLabel')}
                    </h2>
                    {isEveryoneShouldReceiveMsgActive && (
                      <span className="font-normal text-sm leading-[24px] text-gray-200">
                        {t('NewSMSPage.everyoneShouldReceiveThisMessageLabel')}
                      </span>
                    )}
                    {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-primary-400">
                          {t('NewSMSPage.belongToSegmentLabel')}
                        </span>
                        {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-red-400">
                          {t('NewSMSPage.dontBelongToSegmentLabel')}
                        </span>

                        {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldNotInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </section>

                  <section className="mt-4 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('NewSMSPage.validNumbersCountLabel')}
                    </h2>
                    <div className="flex flex-row items-center gap-2">
                      <UsersIcon color="#9BAFD0" />

                      {reviewResponse ? (
                        <span className="text-gray-50">{reviewResponse.data.validNumbers ?? 0}</span>
                      ) : (
                        <LoadingSpinnerIcon />
                      )}
                    </div>
                  </section>

                  <section className="mt-4 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('NewSMSPage.deliveryScheduleLabel')}
                    </h2>
                    <div>
                      {getValues('deliveryScheduleType') === 'Immediately' ? (
                        <RadioButton
                          label={t('NewSMSPage.sendNowLabel')}
                          {...register('deliveryScheduleType')}
                          value="Immediately"
                          className="w-fit pointer-events-none"
                        />
                      ) : getValues('deliveryScheduleType') === 'Event Based' ? (
                        <div className="flex flex-row gap-x-5 items-center">
                          <div className="w-fit">
                            <RadioButton
                              label={t('NewSMSPage.eventLabel')}
                              {...register('deliveryScheduleType')}
                              value="Event Based"
                              className="w-fit pointer-events-none"
                            />
                          </div>
                          <Input
                            className="!w-[15.25rem] pointer-events-none"
                            {...register('eventBasedTrigger')}
                            error={errors.eventBasedTrigger?.message as string}
                            value={getValues('eventBasedTrigger')}
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row gap-x-5 items-center">
                          <div className="w-fit">
                            <RadioButton
                              label={t('NewSMSPage.scheduleDateAndTimeLabel')}
                              {...register('deliveryScheduleType')}
                              value="Specific Time"
                              className="w-fit pointer-events-none"
                            />
                          </div>
                          <Input
                            type="datetime-local"
                            className="!w-[15.25rem] pointer-events-none"
                            {...register('scheduleTime')}
                            error={errors.scheduleTime?.message as string}
                            value={getValues('scheduleTime')}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  <div className="flex flex-col items-center mt-6">
                    <PhoneDevice>
                      <>
                        {watchMessage && (
                          <div className="absolute top-20 left-[3.125rem]">
                            <div className="relative max-w-[16.5rem] min-h-[2rem] max-h-[30rem] bg-gray-600 rounded-2xl px-[10px] py-4 text-white font-normal text-xs leading-[14px] tracking-[0.02em] mb-1 [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                              <div className="bg-gray-600 relative z-50 p-[1px] overflow-y-auto max-h-[28rem] [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                                {watchMessage}
                              </div>
                              <div className="absolute bottom-0 -left-1">
                                <svg
                                  width="27"
                                  height="27"
                                  viewBox="0 0 27 27"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.05094 11.7131C7.83637 10.5843 7.7151 9.40885 7.7151 8.21474V0.0145416H26.0466V26.5463C21.606 26.5463 17.5385 24.9697 14.3667 22.3389C11.2041 24.4472 6.12916 26.453 0 25.3335C1.68855 24.6058 8.2002 20.2678 7.95765 11.5825C7.98564 11.6198 8.01363 11.6665 8.05094 11.7038V11.7131Z"
                                    fill="#47546B"
                                  />
                                </svg>
                              </div>
                            </div>
                            <span className="font-normal text-xs leading-[14px] tracking-[0.02em] text-secondary-400">
                              {t('NewSMSPage.smsCountLabel')} :{' '}
                              {watchMessage ? Math.ceil(watchMessage.length / 160) : 1}
                            </span>
                          </div>
                        )}
                      </>
                    </PhoneDevice>
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('NewSMSPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('NewSMSPage.buttonPrevious')}
                        </Button>
                        <Button
                          className="px-4 !w-40"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={sendSMSMutation.isLoading || sendSMSMutation.isSuccess}
                        >
                          {t('NewSMSPage.buttonSend')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};

export default protectedRoute(NewSMSPage);
