interface TabMenuProps {
  tabLabels: string[];
  changeActiveStep: (step: string) => void;
  activeStep: string;
}

const TabMenu: React.FC<TabMenuProps> = ({ tabLabels, activeStep, changeActiveStep }) => {
  return (
    <div className="flex flex-row xl:w-[75%] 3xl:w-[80%]">
      {tabLabels.map((tabLabel, index) => {
        return (
          <div
            className={`w-[20%] py-4 border-b-2 text-center cursor-pointer text-sm font-normal ${
              activeStep === tabLabel ? 'border-b-primary-400 text-gray-50' : 'border-b-gray-800 text-gray-400'
            }`}
            key={index}
            onClick={() => changeActiveStep(tabLabel)}
          >
            {tabLabel}
          </div>
        );
      })}
    </div>
  );
};

export default TabMenu;
