const NoDataSectionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="59" viewBox="0 0 82 59" fill="none">
      <path
        d="M7.5 59L13.2082 6.67466C13.3742 5.15275 14.6596 4 16.1905 4H29.6365C30.8502 4 32.0354 4.36812 33.0356 5.05575L37.7084 8.26829C38.8753 9.07053 40.2581 9.5 41.6741 9.5H72.1482C73.9362 9.5 75.3273 11.0542 75.1299 12.8313L70 59H7.5Z"
        fill="#5D6282"
      />
      <g filter="url(#filter0_b_1442_7238)">
        <path
          d="M70 59H7.5L1.00063 14.4329C0.736672 12.6229 2.14006 11 3.96923 11H15.8953C17.2577 11 18.5796 11.4637 19.6435 12.3148L23.8565 15.6852C24.9204 16.5363 26.2423 17 27.6047 17H60.4586C61.9251 17 63.1767 18.0602 63.4178 19.5068L70 59Z"
          fill="#8187AF"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter1_b_1442_7238)">
        <path
          d="M40.5 16.5556C40.5 25.1467 47.4645 32.1111 56.0556 32.1111C64.6467 32.1111 71.6111 25.1467 71.6111 16.5556C71.6111 7.96446 64.6467 1 56.0556 1C47.4645 1 40.5 7.96446 40.5 16.5556Z"
          fill="#CDD7ED"
          fillOpacity="0.11"
        />
        <path
          d="M67.1667 27.6667L80.5 41M56.0556 32.1111C47.4645 32.1111 40.5 25.1467 40.5 16.5556C40.5 7.96446 47.4645 1 56.0556 1C64.6467 1 71.6111 7.96446 71.6111 16.5556C71.6111 25.1467 64.6467 32.1111 56.0556 32.1111Z"
          stroke="#7898FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1442_7238"
          x="-11.1412"
          y="-1.11"
          width="93.2512"
          height="72.22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1442_7238" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1442_7238" result="shape" />
        </filter>
        <filter
          id="filter1_b_1442_7238"
          x="37.5"
          y="-2"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1442_7238" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1442_7238" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NoDataSectionIcon;
