import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { Notification, NotificationsByWorkspace } from '@/models/NotificationsByWorkspace';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import queryKeys from '@/modules/shared/query/queryKeys';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function getNotificationsByWorkspace(token: string | undefined, workspaceId: string) {
  const response = await axios.get<NotificationsByWorkspace>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/notifications`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetNotificationsByWorkspace() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);

  return useQuery<Notification[]>({
    queryKey: [queryKeys.notifications, workspace?.id],
    queryFn: () => getNotificationsByWorkspace(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  });
}
