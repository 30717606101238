import { useMutation } from 'react-query';
import axios from 'axios';

import SendSMSDetails from '@/models/SendSMSDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function sendSMS(data: SendSMSDetails, token: string | undefined) {
  const response = await axios.post<SendSMSDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/sms/schedule`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useSendSms() {
  const { user } = useAuth();
  return useMutation<SendSMSDetails, Error, SendSMSDetails>((data) =>
    sendSMS(data, user?.signInUserSession.idToken.jwtToken),
  );
}
