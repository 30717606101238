import { SVGProps } from 'react';

type BlotVariant = 'success' | 'info';
interface BlotIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  variant?: BlotVariant;
}

const BlotIcon: React.FC<BlotIconProps> = ({ color = '#065F46', variant = 'success', ...props }) => {
  if (variant === 'success') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="64" viewBox="0 0 96 64" fill="none">
        <mask
          id="mask0_1200_7160"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="96"
          height="64"
        >
          <rect width="96" height="64" rx="8" fill="#059669" />
        </mask>
        <g mask="url(#mask0_1200_7160)">
          <circle cx="17.6177" cy="33.5323" r="5.53228" fill={color} />
          <circle cx="53.0244" cy="56.3989" r="2.21291" fill={color} />
          <path
            d="M52.6347 34.5619C54.4519 39.0393 52.2954 44.142 47.818 45.9592C47.3241 46.1597 46.8226 46.3118 46.3187 46.4177C43.5149 47.0074 40.3835 47.8094 38.8832 50.2504C37.1316 53.1003 38.0889 56.8014 40.6755 58.9228C45.8327 63.1526 50.0059 68.7276 52.5985 75.3858C60.6468 96.0552 50.4154 119.336 29.746 127.384C9.0766 135.432 -14.2037 125.201 -22.2521 104.531C-30.3004 83.862 -20.069 60.5817 0.600365 52.5333C9.62835 49.018 19.1544 48.99 27.6918 51.7959C30.7841 52.8122 34.3097 51.8492 36.0141 49.0761L36.6478 48.045C37.9047 46.0001 37.3233 43.3665 36.4207 41.1425C34.6035 36.6651 36.76 31.5623 41.2374 29.7452C45.7148 27.928 50.8176 30.0845 52.6347 34.5619Z"
            fill={color}
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="97" height="64" viewBox="0 0 97 64" fill="none">
        <mask
          id="mask0_2286_4796"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="97"
          height="64"
        >
          <rect width="96.91" height="64" rx="8" fill={color} />
        </mask>
        <g mask="url(#mask0_2286_4796)">
          <ellipse cx="64.673" cy="24.7748" rx="6.83905" ry="6.77483" fill={color} />
          <ellipse cx="52.3674" cy="44.5562" rx="2.58637" ry="2.56208" fill={color} />
          <ellipse cx="20.5272" cy="22.1719" rx="1.72424" ry="1.70805" fill={color} />
          <path
            d="M62.1074 63.0454C81.4187 80.1789 83.0525 109.576 65.7566 128.706C48.4607 147.836 18.7847 149.455 -0.526648 132.321C-19.838 115.188 -21.4718 85.7903 -4.17587 66.6603C4.1196 57.4852 15.2629 52.3383 26.7447 51.3566C29.4171 51.1281 31.6201 49.0088 31.615 46.3517L31.613 45.3099C31.6106 44.0363 30.7878 42.9367 29.818 42.1006C27.0322 39.6988 26.7393 35.5146 29.1639 32.755C31.5884 29.9953 35.8122 29.7052 38.598 32.107C41.3839 34.5088 41.6767 38.6929 39.2522 41.4526C39.0457 41.6876 38.8261 41.9047 38.5956 42.1038C36.9487 43.5255 35.1885 45.2621 35.1926 47.426C35.1969 49.6717 36.9291 51.524 39.16 51.9222C47.4248 53.3976 55.3927 57.0879 62.1074 63.0454Z"
            fill={color}
          />
        </g>
      </svg>
    );
  }
};

export default BlotIcon;
