import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGetIsUserApproved } from '@/api/users/useGetIsUserApproved';
import { useRegisterUser } from '@/api/users/useRegisterUser';
import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import LogoIcon from '@/component-library/primitives/Icons/LogoIcon/LogoIcon';
import Link from '@/component-library/primitives/Link/Link';
import Loader from '@/component-library/primitives/Loader/Loader';
import { Input } from '@/component-library/widgets/Input/Input';
import { PhoneCodeInput } from '@/component-library/widgets/PhoneCodeInput/PhoneCodeInput';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import { RegistrationData } from '@/models/RegistrationData';
import { AppRoutes } from '@/utils/routes/router';

const SignUpPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? '';
  const { data, isLoading: isUserApprovedLoading, isError } = useGetIsUserApproved(searchParams.get('id') ?? '', email);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        userEmail: yup
          .string()
          .email(t('CommonErrors.emailFormatValidation'))
          .required(t('CommonErrors.emailRequired')),
        tenantName: yup.string().required(t('CommonErrors.tenantNameRequired')),
        tenantAddress: yup.string().required(t('CommonErrors.tenantAddressRequired')),
        tenantPhone: yup.string().required(t('CommonErrors.tenantPhoneRequired')),
        // messagingGoal: yup.string().required('Messaging goal is required!'),
        firstName: yup.string().required(t('CommonErrors.firstNameRequired')),
        lastName: yup.string().required(t('CommonErrors.lastNameRequired')),
        userPhone: yup.string().required(t('CommonErrors.userPhoneRequired')),
      })
      .required();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Omit<RegistrationData, 'tenantTier'>>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (email) {
      setValue('userEmail', email);
    }
  }, [email, setValue]);

  const registerUserMutation = useRegisterUser();
  const isLoading = registerUserMutation.isLoading;
  const error = registerUserMutation.error as string;
  const onSubmit = async (data: Omit<RegistrationData, 'tenantTier'>) => {
    try {
      await registerUserMutation.mutateAsync({ ...data, tenantTier: 'BASIC', inquiryId: searchParams.get('id') ?? '' });
      navigate(AppRoutes.verifyEmail, { state: { email: data.userEmail } });
    } catch (error) {
      console.log(error);
    }
  };

  if (isUserApprovedLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center text-white">
        <Loader />
      </div>
    );
  } else if (data?.status === true && !isError) {
    return (
      <AuthLayout
        title={t('SignUpPage.title')}
        content={t('SignUpPage.subtitle')}
        contentClass="text-center"
        variant="one-column"
      >
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <Loader />
          </div>
        )}
        {error && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            <CircleWarningIcon /> {t('SignUpPage.errorOccurred')}
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
          <Input
            placeholder={t('SignUpPage.userEmailPlaceholder')}
            {...register('userEmail')}
            label={t('SignUpPage.userEmailLabel')}
            error={errors.userEmail?.message}
            data-cy="userEmail"
            value={email}
            disabled={true}
          />
          <Input
            placeholder={t('SignUpPage.tenantNamePlaceholder')}
            {...register('tenantName')}
            label={t('SignUpPage.tenantNameLabel')}
            error={errors.tenantName?.message}
            data-cy="tenantName"
          />
          <Input
            placeholder={t('SignUpPage.tenantAddressPlaceholder')}
            {...register('tenantAddress')}
            label={t('SignUpPage.tenantAddressLabel')}
            error={errors.tenantAddress?.message}
            data-cy="tenantAddress"
          />
          <PhoneCodeInput
            placeholder={t('SignUpPage.tenantPhonePlaceholder')}
            name="tenantPhone"
            control={control}
            label={t('SignUpPage.tenantPhoneLabel')}
            error={errors.tenantPhone?.message}
            className="mb-2 tenantPhone"
          />
          <SelectField
            name="dataCenter"
            label={t('SignUpPage.dataCenterPlaceholder')}
            control={control}
            options={[{ label: 'eu-central-1', value: 'eu-central-1' }]}
            errorMsg={errors.dataCenter?.message}
            className={'mb-2'}
            data-cy="dataCenter"
          />
          <PhoneCodeInput
            placeholder={t('SignUpPage.userPhonePlaceholder')}
            name="userPhone"
            control={control}
            error={errors.userPhone?.message}
            label={t('SignUpPage.userPhonePlaceholder')}
            className="userPhone"
          />
          <Input
            placeholder={t('SignUpPage.firstNamePlaceholder')}
            {...register('firstName')}
            error={errors.firstName?.message}
            label={t('SignUpPage.firstNamePlaceholder')}
            data-cy="firstName"
          />
          <Input
            placeholder={t('SignUpPage.lastNamePlaceholder')}
            {...register('lastName')}
            error={errors.lastName?.message}
            label={t('SignUpPage.lastNamePlaceholder')}
            data-cy="lastName"
          />

          {/* TODO: re-implement the radio buttons */}
          {/* <label className="text-white">My primary messaging goal is:</label> */}
          {/* <div className="flex flex-col">
            <div>
              <input type="radio" id="1" value="1" {...register('messagingGoal')} />
              <label htmlFor="1" className="text-white ml-2">
                Convert marketing leads
              </label>
            </div>
            <div>
              <input type="radio" id="2" value="2" {...register('messagingGoal')} />
              <label htmlFor="2" className="text-white ml-2">
                Send transactional emails
              </label>
            </div>
            <div>
              <input type="radio" id="3" value="3" {...register('messagingGoal')} />
              <label htmlFor="3" className="text-white ml-2">
                Onboard or retain customers
              </label>
            </div>
            <div>
              <input type="radio" id="4" value="4" {...register('messagingGoal')} />
              <label htmlFor="4" className="text-white ml-2">
                Other
              </label>
            </div>
          </div> */}

          <Button className="w-full" data-cy="buttonRegisterSubmit">
            {t('SignUpPage.buttonRegister')}
          </Button>
        </form>
        <div className="flex">
          <p className="inline text-gray-400 text-sm">{t('SignUpPage.linkAlreadyHaveAnAccount')}</p>
          <Link
            className="inline text-primary-400 text-sm"
            to={AppRoutes.signIn}
            label={t('SignUpPage.linkAlreadyHaveAnAccountLogIn')}
          />
        </div>
      </AuthLayout>
    );
  } else {
    return (
      <div>
        <header className="max-w-[75%] mx-auto flex justify-center pt-10">
          <LogoIcon withText />
        </header>
        <div className="top-0 left-0 right-0 bottom-0 flex flex-col justify-between h-[80vh] px-2">
          <div className="flex flex-col justify-center items-center p-8 w-full max-w-[75%] m-auto h-fit">
            <div className="flex flex-col items-center w-authFieldsContainer">
              <p className="text-white mb-5 text-center">{t('SignUpPage.forbiddenAccessMsg')}</p>
              <Link to={AppRoutes.dashboard} label={t('PageNotFound.backBtn', { pageName: 'Home' })} />
            </div>
          </div>
        </div>
        <footer className="text-gray-300 text-center mb-4">{t('AuthLayout.footer')}</footer>
      </div>
    );
  }
};

export default SignUpPage;
