interface RoleCardProps {
  roleLabel: string;
  description: string;
  isRoleSelected: (role: string) => boolean;
  handleRoleSelect: (role: string) => void;
}

const RoleCard: React.FC<RoleCardProps> = ({ roleLabel, description, isRoleSelected, handleRoleSelect }) => {
  return (
    <section
      className={`flex-1 min-w-[49%] max-w-[49%] h-[8rem] px-6 pt-6 pb-8 rounded-[10px] bg-darkBlue cursor-pointer ${
        !isRoleSelected(roleLabel) && 'opacity-50'
      }`}
      onClick={() => handleRoleSelect(roleLabel)}
    >
      <div className="flex flex-row items-center gap-5 h-full">
        <div className="flex flex-col">
          <h2 className="text-secondary-400 text-sm mb-1">{roleLabel}</h2>
          <p className="text-gray-400 text-xs">{description}</p>
        </div>
      </div>
    </section>
  );
};

export default RoleCard;
