import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/component-library/primitives/Button/Button';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import DeviceIcon from '@/component-library/primitives/Icons/DeviceIcon/DeviceIcon';

interface DevicesSectionProps {
  devices: { name: string }[] | undefined;
  changeActiveStep: (step: string) => void;
}

const DevicesSection: React.FC<DevicesSectionProps> = ({ devices, changeActiveStep }) => {
  const { t } = useTranslation();
  return (
    <div className="px-5 pt-5 pb-7 w-full min-h-[20rem] max-h-[20rem] rounded-[10px] bg-darkBlue">
      <div className="flex flex-row items-center justify-between mb-6">
        <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">{t('MemberProfilePage.devicesLabel')}</h2>
        <button
          onClick={() => changeActiveStep(t('MemberProfilePage.devicesTabLabel'))}
          className="group hover:bg-iconButtonHoverBG rounded-lg w-8 h-8 flex items-center justify-center"
        >
          <span className="group-hover:[&>*_path]:stroke-gray-400">
            <ChevronNewIcon type="right" color="white" />
          </span>
        </button>
      </div>
      <div className="h-[13.5rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {devices && devices.length < 1 && (
          <div className="h-[13.5rem]">
            <div className="flex flex-col gap-4 items-center justify-center h-full">
              <DeviceIcon />
              <div className="flex flex-col gap-y-1 items-center justify-center">
                <span className="text-gray-50 text-sm font-normal text-center tracking-[0.28px]">
                  {t('MemberProfilePage.addDevicesLabel')}
                </span>
                <span className="text-gray-400 text-center text-xs font-normal tracking-[0.24px]">
                  {t('MemberProfilePage.noDevicesDescription')}
                </span>
                <Button className="mt-5 lg:!w-[17rem] 3xl:!w-[19.875rem]">
                  {t('MemberProfilePage.buttonViewSDKDocumentation')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicesSection;
