import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as yup from 'yup';

import { useUpdateTemplate } from '@/api/templates/useUpdateTemplate';
import { Button } from '@/component-library/primitives/Button/Button';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import HtmlEditor from '@/component-library/widgets/HtmlEditor/HtmlEditor';
import { Input } from '@/component-library/widgets/Input/Input';
import { EditTemplateDetails } from '@/models/TemplateDetails';
import { AppRoutes } from '@/utils/routes/router';

const EditTemplate = () => {
  const location = useLocation();
  const templateData = location.state ? location.state.template : null;
  const updateTemplateMutation = useUpdateTemplate();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState('');
  const [formState, setFormState] = useState<EditTemplateDetails>({
    id: templateData.id,
    name: templateData.name,
    content: templateData.content,
  });

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    setHtmlContent(templateData.content);
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => {
        navigate(AppRoutes.templates);
      }, 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, templateData, toastInfo]);

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t('CommonErrors.nameRequired')),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditTemplateDetails>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: EditTemplateDetails) => {
    const sendData = {
      id: templateData.id,
      name: data.name,
      content: htmlContent,
    };

    updateTemplateMutation.mutate(sendData as EditTemplateDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('EditTemplatePage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('EditTemplatePage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const handleHTMLChange = (newHtml: string | undefined) => {
    setHtmlContent(newHtml || '');
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-[6.25rem]">
        <div className="flex flex-col items-center">
          <div className="mb-8 w-[40rem]">
            <h1 className="text-white font-medium text-xl mb-2">{t('EditTemplatePage.title')}</h1>
            <p className="text-gray-300 text-sm">{t('EditTemplatePage.subtitle')}</p>
          </div>
          <form className="min-w-[40rem] w-1/2" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <Input
                label={t('EditTemplatePage.nameLabel')}
                placeholder={t('EditTemplatePage.namePlaceholder')}
                {...register('name', { value: formState.name })}
                error={errors.name?.message as string}
                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
              />
              <div className="space-y-6 w-[170%] 3xl:-ml-[28%] lg:-ml-[13%]">
                <div className="flex flex-row gap-x-2 mt-2">
                  <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                    {htmlContent && <HtmlEditor handleHTMLChange={handleHTMLChange} htmlContentInit={htmlContent} />}
                  </div>
                  <div className="3xl:w-[31.063rem] lg:w-[25rem] 3xl:h-[39.5rem] lg:h-[32rem] flex flex-col">
                    <div className="h-full overflow-auto bg-darkBlue rounded-lg p-4 flex flex-col items-center [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
                      <div className="w-full min-w-fit mt-6 3xl:scale-x-[0.75] lg:scale-x-[0.61] scale-y-[1] editor-preview text-gray-300">
                        {parse(htmlContent)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[35rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.templates)}>
                  {t('EditTemplatePage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={updateTemplateMutation.isLoading || updateTemplateMutation.isSuccess}
                >
                  {t('EditTemplatePage.buttonUpdateTemplate')}
                </Button>
              </div>
            </div>
            {/* <div className="flex items-center justify-center fixed bottom-0 z-50 bg-applicationBgBlue overflow-hidden w-screenWithoutSideMenu left-[16rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[35rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.templates)}>
                  {t('EditTemplatePage.linkCancel')}
                </Button>
                <div className="flex flex-row gap-2">
                  <Button
                    type="button"
                    variant="previous"
                    className="pr-4 !w-[8.125rem]"
                    onClick={onBack}
                    iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                  >
                    {t('EditTemplatePage.buttonPrevious')}
                  </Button>
                  <Button
                    className="px-4 !w-52"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={updateTemplateMutation.isLoading || updateTemplateMutation.isSuccess}
                  >
                    {t('EditTemplatePage.buttonUpdateTemplate')}
                  </Button>
                </div>
              </div>
            </div> */}
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default EditTemplate;
