import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useGetAudienceByWorkspace } from '@/api/workspaces/useGetAudienceByWorkspace';
import { AppRoutes } from '@/utils/routes/router';

import CheckListIcon from '../Icons/CheckListIcon/CheckListIcon';
import FilesIcon from '../Icons/FilesIcon/FilesIcon';
import FolderIcon from '../Icons/FolderIcon/FolderIcon';
import GearIcon from '../Icons/GearIcon/GearIcon';
import HouseIcon from '../Icons/HouseIcon/HouseIcon';
import LayersIcon from '../Icons/LayersIcon/LayersIcon';
import PaperPlaneIcon from '../Icons/PaperPlaneIcon/PaperPlaneIcon';
import UsersIcon from '../Icons/UsersIcon/UsersIcon';
import VolumeIcon from '../Icons/VolumeIcon/VolumeIcon';
import WindowSidebarIcon from '../Icons/WindowSidebarIcon/WindowSidebarIcon';

interface BreadcrumbsProps {
  className?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = useGetAudienceByWorkspace();

  const routeMap = new Map([
    [AppRoutes.dashboard, { label: t('Breadcrumbs.linkDashboard'), icon: <HouseIcon color="#7085A8" /> }],
    [AppRoutes.buildMessage, { label: t('Breadcrumbs.linkBuildMessage'), icon: <PaperPlaneIcon color="#7085A8" /> }],
    [AppRoutes.newSMS, { label: t('Breadcrumbs.linkCreateNewSms'), icon: <PaperPlaneIcon color="#7085A8" /> }],
    [AppRoutes.newEmail, { label: t('Breadcrumbs.linkCreateNewEmail'), icon: <PaperPlaneIcon color="#7085A8" /> }],
    [AppRoutes.campaigns, { label: t('Breadcrumbs.linkCampaigns'), icon: <VolumeIcon color="#7085A8" /> }],
    [AppRoutes.createCampaign, { label: t('Breadcrumbs.linkCreateCampaign'), icon: <VolumeIcon color="#7085A8" /> }],
    [
      `${AppRoutes.campaigns}/${location.pathname.split('/')[2]}/delete`,
      { label: t('Breadcrumbs.linkDeleteCampaign'), icon: <VolumeIcon color="#7085A8" /> },
    ],
    [AppRoutes.delivery, { label: t('Breadcrumbs.linkDelivery'), icon: <LayersIcon color="#7085A8" /> }],
    [AppRoutes.members, { label: t('Breadcrumbs.linkMembers'), icon: <UsersIcon color="#7085A8" /> }],
    [
      `${AppRoutes.members}/${location.pathname.split('/')[2]}`,
      {
        label: data?.filter((audienceMember) => audienceMember.id === location.pathname.split('/')[2])[0]?.name,
        icon: <UsersIcon />,
      },
    ],
    [AppRoutes.createMember, { label: t('Breadcrumbs.linkCreateMember'), icon: <UsersIcon color="#7085A8" /> }],
    [AppRoutes.importMembers, { label: t('Breadcrumbs.linkImportMembers'), icon: <UsersIcon color="#7085A8" /> }],
    [AppRoutes.segments, { label: t('Breadcrumbs.linkSegments'), icon: <UsersIcon color="#7085A8" /> }],
    [
      `${AppRoutes.segments}/${location.pathname.split('/')[2]}/overview`,
      { label: t('Breadcrumbs.linkSegmentOverview'), icon: <UsersIcon color="#7085A8" /> },
    ],
    [AppRoutes.createSegment, { label: t('Breadcrumbs.linkCreateSegment'), icon: <UsersIcon color="#7085A8" /> }],
    [AppRoutes.activityLogs, { label: t('Breadcrumbs.linkActivityLogs'), icon: <CheckListIcon color="#7085A8" /> }],
    [AppRoutes.settings, { label: t('Breadcrumbs.linkSettings'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.teamMembers, { label: t('Breadcrumbs.linkTeamMembers'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.createTeamMember, { label: t('Breadcrumbs.linkAddTeamMember'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.account, { label: t('Breadcrumbs.linkCompanyAccount'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.personalAccount, { label: t('Breadcrumbs.linkPersonalAccount'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.apiCredentials, { label: t('Breadcrumbs.linkAPICredentials'), icon: <GearIcon color="#7085A8" /> }],
    [AppRoutes.workspaces, { label: t('Breadcrumbs.linkWorkspaces'), icon: <WindowSidebarIcon color="#7085A8" /> }],
    [
      `${AppRoutes.workspaces}/${location.pathname.split('/')[2]}`,
      { label: t('Breadcrumbs.linkWorkspaceDetails'), icon: <WindowSidebarIcon color="#7085A8" /> },
    ],
    [
      AppRoutes.createWorkspace,
      { label: t('Breadcrumbs.linkCreateWorkspace'), icon: <WindowSidebarIcon color="#7085A8" /> },
    ],
    [
      `${AppRoutes.workspaces}/${location.pathname.split('/')[2]}/delete`,
      { label: t('Breadcrumbs.linkDeleteWorkspace'), icon: <WindowSidebarIcon color="#7085A8" /> },
    ],
    [AppRoutes.templates, { label: t('Breadcrumbs.linkTemplates'), icon: <FilesIcon color="#7085A8" /> }],
    [AppRoutes.createTemplate, { label: t('Breadcrumbs.linkCreateTemplate'), icon: <FilesIcon color="#7085A8" /> }],
    [
      AppRoutes.newPush,
      { label: t('Breadcrumbs.linkCreatePushNotification'), icon: <PaperPlaneIcon color="#7085A8" /> },
    ],
    [
      AppRoutes.pushNotifications,
      { label: t('Breadcrumbs.linkPushNotifications'), icon: <GearIcon color="#7085A8" /> },
    ],
    [
      AppRoutes.pushNotificationsEnableIos,
      { label: t('Breadcrumbs.linkEnableIos'), icon: <GearIcon color="#7085A8" /> },
    ],
    [
      AppRoutes.pushNotificationsEnableAndroid,
      { label: t('Breadcrumbs.linkEnableAndroid'), icon: <GearIcon color="#7085A8" /> },
    ],
    [
      `${AppRoutes.templates}/${location.pathname.split('/')[2]}/edit`,
      { label: t('Breadcrumbs.linkEditTemplate'), icon: <FilesIcon color="#7085A8" /> },
    ],
    [
      `${AppRoutes.delivery}/${location.pathname.split('/')[2]}`,
      { label: t('Breadcrumbs.linkDeliveryOverview'), icon: <FolderIcon color="#7085A8" /> },
    ],
    [`${AppRoutes.domains}/overview`, { label: t('Breadcrumbs.domainOverview'), icon: <FolderIcon color="#7085A8" /> }],
    [`${AppRoutes.domains}`, { label: t('Breadcrumbs.domains'), icon: <FolderIcon color="#7085A8" /> }],
    [`${AppRoutes.createDomain}`, { label: t('Breadcrumbs.createDomain'), icon: <FolderIcon color="#7085A8" /> }],
  ]);

  let currentLink = '';

  const renderLink = (to: string) => (
    <Link to={to}>
      <span>{routeMap.get(to)?.label}</span>
    </Link>
  );

  const crumbs = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb, index) => {
      currentLink += `/${crumb}`;
      if (routeMap.get(currentLink)) {
        return (
          <Fragment key={index}>
            {index === 0 && <span className="mr-3">{routeMap.get(currentLink)?.icon}</span>}
            <div
              className="after:content-['\00a0\00a0\00a0/\00a0\00a0\00a0'] last:after:hidden text-gray-500 last:text-gray-100 text-sm"
              key={index}
            >
              {renderLink(currentLink)}
            </div>
          </Fragment>
        );
      }
    });

  return <div className={`flex flex-row ${className}`}>{crumbs}</div>;
};

export default Breadcrumbs;
