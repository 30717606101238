import React from 'react';
import { SVGProps } from 'react';

interface SignOutIcon extends SVGProps<SVGSVGElement> {
  color?: string;
}

function SignOutIcon({ color = '#ffffff', ...props }: SignOutIcon) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M2 7.21035C2 4.01192 4.40253 1.37463 7.50135 1.00472C7.91265 0.955626 8.25 1.29613 8.25 1.71035C8.25 2.12456 7.91202 2.45413 7.50288 2.51877C5.23448 2.87715 3.5 4.84119 3.5 7.21035V16.2103C3.5 18.5795 5.23448 20.5435 7.50288 20.9019C7.91202 20.9666 8.25 21.2961 8.25 21.7103C8.25 22.1246 7.91265 22.4651 7.50135 22.416C4.40253 22.0461 2 19.4088 2 16.2103V7.21035Z"
        fill={color}
      />
      <path
        d="M15.7197 6.21967C16.0126 5.92678 16.4874 5.92678 16.7803 6.21967L21.7803 11.2197C21.8522 11.2916 21.9065 11.3745 21.9431 11.4629C21.9798 11.5513 22 11.6483 22 11.75C22 11.9419 21.9268 12.1339 21.7803 12.2803L16.7803 17.2803C16.4874 17.5732 16.0126 17.5732 15.7197 17.2803C15.4268 16.9874 15.4268 16.5126 15.7197 16.2197L19.4393 12.5H9.25C8.83579 12.5 8.5 12.1642 8.5 11.75C8.5 11.3358 8.83579 11 9.25 11H19.4393L15.7197 7.28033C15.4268 6.98744 15.4268 6.51256 15.7197 6.21967Z"
        fill={color}
      />
    </svg>
  );
}

export default SignOutIcon;
