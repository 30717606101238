import { useTranslation } from 'react-i18next';

import LogoIcon from '@/component-library/primitives/Icons/LogoIcon/LogoIcon';
import Link from '@/component-library/primitives/Link/Link';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AppRoutes } from '@/utils/routes/router';

const PageNotFound = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const pageName = user ? 'Dashboard' : 'Home';
  return (
    <div>
      <header className="max-w-[75%] mx-auto pt-10">
        <LogoIcon withText />
      </header>
      <div className="top-0 left-0 right-0 bottom-0 flex flex-col justify-between h-[80vh] px-2">
        <div className="flex flex-col justify-center items-center p-8 w-full max-w-[75%] m-auto h-fit">
          <div className="flex flex-col items-center w-authFieldsContainer">
            <h3 className="text-custom-aliceBlue text-xl font-medium tracking-wide mb-3">{t('PageNotFound.title')}</h3>
            <div className="flex flex-col items-center justify-end relative h-40">
              <div className="flex flex-row items-center justify-center gap-24">
                <p className="text-9xl font-bold text-custom-aliceBlue">4</p>
                <img src="/images/404_image.svg" alt="Placeholder" className="h-auto w-[10.625rem] absolute bottom-4" />
                <p className="text-9xl font-bold text-custom-aliceBlue">4</p>
              </div>
            </div>
            <Link to={AppRoutes.dashboard} label={t('PageNotFound.backBtn', { pageName: pageName })} />
          </div>
        </div>
      </div>
      <footer className="text-gray-300 text-center my-2">{t('PageNotFound.footer')}</footer>
    </div>
  );
};

export default PageNotFound;
