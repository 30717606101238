import { useMutation } from 'react-query';
import axios from 'axios';

import { CreateTeamMemberDetails } from '@/models/TeamMembers';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createTeamMember(data: CreateTeamMemberDetails, token: string | undefined) {
  const response = await axios.post<CreateTeamMemberDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/team-members`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreateTeamMember() {
  const { user } = useAuth();
  return useMutation<CreateTeamMemberDetails, Error, CreateTeamMemberDetails>((data) =>
    createTeamMember(data, user?.signInUserSession.idToken.jwtToken),
  );
}
