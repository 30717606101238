import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { SegmentRule } from '@/models/Segments';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function precalculateSegment(data: SegmentRule, token: string | undefined, workspaceId: string) {
  const response = await axios.post<SegmentRule>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/audience/precalculate`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function usePrecalculateSegment() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<SegmentRule, Error, SegmentRule>((data) =>
    precalculateSegment(data, user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  );
}
