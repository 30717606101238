import { useTranslation } from 'react-i18next';

import NoDataSectionIcon from '@/component-library/primitives/Icons/NoDataSectionIcon/NoDataSectionIcon';

const NoDataSection = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center justify-center h-full">
      <NoDataSectionIcon />
      <span className="text-gray-50 text-sm font-normal tracking-[0.28px]">
        {t('MemberProfilePage.noDataYetLabel')}
      </span>
    </div>
  );
};

export default NoDataSection;
