/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart as BarChartRecharts, Tooltip, XAxis, YAxis } from 'recharts';

import YAxisComponent from '@/component-library/widgets/BarChart/YAxisComponent';
import SelectCustom, { SelectCustomOption } from '@/component-library/widgets/SelectCustom/SelectCustom';
import { EmailMetricsTimeline } from '@/models/EmailMetrics';
import { isLastDayOfTheMonth } from '@/utils/dateUtils';

const BAR_WIDTH = 80;
const CHART_HEIGHT = 300;
const CHART_WIDTH = 430;
const CHART_CONTENT_WIDTH = 1000;

const X_AXIS_ID_0 = 0;
const X_AXIS_ID_1 = 1;

const FILTER_DAY = 'day';
const FILTER_MONTH = 'month';
const FILTER_YEAR = 'year';

const ANIMATION_DURATION_MILISECONDS = 1000;

type BarChartDataType = EmailMetricsTimeline[];

const BarChart = ({ data, dataKeys = ['delivered', 'opened'] }: { data: BarChartDataType; dataKeys?: string[] }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const selectOptions = [
    // @ts-ignore
    { value: FILTER_DAY, label: t('DashboardPage.dayOption'), id: '1' },
    { value: FILTER_MONTH, label: t('DashboardPage.monthOption'), id: '2' },
    { value: FILTER_YEAR, label: t('DashboardPage.yearOption'), id: '3' },
  ];

  const [filter, setFilter] = useState<SelectCustomOption | null>(selectOptions[0]);
  const [filteredData, setFilteredData] = useState<BarChartDataType>([]);

  // chart width takes into account the amount of data we have (filteredData.length)
  const calculatedChartWidth =
    filteredData.length > 0 ? Math.max(filteredData.length * BAR_WIDTH, CHART_WIDTH) : CHART_CONTENT_WIDTH;

  const [firstLoadDone, setFirstLoadDone] = useState(false);

  // Data aggregation effect
  useEffect(() => {
    if (data.length === 0) {
      setFilteredData([]);
      return;
    }

    const newData = [] as BarChartDataType;
    let currentDataMonth = new Date(data[0].date).getUTCMonth();

    // Data aggregation by month
    if (filter?.value === FILTER_MONTH) {
      let monthDeliveredSum = 0;
      let monthOpenedSum = 0;
      let previousItem = data[0];
      data.forEach((currentItem) => {
        const previousItemDate = new Date(previousItem.date);
        const currentItemYear = new Date(currentItem.date).getUTCFullYear();
        const currentItemMonth = new Date(currentItem.date).getUTCMonth();

        // if we are in the current year and month - do the summing
        if (currentItemYear === previousItemDate.getFullYear() && currentItemMonth === previousItemDate.getUTCMonth()) {
          monthDeliveredSum += currentItem.delivered;
          monthOpenedSum += currentItem.opened;
        }
        if (
          currentItemMonth !== previousItemDate.getUTCMonth() ||
          currentItemYear !== previousItemDate.getUTCFullYear()
        ) {
          // finalize the sum for the currentDataMonth
          const curMonthNewData = {
            date: previousItem.date,
            delivered: monthDeliveredSum,
            opened: monthOpenedSum,
          };
          newData.push(curMonthNewData);

          if (
            isLastDayOfTheMonth(
              previousItemDate.getUTCDate(),
              previousItemDate.getUTCMonth(),
              previousItemDate.getUTCFullYear(),
            )
          ) {
            if (currentDataMonth === 11) {
              currentDataMonth = 0;
            }
            if (currentDataMonth < 11) {
              currentDataMonth++;
            }
          } else {
            currentDataMonth = currentItemMonth;
          }

          monthDeliveredSum = currentItem.delivered;
          monthOpenedSum = currentItem.opened;
        }
        previousItem = currentItem;
      });

      const lastMonthNewData = {
        date: previousItem.date,
        delivered: monthDeliveredSum,
        opened: monthOpenedSum,
      };
      newData.push(lastMonthNewData);
    }

    // Data aggregation by year
    if (filter?.value === FILTER_YEAR) {
      let yearDeliveredSum = 0;
      let yearOpenedSum = 0;
      let prevItem = data[0];
      data.forEach((currentItem) => {
        const prevItemYear = new Date(prevItem.date).getUTCFullYear();
        const currentItemYear = new Date(currentItem.date).getUTCFullYear();

        if (currentItemYear === prevItemYear) {
          yearDeliveredSum += currentItem.delivered;
          yearOpenedSum += currentItem.opened;
        }
        if (currentItemYear !== prevItemYear) {
          const curYearNewData = {
            date: prevItem.date,
            delivered: yearDeliveredSum,
            opened: yearOpenedSum,
          };

          newData.push(curYearNewData);

          yearDeliveredSum = currentItem.delivered;
          yearOpenedSum = currentItem.opened;
        }

        prevItem = currentItem;
      });

      const lastYearNewData = {
        date: prevItem.date,
        delivered: yearDeliveredSum,
        opened: yearOpenedSum,
      };
      newData.push(lastYearNewData);
    }

    if (filter?.value === FILTER_DAY) {
      setFilteredData(data);
    } else {
      // if filter is month or year
      setFilteredData(newData);
    }
  }, [filter, data]);

  const xAxisDateFormat = useCallback(
    (value: string) => {
      const date = new Date(value);
      // using `getUtcMonth() + 1` because UTC months start from 0
      if (filter?.value === FILTER_MONTH) return `${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`;

      if (filter?.value === FILTER_YEAR) return date.getUTCFullYear().toString();

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getUTCFullYear().toString().substring(2, 4)}`;
    },
    [filter],
  );

  const tooltipDateFormat = useCallback(
    (item: string) => {
      const tooltipDate = new Date(item);
      if (filter?.value === FILTER_DAY) return tooltipDate.toDateString();
      if (filter?.value === FILTER_MONTH) return `${tooltipDate.getUTCMonth() + 1}.${tooltipDate.getUTCFullYear()}`;
      if (filter?.value === FILTER_YEAR) return tooltipDate.getUTCFullYear();
    },
    [filter?.value],
  );

  const maxDataValue = useMemo(() => {
    let max = 0;
    filteredData?.forEach((item) => {
      if (item.delivered > max) {
        max = item.delivered;
      }
    });
    return max;
  }, [filteredData]);

  useEffect(() => {
    setTimeout(() => setFirstLoadDone(true), ANIMATION_DURATION_MILISECONDS);
  }, []);

  // necessary to scroll back to the beginning after the filter changes
  useEffect(() => {
    chartContainerRef.current?.scrollTo({ left: 0 });
  }, [filteredData]);

  if (data.length === 0) return <h2>No data</h2>;

  return (
    <div>
      <div className="flex flex-row justify-end pb-4">
        <SelectCustom options={selectOptions} filter={filter} setFilter={setFilter} />
      </div>
      <div className="flex flex-row">
        {/* Custom YAxis */}
        <YAxisComponent maxValue={maxDataValue} />

        {/* The actual scrollable chart content */}
        <div
          ref={chartContainerRef}
          style={{ width: CHART_WIDTH, height: CHART_HEIGHT, overflowX: 'auto' }}
          className="[&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-gray-800 [&::-webkit-scrollbar-thumb]:bg-gray-600 overflow-y-hidden"
        >
          <BarChartRecharts
            width={calculatedChartWidth}
            height={CHART_HEIGHT}
            data={filteredData}
            margin={{ top: 10, bottom: 20 }}
          >
            {/* Fixes the bug where the bars with highest value don't extend all the way up in the chart space */}
            <YAxis width={0} domain={[0, maxDataValue]} />

            <XAxis
              xAxisId={X_AXIS_ID_0}
              dataKey="date"
              stroke="#fff"
              tickFormatter={(value) => xAxisDateFormat(value)}
            />
            <XAxis xAxisId={X_AXIS_ID_1} hide />
            <Tooltip
              contentStyle={{ background: '#313B4BAA', border: 0, borderRadius: '8px', color: '#fff' }}
              cursor={false}
              labelFormatter={(item) => tooltipDateFormat(item)}
            />
            <Bar
              animationDuration={ANIMATION_DURATION_MILISECONDS}
              dataKey={dataKeys[0]}
              stackId="a"
              fill="#529F7C"
              xAxisId={X_AXIS_ID_0}
              barSize={55}
            />
            <Bar
              animationBegin={firstLoadDone ? 0 : 500}
              animationDuration={ANIMATION_DURATION_MILISECONDS}
              dataKey={dataKeys[1]}
              stackId="b"
              fill="#9BEBC6"
              xAxisId={X_AXIS_ID_1}
              barSize={40}
            />
          </BarChartRecharts>
        </div>
      </div>

      {/* Custom chart Legend */}
      <div className="flex flex-row justify-center items-center">
        <div className="flex items-baseline">
          <span
            style={{ background: '#529F7C', width: '20px', height: '10px', margin: '5px', display: 'inline-block' }}
          ></span>
          <span style={{ color: 'white', display: 'inline-block', height: '20px', margin: '5px' }}>delivered</span>
        </div>
        <div className="flex items-baseline">
          <span
            style={{ background: '#9BEBC6', width: '20px', height: '10px', margin: '5px', display: 'inline-block' }}
          ></span>
          <span style={{ color: 'white', display: 'inline-block', height: '20px', margin: '5px' }}>opened</span>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
