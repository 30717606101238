import { SVGProps, useState } from 'react';

interface ClockIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  isHoverable?: boolean;
  tooltipLabel?: string;
}

const ClockIcon: React.FC<ClockIconProps> = ({ color = '#F5F7FC', isHoverable = false, tooltipLabel, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 5V10H15M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {isHoverable && showTooltip && (
        <div className="absolute left-8 -bottom-[5px] bg-custom-darkBlue p-2 rounded-lg z-10 whitespace-nowrap">
          {tooltipLabel}
        </div>
      )}
    </div>
  );
};

export default ClockIcon;
