import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import * as yup from 'yup';

import { useChangeUserPassword } from '@/api/settings/useChangeUserPassword';
import { useUpdatePersonalData } from '@/api/settings/useUpdatePersonalData';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import PasswordHints from '@/component-library/widgets/PasswordHints/PasswordHints';
import { PersonalAccountDetails, PersonalAccountRequest } from '@/models/CompanyAccountDetails';
import { InAppChangePasswordData } from '@/models/PasswordData';
import { useAuth } from '@/modules/Auth/hooks/useAuth';

const PersonalAccount = () => {
  const { isLoading, signOut } = useAuth();
  const [user, setUser] = useState({
    attributes: {
      family_name: '',
      given_name: '',
    },
  });

  const updatePersonalDataMutation = useUpdatePersonalData();
  const changePasswordMutation = useChangeUserPassword();

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [isBasicDataInEditMode, setIsBasicDataInEditMode] = useState(false);
  const [isPasswordInEditMode, setIsPasswordInEditMode] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialCharacter, setContainsSpecialCharacter] = useState(false);
  const [containsMinCharacters, setContainsMinCharacters] = useState(false);

  const isPasswordValid =
    containsUppercase && containsLowercase && containsNumber && containsSpecialCharacter && containsMinCharacters;

  const changePasswordSchema = useMemo(() => {
    return yup
      .object()
      .shape({
        currentPassword: yup.string().required(t('PersonalAccountSettingsPage.currentPasswordRequired')),
        newPassword: yup
          .string()
          .required(t('CommonErrors.newPasswordRequired'))
          .matches(/[a-z]/, ' ')
          .matches(/[A-Z]/, ' ')
          .matches(/^.*(?=.*\d).*$/, ' ')
          .matches(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, ' ')
          .min(8, ' ')
          .max(64, t('CommonErrors.passwordMaxCharacters')),
        confirmNewPassword: yup
          .string()
          .required(t('CommonErrors.confirmPasswordRequired'))
          .oneOf([yup.ref('newPassword')], t('CommonErrors.passwordMismatch')),
      })
      .required();
  }, []);

  const [isPasswordInputFocused, setPasswordInputFocused] = useState(false);

  const handlePasswordInputFocus = () => {
    setPasswordInputFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setPasswordInputFocused(false);
  };

  const validatePassword = (password: string) => {
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUppercase(true);
    else setContainsUppercase(false);

    // has lowercase letter
    if (password.toUpperCase() != password) setContainsLowercase(true);
    else setContainsLowercase(false);

    // has number
    if (/\d/.test(password)) setContainsNumber(true);
    else setContainsNumber(false);

    // has special character
    if (/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password)) setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);

    // has min 8 characters
    if (password.length >= 8) setContainsMinCharacters(true);
    else setContainsMinCharacters(false);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await Auth.currentUserInfo();

        setUser({
          attributes: {
            family_name: userInfo.attributes.family_name || '',
            given_name: userInfo.attributes.given_name || '',
          },
        });
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const basicDataSchema = useMemo(() => {
    return yup
      .object()
      .shape({
        familyName: yup.string().trim().required(t('CommonErrors.lastNameRequired')),
        givenName: yup.string().trim().required(t('CommonErrors.firstNameRequired')),
      })
      .required();
  }, [t]);

  const {
    register: registerBasicData,
    watch: watchBasicData,
    handleSubmit: handleSubmitBasicData,
    setValue: setValueBasicData,
    clearErrors: clearErrorsBasicData,
    formState: { errors: errorsBasicData },
  } = useForm<PersonalAccountDetails>({
    resolver: yupResolver(basicDataSchema),
  });

  const {
    register: registerChangePassword,
    handleSubmit: handleSubmitChangePassword,
    watch: watchChangePassword,
    setError: setErrorChangePassword,
    formState: { errors: errorsChangePassword },
    reset: resetChangePassword,
  } = useForm<InAppChangePasswordData>({
    resolver: yupResolver(changePasswordSchema),
  });

  useEffect(() => {
    if (user && isBasicDataInEditMode === false) {
      setValueBasicData('givenName', user?.attributes.given_name || '');
      setValueBasicData('familyName', user?.attributes.family_name || '');
      clearErrorsBasicData();
    }
  }, [user, setValueBasicData, isBasicDataInEditMode, clearErrorsBasicData]);

  const watchAll = watchBasicData();

  const onSubmitBasicData = (data: any) => {
    const sendData: PersonalAccountRequest = {
      attributes: [
        { Name: 'given_name', Value: data.givenName },
        { Name: 'family_name', Value: data.familyName },
      ],
    };
    updatePersonalDataMutation.mutate(sendData as PersonalAccountRequest, {
      onSuccess: async () => {
        const userInfo = await Auth.currentUserInfo();
        setUser({
          attributes: {
            family_name: userInfo.attributes.family_name || '',
            given_name: userInfo.attributes.given_name || '',
          },
        });
        setToastInfo({ message: t('PersonalAccountSettingsPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
        setIsBasicDataInEditMode(false);
      },
      onError: () => {
        setToastInfo({ message: t('PersonalAccountSettingsPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const onSubmitPasswordChange = (data: any) => {
    const sendData = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
    };
    changePasswordMutation.mutate(sendData as InAppChangePasswordData, {
      onSuccess: () => {
        setToastInfo({ message: t('PersonalAccountSettingsPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
        setIsPasswordInEditMode(false);
        resetChangePassword();
        signOut();
        navigate('/');
      },
      onError: (e: any) => {
        setToastInfo({ message: t('PersonalAccountSettingsPage.toastError'), toastType: 'error' });
        setOpen(true);
        if (e.response.data.message === 'Current password does not match') {
          setErrorChangePassword('currentPassword', {
            type: 'manual',
            message: t('PersonalAccountSettingsPage.currentPasswordWrong'),
          });
        }
      },
    });
  };

  return (
    <div className="relative mb-32">
      {isLoading && (
        <div className="absolute w-full h-full z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div>
          <div className="relative mb-8">
            <h1 className="text-white font-medium text-xl mb-2">{t('PersonalAccountSettingsPage.title')}</h1>
            <span className="text-gray-300 text-sm">{t('PersonalAccountSettingsPage.description')}</span>
          </div>
          <form className="space-y-7 min-w-[40rem] w-1/2">
            <section className="space-y-4">
              <h2 className="text-xs font-semibold text-yellow-500">
                {t('PersonalAccountSettingsPage.basicInformationLabel')}
              </h2>
              <Input
                readOnly={!isBasicDataInEditMode}
                label={t('PersonalAccountSettingsPage.firstNameLabel')}
                placeholder={
                  isBasicDataInEditMode === false && !watchAll.givenName
                    ? t('PersonalAccountSettingsPage.undefinedLabel')
                    : t('PersonalAccountSettingsPage.firstNameLabel')
                }
                {...registerBasicData('givenName')}
                className={`!bg-transparent ${
                  isBasicDataInEditMode === false ? '!pl-0 text-gray-300' : watchAll.givenName ? '!text-gray-100' : ''
                }`}
                withBorder={isBasicDataInEditMode}
                error={errorsBasicData.givenName?.message as string}
                cy-data="firstNameUpdate"
              />
              <Input
                readOnly={!isBasicDataInEditMode}
                label={t('PersonalAccountSettingsPage.lastNameLabel')}
                placeholder={
                  isBasicDataInEditMode === false && !watchAll.familyName
                    ? t('PersonalAccountSettingsPage.undefinedLabel')
                    : t('PersonalAccountSettingsPage.lastNameLabel')
                }
                {...registerBasicData('familyName')}
                className={`!bg-transparent ${
                  isBasicDataInEditMode === false ? '!pl-0 text-gray-300' : watchAll.familyName ? '!text-gray-100' : ''
                }`}
                withBorder={isBasicDataInEditMode}
                error={errorsBasicData.familyName?.message as string}
                cy-data="lastNameUpdate"
              />
            </section>
            <div className="flex items-center justify-center gap-x-2 border-t border-gray-800 pt-2.5">
              {isBasicDataInEditMode && (
                <Button
                  type="button"
                  variant="cancel"
                  cy-data="buttonCancel"
                  onClick={() => setIsBasicDataInEditMode(false)}
                >
                  {t('PersonalAccountSettingsPage.linkCancel')}
                </Button>
              )}

              {isBasicDataInEditMode ? (
                <Button
                  type="button"
                  className="px-4 !w-52"
                  onClick={handleSubmitBasicData(onSubmitBasicData)}
                  cy-data="buttonSaveChanges"
                  isLoading={updatePersonalDataMutation.isLoading}
                >
                  {t('PersonalAccountSettingsPage.buttonUpdatePersonalAccount')}
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="cancel"
                  className="px-4 !w-52"
                  data-cy="buttonEdit"
                  onClick={() => setIsBasicDataInEditMode(true)}
                >
                  {t('PersonalAccountSettingsPage.buttonEditPersonalAccount')}
                </Button>
              )}
            </div>
          </form>

          <form className="space-y-7 min-w-[40rem] w-1/2 mt-4">
            <section className="space-y-4">
              <h2 className="text-xs font-semibold text-yellow-500">
                {t('PersonalAccountSettingsPage.changePasswordLabel')}
              </h2>
              {isPasswordInEditMode ? (
                <>
                  <Input
                    placeholder={t('PersonalAccountSettingsPage.currentPasswordPlaceholder')}
                    {...registerChangePassword('currentPassword')}
                    error={errorsChangePassword.currentPassword?.message}
                    label={t('PersonalAccountSettingsPage.currentPasswordPlaceholder')}
                    type="password"
                    data-cy="currentPassword"
                  />
                  <Input
                    placeholder={t('PersonalAccountSettingsPage.newPasswordPlaceholder')}
                    {...registerChangePassword('newPassword', {
                      onChange: (e) => validatePassword(e.target.value),
                    })}
                    error={errorsChangePassword.newPassword?.message}
                    type="password"
                    onBlur={handlePasswordInputBlur}
                    onFocus={handlePasswordInputFocus}
                    label={t('PersonalAccountSettingsPage.newPasswordPlaceholder')}
                    data-cy="newPassword"
                  />
                  {isPasswordInputFocused && !isPasswordValid && (
                    <PasswordHints
                      constainsUppercase={containsUppercase}
                      containsLowercase={containsLowercase}
                      containsNumber={containsNumber}
                      containsSpecialCharacter={containsSpecialCharacter}
                      containsMinCharacters={containsMinCharacters}
                    />
                  )}
                  <Input
                    placeholder={t('PersonalAccountSettingsPage.confirmPasswordPlaceholder')}
                    {...registerChangePassword('confirmNewPassword')}
                    error={errorsChangePassword.confirmNewPassword?.message}
                    type="password"
                    label={t('PersonalAccountSettingsPage.confirmPasswordPlaceholder')}
                    data-cy="confirmNewPassword"
                  />
                </>
              ) : (
                <Input
                  readOnly={true}
                  label={'Password'}
                  placeholder={'******'}
                  className={`!bg-transparent !pl-0 text-gray-300`}
                />
              )}
            </section>
            <div className="flex items-center justify-center gap-x-2 border-t border-gray-800 pt-2.5">
              {isPasswordInEditMode && (
                <Button
                  type="button"
                  data-cy="buttonChangePassword"
                  variant="cancel"
                  onClick={() => setIsPasswordInEditMode(false)}
                >
                  {t('PersonalAccountSettingsPage.linkCancel')}
                </Button>
              )}

              {isPasswordInEditMode ? (
                <Button
                  type="button"
                  className="px-4 !w-52"
                  onClick={handleSubmitChangePassword(onSubmitPasswordChange)}
                  isLoading={changePasswordMutation.isLoading}
                  data-cy="buttonChangePasswordSave"
                >
                  {t('PersonalAccountSettingsPage.buttonUpdatePersonalAccount')}
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="cancel"
                  className="px-4 !w-52"
                  data-cy="buttonChangePassword"
                  onClick={() => setIsPasswordInEditMode(true)}
                >
                  {t('PersonalAccountSettingsPage.buttonEnablePasswordUpdate')}
                </Button>
              )}
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};
export default PersonalAccount;
