import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, FormProvider, useForm, UseFormRegister } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreateSegment } from '@/api/segments/useCreateSegment';
import { useManualSegment } from '@/api/segments/useManualSegment';
import { usePrecalculateSegment } from '@/api/segments/usePrecalculateSegment';
import { useAppSelector } from '@/app/hooks';
import Banner from '@/component-library/primitives/Banner/Banner';
import { Button } from '@/component-library/primitives/Button/Button';
import CheckIcon from '@/component-library/primitives/Icons/CheckIcon/CheckIcon';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import DataFlowIcon from '@/component-library/primitives/Icons/DataFlowIcon/DataFlowIcon';
import DataIcon from '@/component-library/primitives/Icons/DataIcon/DataIcon';
import TriangleWarningIcon from '@/component-library/primitives/Icons/TriangleWarningIcon/TriangleWarningIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import { RadioButton } from '@/component-library/primitives/RadioButton/RadioButton';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import CsvLink from '@/component-library/widgets/CsvLink/CsvLink';
import { FileUploadInput } from '@/component-library/widgets/FileUploadInput/FileUploadInput';
import { Input } from '@/component-library/widgets/Input/Input';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import {
  CreateSegmentDetails,
  EditSegmentDetails,
  ManualSegmentParams,
  ManualSegmentRequest,
  SegmentRule,
} from '@/models/Segments';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { csvExampleSegmentData } from '@/modules/shared/dummyData/csvExampleFile';
import { readFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

import ConditionsScheme from './ConditionsScheme';
import PreviewConditionsScheme from './PreviewConditionsScheme';

const CreateSegment = () => {
  const createSegmentMutation = useCreateSegment();
  const precalculateSegmentMutation = usePrecalculateSegment();
  const manualSegmentMutation = useManualSegment();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const workspace = useAppSelector(selectCurrentWorkspace);
  const [activeStep, setActiveStep] = useState(0);
  const divWhereToScroll = useRef<HTMLDivElement>(null);
  const [fileErrors, setFileErrors] = useState<never | string[]>([]);
  const [segmentType, setSegmentType] = useState('manual');
  const [mainLogicalOperator, setMainLogicalOperator] = useState(false);
  const [memberCount, setMemberCount] = useState(0);
  const [manualSegmentInfo, setManualSegmentInfo] = useState({
    invalidEmailsInCsv: '',
    totalEmailsInCsv: '',
    totalUsersFound: '',
    fileName: '',
  });
  const [createdSegmentId, setCreatedSegmentId] = useState(null);

  const steps = [
    t('CreateSegmentPage.createSegmentStep'),
    t('CreateSegmentPage.setUpStep'),
    t('CreateSegmentPage.previewStep'),
  ];

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success' && !createdSegmentId) {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.segments), 2000);
    } else if (toastInfo.toastType === 'success' && createdSegmentId) {
      timerBeforeNavigation = setTimeout(
        () => navigate(generatePath(AppRoutes.segmentOverview, { id: createdSegmentId })),
        2000,
      );
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    if (segmentType === 'manual') {
      return [
        yup.object({
          name: yup
            .string()
            .trim()
            .required(t('CreateSegmentPage.nameRequired'))
            .min(1, t('CreateSegmentPage.nameTooShort', { minLength: 1 }))
            .max(50, t('CreateSegmentPage.nameTooLong', { maxLength: 50 })),
          description: yup.string().trim(),
        }),
        yup.object({
          importFile: yup.array().min(1, t('CommonErrors.fileRequired')).required(t('CommonErrors.fileRequired')),
        }),
        yup.object({}),
      ];
    } else {
      return [
        yup.object({
          name: yup
            .string()
            .trim()
            .required(t('CreateSegmentPage.nameRequired'))
            .min(1, t('CreateSegmentPage.nameTooShort', { minLength: 1 }))
            .max(50, t('CreateSegmentPage.nameTooLong', { maxLength: 50 })),
          description: yup.string().trim(),
        }),
        yup.object({
          conditions: yup.array().of(
            yup.object().shape({
              nestedConditions: yup
                .array()
                .of(
                  yup.object().shape({
                    attribute: yup.object().required(t('CommonErrors.attributeRequired')),
                    attributeType: yup.object().required(t('CommonErrors.attributeTypeRequired')),
                    operator: yup.object().required(t('CommonErrors.operatorRequired')),
                    value: yup.string().trim().required(t('CommonErrors.valueRequired')),
                  }),
                )
                .min(1)
                .required(),
            }),
          ),
        }),
        yup.object({}),
      ];
    }
  }, [segmentType, t]);

  const currentValidationSchema = schema[activeStep];

  const methods = useForm<CreateSegmentDetails>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
    defaultValues: {
      segmentType: 'manual',
      conditions: [
        {
          nestedLogicalOperator: null,
          conditionGroup: null,
          nestedConditions: [
            {
              attribute: undefined,
              attributeType: undefined,
              operator: undefined,
              value: '',
            },
          ],
        },
      ],
    },
  });

  const {
    control,
    handleSubmit,
    trigger,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = methods;

  const watchImportFile = watch('importFile');
  const watchSegmentType = watch('segmentType');

  const prepareManualRequest = async (): Promise<ManualSegmentRequest | undefined> => {
    const csvFile = watchImportFile;
    if (csvFile && csvFile.length > 0) {
      const base64Data = await readFileAsBase64(csvFile[0]);
      const data = {
        name: getValues('name'),
        description: getValues('description'),
        fileName: csvFile[0].name,
        base64Data: base64Data,
      };
      return data;
    }
    return undefined;
  };

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.segments) : setActiveStep(activeStep - 1);
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      if (activeStep === 1 && watchSegmentType === 'data-driven') {
        const formattedRuleObject = formatRuleObject();
        precalculateSegmentMutation.mutate(formattedRuleObject, {
          onSuccess: (res: any) => {
            setMemberCount(res.data.length);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          },
          onError: () => {
            console.log('failed to precalculate members');
            setMemberCount(0);
          },
        });
      } else if (activeStep === 1 && watchSegmentType === 'manual') {
        const sendData = await prepareManualRequest();
        if (sendData) {
          manualSegmentMutation.mutate({ data: sendData, endpoint: 'review-import' } as ManualSegmentParams, {
            onSuccess: (res: any) => {
              //review and submit steps are using the same mutation, so after review isSuccess stays true
              manualSegmentMutation.reset();
              setManualSegmentInfo(res.data);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            },
            onError: (e: any) => {
              const parsingErrors = e?.response?.data?.message?.error;
              if (parsingErrors && parsingErrors.length > 0) {
                const errorMessages: string[] = parsingErrors.map((error: string) => t(`CreateSegmentPage.${error}`));
                setFileErrors(errorMessages);
              }

              console.log('failed to precalculate members');
              setMemberCount(0);
            },
          });
        } else {
          console.error('No file selected.');
        }
      } else if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const formatRuleObject = (): SegmentRule => {
    const allConditions = getValues('conditions')?.flatMap((condition) => {
      return condition.nestedConditions;
    });
    const attributeSet = new Set();
    const uniqueAttributes = allConditions?.filter((item) => {
      const duplicate = attributeSet.has(item.attribute?.label);
      attributeSet.add(item.attribute?.label);
      return !duplicate;
    });
    const topLevelConds = getValues('conditions')
      ?.filter((condition) => {
        return condition.nestedLogicalOperator === null;
      })
      .flatMap((condition) => {
        return condition.nestedConditions;
      });

    const groupedConditions = getValues('conditions')?.filter((condition) => {
      return condition.nestedLogicalOperator !== null;
    });

    const allAttributes = uniqueAttributes?.map((item) => {
      return {
        name: item.attribute?.label,
        type: item.attributeType?.label,
      };
    });

    const topLevelConditions = topLevelConds ? topLevelConds : [];
    const conditionGroups = groupedConditions ? groupedConditions : [];

    const ruleObject = {
      rule: {
        name: getValues('name'),
        attributes: allAttributes,
        decisions: [
          {
            conditions: {
              [mainLogicalOperator ? 'any' : 'all']: [
                ...topLevelConditions.map((item) => {
                  if (item.attribute?.label === 'email' || item.attribute?.label === 'name') {
                    return {
                      fact: item.attribute?.label,
                      operator: item.operator?.label,
                      value: item.value,
                    };
                  } else {
                    return {
                      fact: 'attribute',
                      operator: item.operator?.label,
                      value: item.value,
                      path: `$.value`,
                      params: {
                        key: item.attribute?.label,
                      },
                    };
                  }
                }),
                ...conditionGroups?.map((item) => {
                  return {
                    [item.nestedLogicalOperator ? 'any' : 'all']: item.nestedConditions.map((cond) => {
                      if (cond.attribute?.label === 'email' || cond.attribute?.label === 'name') {
                        return {
                          fact: cond.attribute?.label,
                          operator: cond.operator?.label,
                          value: cond.value,
                        };
                      } else {
                        return {
                          fact: 'attribute',
                          operator: cond.operator?.label,
                          value: cond.value,
                          path: `$.value`,
                          params: {
                            key: cond.attribute?.label,
                          },
                        };
                      }
                    }),
                  };
                }),
              ],
            },
            event: {
              type: 'PartOfSegment',
              params: {},
            },
          },
        ],
      },
    };
    return ruleObject;
  };

  const onSubmit = async (data: CreateSegmentDetails) => {
    if (data.segmentType === 'data-driven') {
      const sendData = {
        name: data.name,
        description: data.description,
        workspaceId: workspace?.id,
        segmentType: data.segmentType,
        rule: data.segmentType === 'data-driven' ? JSON.stringify(formatRuleObject()) : '',
      };

      createSegmentMutation.mutate(sendData as CreateSegmentDetails, {
        onSuccess: () => {
          setToastInfo({ message: t('CreateSegmentPage.toastSuccess'), toastType: 'success' });
          setOpen(true);
        },
        onError: () => {
          setToastInfo({ message: t('CreateSegmentPage.toastError'), toastType: 'error' });
          setOpen(true);
        },
      });
    } else {
      const sendData = await prepareManualRequest();
      if (sendData) {
        manualSegmentMutation.mutate({ data: sendData, endpoint: 'import' } as ManualSegmentParams, {
          onSuccess: () => {
            setToastInfo({ message: t('CreateSegmentPage.toastSuccess'), toastType: 'success' });
            setOpen(true);
          },
          onError: () => {
            setToastInfo({ message: t('CreateSegmentPage.toastError'), toastType: 'error' });
            setOpen(true);
          },
        });
      } else {
        console.error('No file selected.');
      }
    }
  };

  const onSubmitWithRedirection = async (data: CreateSegmentDetails) => {
    if (data.segmentType === 'data-driven') {
      const sendData = {
        name: data.name,
        description: data.description,
        workspaceId: workspace?.id,
        segmentType: data.segmentType,
        rule: data.segmentType === 'data-driven' ? JSON.stringify(formatRuleObject()) : '',
      };

      createSegmentMutation.mutate(sendData as CreateSegmentDetails, {
        onSuccess: (res: any) => {
          setToastInfo({ message: t('CreateSegmentPage.toastSuccess'), toastType: 'success' });
          setOpen(true);
          setCreatedSegmentId(res.data.segmentId);
        },
        onError: () => {
          setToastInfo({ message: t('CreateSegmentPage.toastError'), toastType: 'error' });
          setOpen(true);
        },
      });
    } else {
      const sendData = await prepareManualRequest();
      if (sendData) {
        manualSegmentMutation.mutate({ data: sendData, endpoint: 'import' } as ManualSegmentParams, {
          onSuccess: (res: any) => {
            setToastInfo({ message: t('CreateSegmentPage.toastSuccess'), toastType: 'success' });
            setOpen(true);
            setCreatedSegmentId(res.data.segmentId);
          },
          onError: () => {
            setToastInfo({ message: t('CreateSegmentPage.toastError'), toastType: 'error' });
            setOpen(true);
          },
        });
      } else {
        console.error('No file selected.');
      }
    }
  };

  return (
    <div className="relative mb-[350px]">
      {createSegmentMutation.isLoading && (
        <div className="absolute w-full h-full z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div className="flex min-w-[40rem] items-start -ml-6" ref={divWhereToScroll}>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-[40rem]">
          <FormProvider {...methods}>
            <form className="space-y-6 min-w-[40rem] w-1/2">
              {/* Create Segment step */}
              {activeStep === 0 && (
                <div>
                  <div className="relative mb-7">
                    <h1 className="text-white font-medium text-xl">{t('CreateSegmentPage.createSegmentStep')}</h1>
                  </div>
                  <section className="space-y-3">
                    <h2 className="text-xs font-semibold text-yellow-500 mb-3">
                      {t('CreateSegmentPage.nameYourSegmentLabel')}
                    </h2>
                    <Input
                      placeholder={t('CreateSegmentPage.segmentNamePlaceholder')}
                      {...register('name')}
                      error={errors.name?.message as string}
                      label={t('CreateSegmentPage.segmentNameLabel')}
                      data-cy="segmentName"
                    />
                    <Textarea
                      {...register('description')}
                      placeholder={t('CreateSegmentPage.descriptionPlaceholder')}
                      error={errors.description?.message}
                      label={t('CreateSegmentPage.descriptionLabel')}
                      optional={true}
                    />
                  </section>
                  <section className="space-y-3 mt-9">
                    <h2 className="text-xs font-semibold text-yellow-500 mb-3">
                      {t('CreateSegmentPage.chooseSegmentTypeLabel')}
                    </h2>
                    <div
                      className={`${
                        watchSegmentType !== 'data-driven' ? 'opacity-50' : ''
                      } relative h-[101px] pl-4 bg-darkBlue rounded-lg`}
                      onClick={() => setSegmentType('data-driven')}
                      data-cy="data-driven"
                    >
                      <RadioButton
                        label={
                          <div className="flex flex-row gap-x-2 ml-2 items-start">
                            <div className="mt-[2px]">
                              <DataFlowIcon />
                            </div>
                            <div className="flex flex-col gap-y-2">
                              <h2 className="!font-medium !text-secondary-400">
                                {t('CreateSegmentPage.dataDrivenSegmentLabel')}
                              </h2>
                              <p className="!text-gray-400 !text-xs !tracking-[0.24px] pr-9">
                                {t('CreateSegmentPage.dataDrivenSegmentDescription')}
                              </p>
                            </div>
                          </div>
                        }
                        {...register('segmentType')}
                        value="data-driven"
                        className="!h-[101px] !z-50"
                      />
                    </div>
                    <div
                      className={`${
                        watchSegmentType !== 'manual' ? 'opacity-50' : ''
                      } relative h-[101px] pl-4 bg-darkBlue rounded-lg`}
                      onClick={() => setSegmentType('manual')}
                    >
                      <RadioButton
                        label={
                          <div className="flex flex-row gap-x-2 ml-2 items-start">
                            <div>
                              <DataIcon />
                            </div>
                            <div className="flex flex-col gap-y-2">
                              <h2 className="!font-medium !text-secondary-400">
                                {t('CreateSegmentPage.manualSegmentLabel')}
                              </h2>
                              <p className="!text-gray-400 !text-xs !tracking-[0.24px] pr-9">
                                {t('CreateSegmentPage.manualSegmentDescription')}
                              </p>
                            </div>
                          </div>
                        }
                        {...register('segmentType')}
                        value="manual"
                        className="!h-[101px] z-50"
                      />
                    </div>
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                        {t('CreateSegmentPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          data-cy="nextButton"
                          onClick={onNext}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('CreateSegmentPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Set up Manual Segment step  */}
              {activeStep === 1 && watchSegmentType === 'manual' && (
                <div>
                  <div className="mb-5">
                    <h1 className="text-white font-medium text-xl mb-2">
                      {t('CreateSegmentPage.addPeopleToSegmentLabel')}
                    </h1>
                    <span className="text-gray-300 text-sm">
                      {t('CreateSegmentPage.addPeopleToSegmentDescription')}
                    </span>
                  </div>
                  <h2 className="mb-4 text-xs font-semibold text-yellow-500">{t('CreateSegmentPage.addByCsvLabel')}</h2>
                  <section className="space-y-11">
                    <div>
                      <p
                        className={`${
                          errors.importFile?.message ? 'text-errorRed-500' : 'text-gray-100'
                        } text-xs font-medium mb-2`}
                      >
                        {t('CreateSegmentPage.uploadFileLabel')}
                      </p>
                      <FileUploadInput
                        name={'importFile'}
                        control={control}
                        value={watchImportFile}
                        setValue={setValue}
                        importError={errors.importFile?.message}
                        parseError={fileErrors}
                        setParseError={setFileErrors}
                        placeholder={t('ImportMembersPage.dragAndDropPlaceholder')}
                        fieldClassName="h-[10rem] overflow-y-auto"
                        exampleFileCSVLink={
                          <div className="flex flex-row items-center">
                            <span className="text-sm text-gray-100 font-medium after:content-['\00a0']">
                              {t('CreateSegmentPage.downloadLink')}
                            </span>
                            <CsvLink
                              data={csvExampleSegmentData}
                              className="text-primary-400 text-sm font-medium hover:underline"
                              linkLabel={t('CreateSegmentPage.hereLabel')}
                            />
                          </div>
                        }
                      />
                    </div>
                    {/* 
                    Add By API
                    <div>
                      <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                        {t('CreateSegmentPage.addByApiLabel')}
                      </h2>
                      <div className="bg-darkBlue rounded-lg p-4 cursor-pointer">
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-2 p-3">
                            <span>
                              <AddPlusCircleIcon color="#7898FB" />
                            </span>{' '}
                            <span className="text-secondary-400 text-sm font-medium tracking-[0.28px]">
                              {t('CreateSegmentPage.addPeopleByApiButton')}
                            </span>
                          </div>
                          <ChevronNewIcon type="right" />
                        </div>
                      </div>
                    </div> */}
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                        {t('CreateSegmentPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('CreateSegmentPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          data-cy="nextButton"
                          onClick={onNext}
                          isLoading={manualSegmentMutation.isLoading}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('CreateSegmentPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Set up Data-driven Segment step  */}
              {activeStep === 1 && watchSegmentType === 'data-driven' && (
                <div>
                  <div className="mb-5">
                    <h1 className="text-white font-medium text-xl mb-2">
                      {t('CreateSegmentPage.setUpConditionsLabe')}
                    </h1>
                    <span className="text-gray-300 text-sm">{t('CreateSegmentPage.defineAtLeastOneCondition')}</span>
                  </div>
                  <section>
                    <div className="relative z-[50]">
                      <ToggleSwitch
                        withLabels={{
                          left: t('CreateSegmentPage.andCondtionsOptionLabel'),
                          right: t('CreateSegmentPage.orConditionLabel'),
                        }}
                        value={mainLogicalOperator}
                        onChange={setMainLogicalOperator}
                      />
                    </div>
                    <ConditionsScheme
                      control={control as Control<CreateSegmentDetails | EditSegmentDetails, any>}
                      register={register as UseFormRegister<CreateSegmentDetails | EditSegmentDetails>}
                      errors={errors}
                    />
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                        {t('CreateSegmentPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('CreateSegmentPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          data-cy="nextButton"
                          onClick={onNext}
                          isLoading={precalculateSegmentMutation.isLoading}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('CreateSegmentPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Manual segment - Preview step */}
              {activeStep === 2 && watchSegmentType === 'manual' && (
                <div>
                  <div className="mb-9">
                    <h1 className="text-white font-medium text-xl mb-3">{t('CreateSegmentPage.previewStep')}</h1>
                    <div className="mt-8">
                      <Banner variant="info">
                        <h3 className="text-sm text-white font-normal tacking-[0.28px]">
                          <Trans
                            i18nKey="CreateSegmentPage.peopleBelongingToSegmentLabel"
                            values={{
                              peopleNumber: manualSegmentInfo.totalUsersFound,
                            }}
                          />
                        </h3>
                        <p className="text-xs font-normal text-gray-400 tracking-[0.24px]">
                          {t('CreateSegmentPage.peopleBelongingToSegmentDescription')}
                        </p>
                      </Banner>
                    </div>
                  </div>
                  <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                    {t('CreateSegmentPage.importDetailsLabel')}
                  </h2>
                  <section>
                    <div className="mb-7 flex flex-row gap-1 text-sm text-gray-300 font-normal">
                      <span>
                        {manualSegmentInfo.totalEmailsInCsv} {t('CreateSegmentPage.rowsLabel')}
                      </span>
                      {/* <span>-</span> */}
                      {/* <span>
                        {60} {t('CreateSegmentPage.columnsLabel')}
                      </span> */}
                    </div>
                    <hr className="w-full border-custom-darkBlue" />
                    <div className="flex flex-col gap-y-2 mt-7">
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row justify-center gap-2">
                          <CheckIcon />
                          <span className="text-base font-normal text-gray-50">
                            {t('CreateSegmentPage.validEmails')}
                          </span>
                        </div>
                        <div>
                          <span className="text-base font-normal text-gray-400">
                            {manualSegmentInfo.totalUsersFound}
                          </span>
                        </div>
                      </div>
                      {/* 
                      Use this section when the information about invalid emails is available
                      
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row justify-center gap-2">
                          <TriangleWarningIcon />
                          <span className="text-base font-normal text-gray-50">
                            {t('CreateSegmentPage.invalidEmails')}
                          </span>
                        </div>
                        <div>
                          <span className="text-base font-normal text-orange-400">
                            {manualSegmentInfo.invalidEmailsInCsv}
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                        {t('CreateSegmentPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button type="button" variant="previous" className="!min-w-[48px] !w-12" onClick={onBack}>
                          <ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />
                        </Button>
                        <Button
                          variant="secondary"
                          className="px-4 !w-52"
                          onClick={handleSubmit(onSubmitWithRedirection)}
                          isLoading={manualSegmentMutation.isLoading || manualSegmentMutation.isSuccess}
                        >
                          {t('CreateSegmentPage.buttonSaveAndGoToOverview')}
                        </Button>
                        <Button
                          className="px-4 !w-40"
                          data-cy="saveSegmentButton"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={manualSegmentMutation.isLoading || manualSegmentMutation.isSuccess}
                        >
                          {t('CreateSegmentPage.buttonSaveAndQuit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Data-driven segment - Preview step */}
              {activeStep === 2 && watchSegmentType === 'data-driven' && (
                <div>
                  <div className="mb-9">
                    <h1 className="text-white font-medium text-xl mb-3">{t('CreateSegmentPage.previewStep')}</h1>
                    <div className="mt-8">
                      <Banner variant="info">
                        <h3 className="text-sm text-white font-normal tacking-[0.28px]">
                          <Trans
                            i18nKey="CreateSegmentPage.peopleBelongingToSegmentLabel"
                            values={{
                              peopleNumber: memberCount,
                            }}
                          />
                        </h3>
                        <p className="text-xs font-normal text-gray-400 tracking-[0.24px]">
                          {t('CreateSegmentPage.peopleBelongingToSegmentDescription')}
                        </p>
                      </Banner>
                    </div>
                  </div>
                  <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                    {t('CreateSegmentPage.conditionsLabel')}
                  </h2>
                  <section>
                    <PreviewConditionsScheme
                      conditions={getValues('conditions') ?? []}
                      mainLogicalOperator={mainLogicalOperator}
                    />
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                        {t('CreateSegmentPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button type="button" variant="previous" className="!min-w-[48px] !w-12" onClick={onBack}>
                          <ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />
                        </Button>
                        <Button
                          variant="secondary"
                          className="px-4 !w-52"
                          onClick={handleSubmit(onSubmitWithRedirection)}
                          isLoading={createSegmentMutation.isLoading || createSegmentMutation.isSuccess}
                        >
                          {t('CreateSegmentPage.buttonSaveAndGoToOverview')}
                        </Button>
                        <Button
                          className="px-4 !w-40"
                          data-cy="saveSegmentButton"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={createSegmentMutation.isLoading || createSegmentMutation.isSuccess}
                        >
                          {t('CreateSegmentPage.buttonSaveAndQuit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};

export default CreateSegment;
