import { SVGProps } from 'react';

interface DataIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const DataIcon: React.FC<DataIconProps> = ({ color = '#7898FB', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15 9.99992V14.1666C15 15.5473 12.7614 16.6666 10 16.6666C7.23858 16.6666 5 15.5473 5 14.1666V9.99992M15 9.99992V5.83325M15 9.99992C15 11.3806 12.7614 12.4999 10 12.4999C7.23858 12.4999 5 11.3806 5 9.99992M15 5.83325C15 4.45254 12.7614 3.33325 10 3.33325C7.23858 3.33325 5 4.45254 5 5.83325M15 5.83325C15 7.21396 12.7614 8.33325 10 8.33325C7.23858 8.33325 5 7.21396 5 5.83325M5 9.99992V5.83325"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DataIcon;
