import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CloseIcon: React.FC<CloseIconProps> = ({ color = '#9BAFD0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
