import { SVGProps } from 'react';

interface AddPlusCircleIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const AddPlusCircleIcon: React.FC<AddPlusCircleIconProps> = ({ color = '#F5F7FC', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 12H12M12 12H16M12 12V16M12 12V8M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddPlusCircleIcon;
