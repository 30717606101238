/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/component-library/primitives/Link/Link';
import Loader from '@/component-library/primitives/Loader/Loader';
import Table from '@/component-library/widgets/Table/Table';
import { Notification } from '@/models/NotificationsByWorkspace';
import { useDeliveryTable } from '@/modules/Dashboard/Delivery/useDeliveryTable';
import { AppRoutes } from '@/utils/routes/router';

const COLUMNS = ['title', 'channel', 'sent'];

const DeliveryTableMini = ({ data }: { data: Notification[] | undefined }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { columns } = useDeliveryTable();
  const filteredColumns = useMemo(
    () =>
      columns.filter((column) => {
        return COLUMNS.includes(column.id || '');
      }),
    [columns],
  );

  if (!data) return <Loader />;
  if (data.length === 0) return <h2>No data</h2>;

  return (
    <div className="flex flex-col text-start justify-between w-[100%]">
      <div className="flex justify-center items-center px-2">
        <Table
          columns={filteredColumns}
          /* TODO: currently only sending last 3 */
          data={data.slice(0, 3) ?? []}
          className={{
            table: 'block overflow-auto h-[12rem]',
            row: 'h-[1rem]!',
            cell: 'lg:min-w-[8.5rem] 3xl:min-w-[12rem]',
            header: 'max-w-[3rem]',
          }}
          headerStyle={{ height: '2.5rem' }}
          fullWidth={false}
          showRecordsPerPage={false}
          showPagination={false}
        />
      </div>
      <div className="flex justify-end text-lg mt-3 mr-2">
        <Link to={AppRoutes.delivery} variant="secondary" label={t('DashboardPage.goToDelivery')} />
      </div>
    </div>
  );
};

export default DeliveryTableMini;
