import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { SegmentDetails } from '@/models/Segments';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getSegmentDetails(token: string | undefined, workspaceId: string, segmentId: string) {
  const response = await axios.get<any>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/segments/${segmentId}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetSegmentDetails(segmentId: string | undefined) {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useQuery<SegmentDetails>({
    queryKey: [queryKeys.segmentDetails, workspace?.id, segmentId],
    queryFn: () => getSegmentDetails(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? '', segmentId ?? ''),
  });
}
