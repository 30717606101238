import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AppRoutes } from '@/utils/routes/router';

import { Button } from '../../primitives/Button/Button';
import LogoIcon from '../../primitives/Icons/LogoIcon/LogoIcon';
import LanguagePicker from '../../widgets/LanguagePicker/LanguagePicker';

const Header: React.FC = () => {
  const { isAuthenticated, signOut, username } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnRegister = () => {
    navigate(AppRoutes.signUp);
  };

  return (
    <header className="flex justify-between items-center p-2 w-full lg:max-w-[80%] 3xl:max-w-[75%] my-0 mx-auto pt-10">
      <LogoIcon />
      {/* <nav className="hidden sm:flex items-center space-x-9 justify-center flex-grow">
        <NavLink className="text-gray-300 hover:text-gray-400" to={`/pricing`}>
          {t('Header.linkPricing')}
        </NavLink>
        <NavLink className="text-gray-300 hover:text-gray-400" to={`/docs`}>
          {t('Header.linkDocs')}
        </NavLink>
        <NavLink className="text-gray-300 hover:text-gray-400" to={`/blog`}>
          {t('Header.linkBlog')}
        </NavLink>
        <NavLink className="text-gray-300 hover:text-gray-400" to={`/contact-us`}>
          {t('Header.linkContact')}
        </NavLink>
      </nav> */}

      {/* <div className="flex space-x-9 items-center">
        <LanguagePicker />

        {isAuthenticated ? (
          <>
            <p className="text-gray-300">{username}</p>
            <NavLink className="text-gray-300 hover:text-gray-400" to="/" onClick={() => signOut()}>
              {t('Header.linkSignOut')}
            </NavLink>
          </>
        ) : (
          <NavLink className="text-gray-300 hover:text-gray-400 buttonSignIn" to={AppRoutes.signIn}>
            {t('Header.linkSignIn')}
          </NavLink>
        )}
        {!isAuthenticated && (
          <Button variant="primary" size="small" onClick={handleOnRegister}>
            {t('Header.buttonRegister')}
          </Button>
        )}
      </div> */}
    </header>
  );
};

export default Header;
