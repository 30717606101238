import { useEffect, useState } from 'react';

import { useAppSelector } from '@/app/hooks';
import { selectPermissions } from '@/app/slices/permissionsSlice';

import { UserRoles } from '../enums/enums';

export const usePermissionChecker = (resource: string, method: string) => {
  const [isPermissionAllowed, setIsPermissionAllowed] = useState<boolean>(false);

  const rolesResponse = useAppSelector(selectPermissions);

  useEffect(() => {
    const checkPermission = () => {
      if (rolesResponse) {
        const userRole = rolesResponse.roleName;
        if (userRole === UserRoles.TENANT_ADMIN) {
          setIsPermissionAllowed(true);
          return;
        }

        if (rolesResponse.permissions[resource][method] !== undefined) {
          setIsPermissionAllowed(rolesResponse.permissions[resource][method]);
          return;
        }
      }
      setIsPermissionAllowed(false);
    };

    checkPermission();
  }, [rolesResponse, resource, method]);

  return isPermissionAllowed;
};

export default usePermissionChecker;
