import { useTranslation } from 'react-i18next';

import CircleCheckIcon from '@/component-library/primitives/Icons/CircleCheckIcon/CircleCheckIcon';
import CircleInfoIcon from '@/component-library/primitives/Icons/CircleInfoIcon/CircleInfoIcon';

const PasswordHints = ({
  constainsUppercase,
  containsLowercase,
  containsNumber,
  containsSpecialCharacter,
  containsMinCharacters,
}: {
  constainsUppercase: boolean;
  containsLowercase: boolean;
  containsNumber: boolean;
  containsSpecialCharacter: boolean;
  containsMinCharacters: boolean;
}) => {
  const { t } = useTranslation();

  const validationData = [
    [t('CommonErrors.passwordMinCharacters'), containsMinCharacters],
    [t('CommonErrors.passwordLowercaseRequired'), containsLowercase],
    [t('CommonErrors.passwordUppercaseRequired'), constainsUppercase],
    [t('CommonErrors.passwordNumberRequired'), containsNumber],
    [t('CommonErrors.passwordSpecialCharacterRequired'), containsSpecialCharacter],
  ];

  return (
    <div className="space-y-1">
      {validationData.map((data, id) => (
        <div key={id} className="flex space-x-[6px] items-center">
          {data[1] ? <CircleCheckIcon width="20" height="20" /> : <CircleInfoIcon />}
          <span className={`text-xs ${data[1] ? 'text-gray-50' : 'text-gray-500'} font-normal tracking-[0.24px]`}>
            {data[0]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default PasswordHints;
