import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AppRoutes } from '@/utils/routes/router';

const PublicRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth();

  // Redirect to dashboard if authenticated
  if (isAuthenticated) {
    navigate(AppRoutes.dashboard);
  }

  // prevent rendering of children while isLoading is resolved
  if (isLoading) {
    return (
      <>
        <div className="checkingAuth"></div>
      </>
    );
  }

  // Render the children if user is not authenticated
  return <>{children}</>;
};

export default PublicRoute;
