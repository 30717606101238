import { UseFormRegister } from 'react-hook-form';
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';

import { RadioButton } from '@/component-library/primitives/RadioButton/RadioButton';
import NewEmail from '@/models/NewEmail';

interface OptionSelectorProps {
  title: string;
  content: string;
  gradientOption: string;
  isSelected: boolean;
  onSelect: (option: string) => void;
  register?: UseFormRegister<NewEmail>;
  radioBtnValue?: string;
  optionImage?: React.ReactElement;
  disabled?: boolean;
}

const OptionSelector: React.FC<OptionSelectorProps> = ({
  title,
  content,
  gradientOption,
  isSelected,
  onSelect,
  register,
  radioBtnValue,
  optionImage,
  disabled = false,
}) => {
  const handleSelect = () => {
    onSelect(title);
  };

  return (
    <div
      className={`relative w-1/3 flex flex-col p-4 border-transparent h-[13.188rem] justify-end rounded-[1.356rem] cursor-pointer ${
        isSelected ? '' : 'opacity-50'
      } ${disabled && 'opacity-100 pointer-events-none'}`}
      onClick={handleSelect}
      style={{
        backgroundImage: `url('/images/optionGradient${gradientOption}.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {register && radioBtnValue && (
        <RadioButton {...register('editingType')} value={radioBtnValue} className="w-fit" borderWhite />
      )}
      <div className={`${optionImage ? 'mt-[35px]' : 'mt-[90px]'} h-[100%] flex flex-col align-baseline`}>
        {optionImage && optionImage}
        <h1 className="text-sm leading-4 tracking-[0.02em] text-white font-normal w-[80%]">{title}</h1>
        <p className="text-gray-100 text-xs leading-3 tracking-[0.02em] font-normal mt-2 w-[92%]">{content}</p>
      </div>
      {disabled && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-modalOpacityBG backdrop-blur-[1px]">
          <span className="text-white">Coming soon</span>
        </div>
      )}
    </div>
  );
};

export default OptionSelector;
