import { useQuery } from 'react-query';
import axios from 'axios';

import { AccountMetrics } from '@/models/AccountMetrics';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function geAccountMetrics(token: string | undefined) {
  const response = await axios.get<AccountMetrics>(`${AwsConfigApiGateway.pooledTenantApiUrl}/account-metrics`, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
}

export function useGetAccountMetrics() {
  const { user } = useAuth();
  return useQuery<AccountMetrics>({
    queryKey: [queryKeys.accountMetrics],
    queryFn: () => geAccountMetrics(user?.signInUserSession?.idToken.jwtToken),
  });
}
