import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import ArrowDownUpIcon from '@/component-library/primitives/Icons/ArrowDownUpIcon/ArrowDownUpIcon';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import TrashIcon from '@/component-library/primitives/Icons/TrashIcon/TrashIcon';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Workspace } from '@/models/WorkspacesByTenant';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

export const useWorkspacesTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Workspace>();
  const navigate = useNavigate();

  const isActionAllowed = usePermissionChecker(PermissionsEnum.WORKSPACES, HttpMethodsEnum.PUT);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  const handleOnEdit = (workspace: Workspace) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    const editWorkspaceUrl = generatePath(AppRoutes.editWorkspace, { id: workspace.id });
    navigate(editWorkspaceUrl, { state: { workspace } });
  };

  const handleOnDelete = (workspaceId: string | null) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    workspaceId && navigate(generatePath(AppRoutes.deleteWorkspace, { id: workspaceId }));
  };

  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <div className="flex flex-row items-center gap-1">
          <span className="whitespace-wrap">{t('WorkspacesPage.workspaceNameColumn')}</span>
          <span className="cursor-pointer">
            <ArrowDownUpIcon />
          </span>
        </div>
      ),
      cell: (info) => <span className="block truncate max-w-[10rem] whitespace-wrap">{info.renderValue()}</span>,
    }),
    columnHelper.accessor('settings', {
      header: () => <div className="whitespace-wrap">{t('WorkspacesPage.deliverySettingsColumn')}</div>,
      cell: (info) => <span className="block truncate lg:max-w-[6rem] 3xl:max-w-[12rem]">{info.renderValue()}</span>,
    }),
    // columnHelper.accessor('identifier', {
    //   header: () => <div>{t('WorkspacesPage.identifierColumn')}</div>,
    //   cell: (info) => <div>{info.renderValue()}</div>,
    // }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{t('WorkspacesPage.createdAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('createdByName', {
      header: () => <div>{t('WorkspacesPage.createdByColumn')}</div>,
      cell: (info) => <div className="truncate max-w-[10rem]">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('lastModifiedAt', {
      header: () => <div>{t('WorkspacesPage.lastModifiedAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('lastModifiedByName', {
      header: () => <div>{t('WorkspacesPage.lastModifiedByColumn')}</div>,
      cell: (info) => <div className="truncate max-w-[10rem]">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.id;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5 tableOptions">...</span>
              </div>
            </div>
            {openDropdownId === rowId && (
              <div className="absolute right-0 z-10 p-2 pt-2 pb-2 px-2 shadow-md w-40 rounded-lg bg-custom-darkBlue border-[0.063rem] border-gray-800">
                <div
                  key={'0'}
                  className="group/item flex items-center gap-2 px-2 py-3 mb-1 cursor-pointer rounded-lg hover:bg-activeSideMenuOption hover:text-primary-400 text-gray-200"
                  onClick={() => handleOnEdit(info.row.original)}
                >
                  <span className="group-hover/item:[&>*_path]:stroke-primary-400">
                    <EditPencilIcon />
                  </span>
                  <span className="font-normal">{t('WorkspacesPage.linkEdit')}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <path d="M0 1H145.878H147" stroke="#313B4B" />
                </svg>
                <div
                  key={'1'}
                  className="group/item flex items-center gap-2 px-2 py-3 mt-1 cursor-pointer rounded-lg hover:bg-deleteButtonHoverBG text-red-400"
                  onClick={() => handleOnDelete(info.renderValue())}
                >
                  <span>
                    <TrashIcon color="#ff9296" />
                  </span>
                  <span className="font-normal">{t('WorkspacesPage.linkDelete')}</span>
                </div>
              </div>
            )}
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
          </div>
        );
      },
    }),
  ];

  return { columns };
};
