import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetWorkspacesByTenant } from '@/api/workspaces/useGetWorkspacesByTenant';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import AddPlusCircleIcon from '@/component-library/primitives/Icons/AddPlusCircleIcon/AddPlusCircleIcon';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import { Workspace } from '@/models/WorkspacesByTenant';
import {
  selectCurrentWorkspace,
  selectWorkspaces,
  setSelectedWorkspace,
} from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { colors } from '@/modules/shared/dummyData/colors';
import { AppRoutes } from '@/utils/routes/router';

import { selectIsCollapsed, setCollapsedSidebar } from '../SideMenu/sideMenuSlice';

const WorkspacesMenu = () => {
  const [isCreateWorkspaceButtonHovered, setIsCreateWorkspaceButtonHovered] = useState(false);
  const { isFetching } = useGetWorkspacesByTenant();

  const { t } = useTranslation();

  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const collapsedSidebar = useAppSelector(selectIsCollapsed);
  const workspaces = useAppSelector(selectWorkspaces);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  const formattedWorkspaces: { id: string; open: boolean }[] = [];
  workspaces.forEach((element: Workspace) => {
    formattedWorkspaces.push({ id: element.id, open: false });
  });
  const [tooltipState, setTooltipState] = useState(formattedWorkspaces);
  useEffect(() => {
    const workspaceFromSessionStorage = sessionStorage.getItem('workspace');
    const sideBarStateFromSessionStorage = sessionStorage.getItem('sideBarState');
    workspaceFromSessionStorage && dispatch(setSelectedWorkspace(JSON.parse(workspaceFromSessionStorage)));
    sideBarStateFromSessionStorage && dispatch(setCollapsedSidebar(JSON.parse(sideBarStateFromSessionStorage)));
  }, []);

  const handleSelectWorkspace = (workspace: Workspace) => {
    sessionStorage.setItem('workspace', JSON.stringify(workspace));
    sessionStorage.setItem('sideBarState', JSON.stringify(collapsedSidebar));
    const containsUUID = uuidRegex.test(location.pathname);

    if (containsUUID) {
      const parts = location.pathname.split('/');
      const firstPart = parts[1];
      window.location.assign('/' + firstPart);
    } else {
      navigate(0);
    }
  };

  return (
    <div className="w-20 bg-darkBlue h-[100vh] border-r-2 border-r-custom-darkGray lg:pt-4 3xl:pt-6 pb-2">
      {isFetching && <div className="w-20"></div>}
      {!isFetching && (
        <>
          <div className="w-20 lg:max-h-[90%] overflow-hidden hover:overflow-y-auto [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-800 [&::-webkit-scrollbar-thumb]:bg-gray-600 overflow-x-auto">
            <div className="flex items-center flex-col gap-y-4">
              {workspaces?.map((workspace, id) => {
                return (
                  <div key={workspace?.id}>
                    <Tooltip
                      side="right"
                      content={<div className="text-white text-sm">{workspace?.name}</div>}
                      open={tooltipState.find((el) => el.id === workspace.id)?.open}
                      onOpenChange={(open: boolean) => {
                        if (open) {
                          setTooltipState((prevState) => {
                            const hoveredElement = prevState.find((el) => el.id === workspace.id);
                            return [
                              ...prevState,
                              {
                                id: hoveredElement ? hoveredElement.id : '',
                                open: true,
                              },
                            ];
                          });
                        } else {
                          setTooltipState((prevState) => {
                            const hoveredElement = prevState.find((el) => el.id === workspace.id);
                            return [
                              ...prevState,
                              {
                                id: hoveredElement ? hoveredElement.id : '',
                                open: false,
                              },
                            ];
                          });
                        }
                      }}
                      contentClassName="bg-gray-800 p-2.5 rounded z-50 w-fit text-center"
                      arrowClassName="mb-2 fill-gray-800 w-4 h-2"
                    >
                      <div
                        className={`cursor-pointer rounded-md border-[5px] w-[52px] h-[52px] flex items-center justify-center uppercase text-white font-bold ${
                          workspace?.id === currentWorkspace?.id
                            ? 'border-gray-100'
                            : 'border-gray-800 hover:border-gray-600 opacity-50'
                        }`}
                        style={{ background: `${colors[id]}` }}
                        onClick={() => handleSelectWorkspace(workspace)}
                      >
                        {workspace.name.charAt(0) + workspace.name.charAt(1)}
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-5 flex flex-col items-center">
            <Tooltip
              side="right"
              content={<div className="text-white text-sm">{t('WorkspacePicker.createAWorkspace')}</div>}
              open={isCreateWorkspaceButtonHovered}
              onOpenChange={(open: boolean) => {
                if (open) {
                  setIsCreateWorkspaceButtonHovered(true);
                } else {
                  setIsCreateWorkspaceButtonHovered(false);
                }
              }}
              contentClassName="bg-gray-800 p-2.5 rounded z-50 w-fit text-center"
              arrowClassName="mb-2 fill-gray-800 w-4 h-2"
            >
              <div
                onClick={() => navigate(AppRoutes.createWorkspace)}
                className="group w-10 h-10 rounded hover:bg-gray-800 flex items-center justify-center cursor-pointer"
              >
                <span className="group-hover:[&>*_path]:stroke-white">
                  <AddPlusCircleIcon color="#9BAFD0" />
                </span>
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkspacesMenu;
