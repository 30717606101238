import { useTranslation } from 'react-i18next';

import NoDataIcon from '@/component-library/primitives/Icons/NoDataIcon/NoDataIcon';
import NoDataPaperPlaneIcon from '@/component-library/primitives/Icons/NoDataPaperPlaneIcon/NoDataPaperPlaneIcon';

interface NoDataBoardProps {
  description?: string;
  className?: string;
  actionButton?: React.ReactElement;
}

const NoDataBoard: React.FC<NoDataBoardProps> = ({ description, className, actionButton }) => {
  const noDataBoardClasses = `w-full h-[80%] flex flex-col items-center justify-center bg-darkBlue rounded-lg ${className}`;
  const { t } = useTranslation();

  return (
    <div className={noDataBoardClasses}>
      <div className="flex flex-col items-center">
        <div className="relative flex justify-center">
          <div className="absolute bottom-[58px] left-[22%]">
            <NoDataPaperPlaneIcon />
          </div>
          <NoDataIcon />
        </div>
        <div className="text-center text-gray-50 text-base font-medium mt-3">{t('NoDataBoard.noDataLabel')}</div>
        <div className="text-gray-400 text-sm">{description}</div>
        {actionButton && actionButton}
      </div>
    </div>
  );
};

export default NoDataBoard;
