import { useTranslation } from 'react-i18next';

import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import { Campaign } from '@/models/Campaigns';

import NoDataSection from '../NoDataSection';

interface CampaignsSectionProps {
  campaigns: Campaign[] | undefined;
  changeActiveStep: (step: string) => void;
}

const CampaignsSection: React.FC<CampaignsSectionProps> = ({ campaigns, changeActiveStep }) => {
  const { t } = useTranslation();

  return (
    <div className="px-5 pt-5 pb-7 w-full min-h-[17rem] max-h-[17rem] rounded-[10px] bg-darkBlue">
      <div className="flex flex-row items-center justify-between mb-8">
        <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
          {t('MemberProfilePage.campaignsLabel')}
        </h2>
        <button
          onClick={() => changeActiveStep(t('MemberProfilePage.campaignsTabLabel'))}
          className="group hover:bg-iconButtonHoverBG rounded-lg w-8 h-8 flex items-center justify-center"
        >
          <span className="group-hover:[&>*_path]:stroke-gray-400">
            <ChevronNewIcon type="right" color="white" />
          </span>
        </button>
      </div>
      <div className="h-[10rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {campaigns && campaigns.length < 1 && (
          <div className="h-[10rem]">
            <NoDataSection />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignsSection;
