import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import Chip from '@/component-library/primitives/Chip/Chip';
import ArrowDownUpIcon from '@/component-library/primitives/Icons/ArrowDownUpIcon/ArrowDownUpIcon';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import TrashIcon from '@/component-library/primitives/Icons/TrashIcon/TrashIcon';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Campaign } from '@/models/Campaigns';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

export const useCampaignsTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Campaign>();
  const navigate = useNavigate();

  const isActionAllowed = usePermissionChecker(PermissionsEnum.CAMPAIGNS, HttpMethodsEnum.PUT);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  const handleOnEdit = () => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    console.log('handleOnEdit');
  };

  const handleOnDelete = (campaignId: string | null) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    campaignId && navigate(generatePath(AppRoutes.deleteCampaign, { id: campaignId }));
  };

  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <div className="flex flex-row items-center gap-1">
          <span>{t('CampaignsPage.nameColumn')}</span>
          <span className="cursor-pointer">
            <ArrowDownUpIcon />
          </span>
        </div>
      ),
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('description', {
      header: () => <div>{t('CampaignsPage.descriptionColumn')}</div>,
      cell: (info) => (
        <div>
          <a href="#" className="text-yellow-200 underline block mb-4">
            {info.row.original.name}
          </a>
          <span className="block text-left truncate max-w-lg">{info.renderValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <div>{t('CampaignsPage.statusColumn')}</div>,
      cell: (info) => <Chip label={'Running'} variant="dark" className={'gap-8 flex-shrink-0'} />,
    }),
    columnHelper.accessor('created', {
      header: () => <div>{t('CampaignsPage.createdColumn')}</div>,
      cell: (info) => <div>{info.renderValue()}</div>,
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.id;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5">...</span>
              </div>
            </div>
            {openDropdownId === rowId && (
              <div className="absolute right-0 z-10 p-2 pt-2 pb-2 px-2 shadow-md w-40 rounded-lg bg-custom-darkBlue border-[0.063rem] border-gray-800">
                <div
                  key={'0'}
                  className="group/item flex items-center gap-2 px-2 py-3 mb-1 cursor-pointer rounded-lg hover:bg-activeSideMenuOption hover:text-primary-400 text-gray-200"
                  onClick={() => handleOnEdit()}
                >
                  <span className="group-hover/item:[&>*_path]:stroke-primary-400">
                    <EditPencilIcon />
                  </span>
                  <span className="font-normal">{t('CampaignsPage.linkEdit')}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <path d="M0 1H145.878H147" stroke="#313B4B" />
                </svg>
                <div
                  key={'1'}
                  className="group/item flex items-center gap-2 px-2 py-3 mt-1 cursor-pointer rounded-lg hover:bg-deleteButtonHoverBG text-red-400"
                  onClick={() => handleOnDelete(info.renderValue())}
                >
                  <span>
                    <TrashIcon color="#ff9296" />
                  </span>
                  <span className="font-normal">{t('CampaignsPage.linkDelete')}</span>
                </div>
              </div>
            )}
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
          </div>
        );
      },
    }),
  ];

  return { columns };
};
