import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetCampaigns } from '@/api/campaigns/useGetCampaigns';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import { useCampaignsTable } from './useCampaignsTable';

const Campaigns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetCampaigns();
  const { columns } = useCampaignsTable();
  const isActionAllowed = usePermissionChecker(PermissionsEnum.CAMPAIGNS, HttpMethodsEnum.POST);

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [filterValue, setFilterValue] = useState('');

  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase()),
      ),
    );
  }, [data, filterValue]);

  const handleNavigate = (route: string, isActionAllowed: boolean) => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    navigate(route);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField initialValue={filterValue} setSearchValue={setFilterValue} />
        </div>
        {filteredData && filteredData.length > 0 && (
          <div className="justify-end">
            <Button className="!w-[10.5rem]" onClick={() => handleNavigate(AppRoutes.createCampaign, isActionAllowed)}>
              {t('CampaignsPage.buttonNewCampaign')}
            </Button>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : filteredData && filteredData.length > 0 ? (
        <Table
          columns={columns}
          data={filteredData ?? []}
          className={{
            row: '',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard
          description={t('NoDataBoard.addYourFirstCampaign')}
          actionButton={
            <Button
              className="!w-[10.5rem] mt-4"
              onClick={() => handleNavigate(AppRoutes.createCampaign, isActionAllowed)}
            >
              {t('CampaignsPage.buttonNewCampaign')}
            </Button>
          }
        />
      )}
      <ProvideToasts>
        <Toast
          isOpen={open}
          setOpen={setOpen}
          message={toastInfo.message}
          toastType={toastInfo.toastType as ToastType}
        />
      </ProvideToasts>
    </div>
  );
};

export default Campaigns;
