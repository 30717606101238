import { SVGProps } from 'react';

interface SearchMagnifyingGlassIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const SearchMagnifyingGlassIcon: React.FC<SearchMagnifyingGlassIconProps> = ({ color = '#F5F7FC', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.5 12.5L17.5 17.5M8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchMagnifyingGlassIcon;
