import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTimeAgo from 'react-time-ago';
import { createColumnHelper } from '@tanstack/react-table';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { ActivityLog } from '@/models/ActivityLogsByWorkspace';

export const useActivityLogsTable = () => {
  TimeAgo.setDefaultLocale(en.locale);
  TimeAgo.addLocale(en);

  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ActivityLog>();

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('activityName', {
      header: () => <div>{t('ActivityLogsPage.activityNameColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{t('ActivityLogsPage.createdAtColumn')}</div>,
      cell: (info) => <ReactTimeAgo date={new Date(info.renderValue() ?? 0)} locale="en-US" />,
    }),
    columnHelper.accessor('createdByName', {
      header: () => <div>{t('ActivityLogsPage.createdByNameColumn')}</div>,
      cell: (info) => <span>{info.renderValue()}</span>,
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.id;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5">...</span>
              </div>
            </div>
          </div>
        );
      },
    }),
  ];

  return columns;
};
