import { SVGProps } from 'react';

interface CloseButtonIconProps extends SVGProps<SVGSVGElement> {
  symbolColor?: string;
  bgColor?: string;
  width?: string;
  height?: string;
}

const CloseButtonIcon: React.FC<CloseButtonIconProps> = ({
  symbolColor = '#7085A8',
  bgColor = 'rgba(112, 133, 168, 0.16)',
  width = '24',
  height = '24',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="5" fill={bgColor} />
      <path
        d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
        stroke={symbolColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseButtonIcon;
