import { SVGProps, useState } from 'react';

interface CheckAllIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  isHoverable?: boolean;
  tooltipLabel?: string;
}

const CheckAllIcon: React.FC<CheckAllIconProps> = ({
  color = '#F5F7FC',
  isHoverable = false,
  tooltipLabel,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
      <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 5.48535L10.2426 9.72799L18.727 1.24268M1 5.48535L5.24264 9.72799M13.7279 1.24268L10.5 4.50009"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {isHoverable && showTooltip && (
        <div className="absolute left-8 -bottom-3 bg-custom-darkBlue p-2 rounded-lg z-10">{tooltipLabel}</div>
      )}
    </div>
  );
};

export default CheckAllIcon;
