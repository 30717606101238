import { useMutation } from 'react-query';
import axios from 'axios';

import { EditTemplateDetails } from '@/models/TemplateDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function updateTemplate(templateData: EditTemplateDetails, token: string | undefined) {
  const response = await axios.put<EditTemplateDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/templates`,
    templateData,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useUpdateTemplate() {
  const { user } = useAuth();

  return useMutation<EditTemplateDetails, Error, EditTemplateDetails>((templateData) =>
    updateTemplate(templateData, user?.signInUserSession.idToken.jwtToken),
  );
}
