import { SVGProps } from 'react';

interface DragIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const DragIcon: React.FC<DragIconProps> = ({ color = '#3B4659', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="22" viewBox="0 0 8 22" fill="none">
      <circle cx="1.5" cy="2" r="1.5" fill={color} />
      <circle cx="1.5" cy="8" r="1.5" fill={color} />
      <circle cx="1.5" cy="14" r="1.5" fill={color} />
      <circle cx="1.5" cy="20" r="1.5" fill={color} />
      <circle cx="6.5" cy="2" r="1.5" fill={color} />
      <circle cx="6.5" cy="8" r="1.5" fill={color} />
      <circle cx="6.5" cy="14" r="1.5" fill={color} />
      <circle cx="6.5" cy="20" r="1.5" fill={color} />
    </svg>
  );
};

export default DragIcon;
