import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { ImportMembersDetails } from '@/models/WorkspaceDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function importMembers(data: ImportMembersDetails, workspaceId: string, token: string | undefined) {
  const response = await axios.post<ImportMembersDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/audience/import`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useImportMembers() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<ImportMembersDetails, Error, ImportMembersDetails>((data) =>
    importMembers(data, workspace?.id ?? '', user?.signInUserSession.idToken.jwtToken),
  );
}
