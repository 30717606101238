import { SVGProps } from 'react';

interface ArrowIconProps extends SVGProps<SVGSVGElement> {
  type: string;
  color?: string;
}

function ArrowIcon({ color = '#eff3fa', type, ...props }: ArrowIconProps) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {type === 'left' && (
        <>
          <path
            d="M4.30691 12.2871C4.34351 12.3755 4.39776 12.4584 4.46967 12.5303L11.4697 19.5303C11.7626 19.8232 12.2374 19.8232 12.5303 19.5303C12.8232 19.2374 12.8232 18.7626 12.5303 18.4697L6.81066 12.75L19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25L6.81066 11.25L12.5303 5.53033C12.8232 5.23744 12.8232 4.76256 12.5303 4.46967C12.2374 4.17678 11.7626 4.17678 11.4697 4.46967L4.47004 11.4693"
            fill={color}
          />
          <path
            d="M4.46808 11.4713C4.33329 11.6069 4.25 11.7937 4.25 12C4.25 12.1017 4.27024 12.1987 4.30691 12.2871"
            fill={color}
          ></path>
        </>
      )}
    </svg>
  );
}

export default ArrowIcon;
