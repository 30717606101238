import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from '@/component-library/layouts/AuthLayout/AuthLayout';
import { Button } from '@/component-library/primitives/Button/Button';
import { Input } from '@/component-library/widgets/Input/Input';
import PasswordHints from '@/component-library/widgets/PasswordHints/PasswordHints';
import { PasswordData } from '@/models/PasswordData';
import { AppRoutes } from '@/utils/routes/router';

import { useAuth } from '../../hooks/useAuth';

const CreatePasswordPage = () => {
  const { newPassword } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialCharacter, setContainsSpecialCharacter] = useState(false);
  const [containsMinCharacters, setContainsMinCharacters] = useState(false);

  const isPasswordValid =
    containsUppercase && containsLowercase && containsNumber && containsSpecialCharacter && containsMinCharacters;

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        password: yup
          .string()
          .required(t('CommonErrors.newPasswordRequired'))
          .matches(/[a-z]/, ' ')
          .matches(/[A-Z]/, ' ')
          .matches(/^.*(?=.*\d).*$/, ' ')
          .matches(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, ' ')
          .min(8, ' ')
          .max(64, t('CommonErrors.passwordMaxCharacters')),
        confirmPassword: yup
          .string()
          .required(t('CommonErrors.confirmPasswordRequired'))
          .oneOf([yup.ref('password')], t('CommonErrors.passwordMismatch')),
      })
      .required();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordData>({
    resolver: yupResolver(schema),
  });

  const watchAll = watch();

  const [isPasswordInputFocused, setPasswordInputFocused] = useState(false);

  const handlePasswordInputFocus = () => {
    setPasswordInputFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setPasswordInputFocused(false);
  };

  const validatePassword = (password: string) => {
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUppercase(true);
    else setContainsUppercase(false);

    // has lowercase letter
    if (password.toUpperCase() != password) setContainsLowercase(true);
    else setContainsLowercase(false);

    // has number
    if (/\d/.test(password)) setContainsNumber(true);
    else setContainsNumber(false);

    // has special character
    if (/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password)) setContainsSpecialCharacter(true);
    else setContainsSpecialCharacter(false);

    // has min 8 characters
    if (password.length >= 8) setContainsMinCharacters(true);
    else setContainsMinCharacters(false);
  };

  const onSubmit = async (data: PasswordData) => {
    const { password, confirmPassword } = data;
    try {
      await schema.validate(data, { abortEarly: false });
      await newPassword(password);
      navigate(AppRoutes.signIn);
    } catch (error) {
      console.log('Something went wrong!');
    }
  };

  return (
    <AuthLayout
      title={t('CreatePasswordPage.title')}
      content={t('CreatePasswordPage.subtitle')}
      titleClass="self-start"
      contentClass="self-start"
      variant="one-column"
      titleIconProps={{ path: '/images/lock.png', classes: 'self-start' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-4">
        <Input
          placeholder={t('CreatePasswordPage.newPasswordPlaceholder')}
          {...register('password', {
            onChange: (e) => validatePassword(e.target.value),
          })}
          error={errors.password?.message}
          type="password"
          onBlur={handlePasswordInputBlur}
          onFocus={handlePasswordInputFocus}
          label={t('CreatePasswordPage.newPasswordPlaceholder')}
          data-cy="newPassword"
        />
        {isPasswordInputFocused && !isPasswordValid && (
          <PasswordHints
            constainsUppercase={containsUppercase}
            containsLowercase={containsLowercase}
            containsNumber={containsNumber}
            containsSpecialCharacter={containsSpecialCharacter}
            containsMinCharacters={containsMinCharacters}
          />
        )}
        <Input
          placeholder={t('CreatePasswordPage.confirmPasswordPlaceholder')}
          {...register('confirmPassword')}
          error={errors.confirmPassword?.message}
          type="password"
          label={t('CreatePasswordPage.confirmPasswordPlaceholder')}
          data-cy="confirmPassword"
        />
        <Button className="w-full" data-cy="buttonSetUpPassword">
          {t('CreatePasswordPage.buttonChangePassword')}
        </Button>
      </form>
    </AuthLayout>
  );
};

export default CreatePasswordPage;
