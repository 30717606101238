/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import React from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { useDeleteTemplateById } from '@/api/templates/useDeleteTemplate';
import ArrowDownUpIcon from '@/component-library/primitives/Icons/ArrowDownUpIcon/ArrowDownUpIcon';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import TrashIcon from '@/component-library/primitives/Icons/TrashIcon/TrashIcon';
import Modal from '@/component-library/primitives/Modal/Modal';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Template } from '@/models/TemplatesByTenant';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import queryKeys from '../../shared/query/queryKeys';

export const useTemplatesTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Template>();
  const deleteTemplateMutation = useDeleteTemplateById();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTemplateId, setDeletingTemplateId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isTemplateEditingAllowed = usePermissionChecker(PermissionsEnum.TEMPLATES, HttpMethodsEnum.PUT);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  const handleDeleteModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowDeleteModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowDeleteModal(state);
        });
      });
    }
  };

  const handleOnEdit = (template: Template) => {
    if (!isTemplateEditingAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    const editTemplateeUrl = generatePath(AppRoutes.templatesEdit, { id: template.id });
    navigate(editTemplateeUrl, { state: { template } });
  };

  const handleOnDelete = (templateId: string | null) => {
    if (!isTemplateEditingAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    setDeletingTemplateId(templateId);
    handleDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    console.log(deletingTemplateId);

    if (deletingTemplateId) {
      const sendData = deletingTemplateId;

      deleteTemplateMutation.mutate(sendData, {
        onSuccess: () => {
          setTimeout(() => {
            queryClient.setQueryData<Template[]>(
              queryKeys.templates,
              (prevData) => prevData?.filter((template) => template.id !== deletingTemplateId) || [],
            );
            setToastInfo({ message: t('TemplatesPage.toastDeleteSuccess'), toastType: 'success' });
            setOpen(true);
          }, 1000);
        },
        onError: () => {
          // TODO: handle error here, like showing an error message
          console.log('Error deleting template');
        },
        onSettled: () => {
          setDeletingTemplateId(null);
        },
      });
    }
    handleDeleteModal(false);
  };
  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <div className="flex flex-row items-center gap-1">
          <span>{t('TemplatesPage.templateNameColumn')}</span>
          <span className="cursor-pointer">
            <ArrowDownUpIcon />
          </span>
        </div>
      ),
      cell: (info) => <span className="block truncate">{info.renderValue()}</span>,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <div>{t('TemplatesPage.createdAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('createdByName', {
      header: () => <div>{t('TemplatesPage.createdByColumn')}</div>,
      cell: (info) => <div className="truncate max-w-[12rem]">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('lastModifiedAt', {
      header: () => <div>{t('TemplatesPage.lastModifiedAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('lastModifiedByName', {
      header: () => <div>{t('TemplatesPage.lastModifiedByColumn')}</div>,
      cell: (info) => <div className="truncate max-w-[12rem]">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.id;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5">...</span>
              </div>
            </div>
            {openDropdownId === rowId && (
              <div className="absolute right-0 z-10 p-2 pt-2 pb-2 px-2 shadow-md w-40 rounded-lg bg-custom-darkBlue border-[0.063rem] border-gray-800">
                <div
                  key={'0'}
                  className="group/item flex items-center gap-2 px-2 py-3 mb-1 cursor-pointer rounded-lg hover:bg-activeSideMenuOption hover:text-primary-400 text-gray-200"
                  onClick={() => handleOnEdit(info.row.original)}
                >
                  <span className="group-hover/item:[&>*_path]:stroke-primary-400">
                    <EditPencilIcon />
                  </span>
                  <span className="font-normal">{t('TemplatesPage.linkEdit')}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <path d="M0 1H145.878H147" stroke="#313B4B" />
                </svg>
                <div
                  key={'1'}
                  className="group/item flex items-center gap-2 px-2 py-3 mt-1 cursor-pointer rounded-lg hover:bg-deleteButtonHoverBG text-red-400"
                  onClick={() => handleOnDelete(info.renderValue())}
                >
                  <span>
                    <TrashIcon color="#ff9296" />
                  </span>
                  <span className="font-normal">{t('TemplatesPage.linkDelete')}</span>
                </div>
              </div>
            )}
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
          </div>
        );
      },
    }),
  ];

  const deleteConfirmationModal = (
    <Modal
      isOpen={showDeleteModal}
      title={t('TemplatesPage.modalTitle')}
      body={t('TemplatesPage.modalBody')}
      cancelBtnTxt={t('TemplatesPage.modalCancelBtn')}
      confirmBtnTxt={t('TemplatesPage.modalConfirmBtn')}
      onCancel={() => handleDeleteModal(false)}
      onConfirm={handleConfirmDelete}
    />
  );

  return { columns, deleteConfirmationModal };
};
