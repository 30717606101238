import { useQuery } from 'react-query';
import axios from 'axios';

import queryKeys from '@/modules/shared/query/queryKeys';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function getIsUserApproved(id: string, email: string) {
  const response = await axios.get(
    `${AwsConfigApiGateway.adminApiUrl}/inquiry?id=${id}&email=${encodeURIComponent(email)}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      },
    },
  );
  return response.data;
}

export function useGetIsUserApproved(id: string, email: string) {
  return useQuery({
    queryKey: [queryKeys.inquiry],
    queryFn: () => getIsUserApproved(id, email),
    refetchOnWindowFocus: false,
  });
}
