import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { CreateCampaignDetails } from '@/models/Campaigns';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createCampaign(data: CreateCampaignDetails, token: string | undefined, workspaceId: string) {
  const response = await axios.post<CreateCampaignDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/campaigns`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreateCampaign() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<CreateCampaignDetails, Error, CreateCampaignDetails>((data) =>
    createCampaign(data, user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  );
}
