import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreateTeamMember } from '@/api/settings/useCreateTeamMember';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import StopIcon from '@/component-library/primitives/Icons/StopIcon/StopIcon';
import Link from '@/component-library/primitives/Link/Link';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import { CreateTeamMemberDetails } from '@/models/TeamMembers';
import { selectWorkspaces } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { UserRoles } from '@/modules/shared/enums/enums';
import { AppRoutes } from '@/utils/routes/router';

import RoleCard from './RoleCard';

const CreateTeamMember = () => {
  const createTeamMemberMutation = useCreateTeamMember();
  const workspaces = useAppSelector(selectWorkspaces);
  const filteredWorkspaces = workspaces?.map((workspace) => {
    return {
      name: workspace.name,
      role: UserRoles.TENANT_ADMIN,
      access: false,
    };
  });
  const [workspacesData, setWorkspacesData] = useState(filteredWorkspaces);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { t } = useTranslation();
  const [accessError, setAccessError] = useState('');

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        firstName: yup.string().trim().required(t('CommonErrors.firstNameRequired')),
        lastName: yup.string().trim().required(t('CommonErrors.lastNameRequired')),
        email: yup.string().trim().email('CommonErrors.emailFormatValidation').required('CommonErrors.emailRequired'),
      })
      .required();
  }, [t]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.teamMembers), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTeamMemberDetails>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: CreateTeamMemberDetails) => {
    const atLeastOneHasAccess = workspacesData.some((workspace) => workspace.access === true);
    if (!atLeastOneHasAccess) {
      setAccessError(t('CreateTeamMemberPage.accessToAtLeastOneWorkspaceError'));
    } else {
      const sendData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        role: userAssignRole,
        workspaces: workspacesData,
      };
      createTeamMemberMutation.mutate(sendData as CreateTeamMemberDetails, {
        onSuccess: () => {
          setAccessError('');
          setToastInfo({ message: t('CreateTeamMemberPage.toastSuccess'), toastType: 'success' });
          setOpen(true);
        },
        onError: () => {
          setAccessError('');
          setToastInfo({ message: t('CreateTeamMemberPage.toastError'), toastType: 'error' });
          setOpen(true);
        },
      });
    }
  };

  const updateAccess = (workspaceId: number) => {
    setWorkspacesData((state) =>
      state.map((el, id) => {
        return id === workspaceId ? { ...el, access: !el.access } : el;
      }),
    );
  };

  const disableAll = () => {
    setWorkspacesData((state) =>
      state.map((el) => {
        return { ...el, access: false };
      }),
    );
  };

  const [userAssignRole, setUserAssignRole] = useState<string>(UserRoles.TENANT_ADMIN);
  const handleRoleSelect = (role: string) => {
    setUserAssignRole(() => role);
  };

  const isRoleSelected = (role: string) => userAssignRole === role;

  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-32">
        <div className="flex flex-col items-center mt-4">
          <div className="relative mb-8 w-[40rem]">
            <h1 className="text-white font-medium text-xl mb-2">{t('CreateTeamMemberPage.title')}</h1>
          </div>
          <form className="w-[40rem]" onSubmit={handleSubmit(onSubmit)}>
            <section className="space-y-4 mb-10">
              <h2 className="text-xs font-semibold text-yellow-500 mb-5">
                {t('CreateTeamMemberPage.attributesLabel')}
              </h2>
              <Input
                label={t('CreateTeamMemberPage.firstNameLabel')}
                placeholder={t('CreateTeamMemberPage.firstNamePlaceholder')}
                {...register('firstName')}
                error={t(errors.firstName?.message) as string}
              />

              <Input
                label={t('CreateTeamMemberPage.lastNameLabel')}
                placeholder={t('CreateTeamMemberPage.lastNamePlaceholder')}
                {...register('lastName')}
                error={t(errors.lastName?.message) as string}
              />

              <Input
                label={t('CreateTeamMemberPage.emailLabel')}
                placeholder={t('CreateTeamMemberPage.emailPlaceholder')}
                {...register('email')}
                error={t(errors.email?.message) as string}
              />
            </section>
            <hr className="w-full border-custom-darkBlue" />
            <section className="mt-10 mb-10">
              <h2 className="text-xs font-semibold text-yellow-500 mb-5 uppercase">
                {t('CreateTeamMemberPage.roleLabel')}
              </h2>
              <div className="flex flex-row flex-wrap items-center justify-center gap-3">
                <RoleCard
                  roleLabel={UserRoles.TENANT_ADMIN}
                  description={t('CreateTeamMemberPage.administratorRoleDescription')}
                  isRoleSelected={isRoleSelected}
                  handleRoleSelect={handleRoleSelect}
                />
                <RoleCard
                  roleLabel={UserRoles.TENANT_MANAGER}
                  description={t('CreateTeamMemberPage.managerRoleDescription')}
                  isRoleSelected={isRoleSelected}
                  handleRoleSelect={handleRoleSelect}
                />
                <RoleCard
                  roleLabel={UserRoles.TENANT_AUTHOR}
                  description={t('CreateTeamMemberPage.authorRoleDescription')}
                  isRoleSelected={isRoleSelected}
                  handleRoleSelect={handleRoleSelect}
                />
                <RoleCard
                  roleLabel={'TenantUser'}
                  description={t('CreateTeamMemberPage.userRoleDescription')}
                  isRoleSelected={isRoleSelected}
                  handleRoleSelect={handleRoleSelect}
                />
              </div>
            </section>
            <hr className="w-full border-custom-darkBlue" />
            <section className="mt-10">
              <h2 className="text-xs font-semibold text-yellow-500 mb-5 uppercase">
                {t('CreateTeamMemberPage.workspacesLabel')}
              </h2>
              <table className="w-full">
                <thead>
                  <tr className="text-xs text-secondary-400 font-semibold tracking-[0.24px] border-b-2 border-b-gray-800">
                    <th className="text-left pb-2 uppercase">{t('CreateTeamMemberPage.workspaceNameLabel')}</th>
                    <th className="text-left pb-2 uppercase">{t('CreateTeamMemberPage.roleLabel')}</th>
                    <th className="text-left pb-2 uppercase">{t('CreateTeamMemberPage.accessLabel')}</th>
                    <th className="pb-2 text-right">
                      <button
                        type="button"
                        onClick={disableAll}
                        className="uppercase hover:bg-deleteButtonHoverBG text-red-400 rounded-lg px-2 py-3"
                      >
                        <div className="flex flex-row items-center gap-x-1">
                          <StopIcon width="18" height="18" />
                          {t('CreateTeamMemberPage.linkDisableAll')}
                        </div>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workspacesData?.map((workspace: any, index: number) => (
                    <tr key={index} className="text-gray-300 text-sm font-normal">
                      <td className="py-2 w-[40%]">{workspace.name}</td>
                      <td className="py-2 w-[30%]">{userAssignRole}</td>
                      <td className="py-2">
                        <div className="mt-2">
                          <ToggleSwitch value={workspace.access} onChange={() => updateAccess(index)} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {accessError && (
                <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
                  <CircleWarningIcon />
                  {accessError}
                </p>
              )}
            </section>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[40rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.teamMembers)}>
                  {t('CreateTeamMemberPage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={createTeamMemberMutation.isLoading || createTeamMemberMutation.isSuccess}
                >
                  {t('CreateTeamMemberPage.buttonAddTeamMember')}
                </Button>
              </div>
            </div>
          </form>
          <ProvideToasts>
            <Toast
              isOpen={open}
              setOpen={setOpen}
              message={toastInfo.message}
              toastType={toastInfo.toastType as ToastType}
            />
          </ProvideToasts>
        </div>
      </div>
    </>
  );
};
export default CreateTeamMember;
