/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useParams } from 'react-router';

import { useGetNotificationsDetailsById } from '@/api/messages/useGetNotificationDetailsById';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  selectIsCollapsed,
  setCollapsedSidebar,
  setIsCollapsableIconHidden,
} from '@/component-library/layouts/SideMenu/sideMenuSlice';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import Table from '@/component-library/widgets/Table/Table';
import { NotificationOverview } from '@/models/NotificationsByWorkspace';

import BasicDataSidePanel from './BasicDataSidePanel';
import ContentModal from './ContentModal';
import { useNotificationsTable } from './useNotificationsTable';

const NotificationOverviewPage = () => {
  const { id: notificationId } = useParams();
  const { data, isFetched, isLoading } = useGetNotificationsDetailsById(notificationId);
  const { columns } = useNotificationsTable();

  const [notification, setNotification] = useState<NotificationOverview | undefined>(undefined);
  const [showContentModal, setShowContentModal] = useState(false);
  const collapsedSidebar = useAppSelector(selectIsCollapsed);
  const [previousCollapsedState, setPreviousCollapsedState] = useState(collapsedSidebar);

  const handleNotificationContentModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowContentModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowContentModal(state);
        });
      });
    }
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setCollapsedSidebar(true));
    dispatch(setIsCollapsableIconHidden(true));
    isFetched && setNotification(data);
    return () => {
      !previousCollapsedState && dispatch(setCollapsedSidebar(false));
      dispatch(setIsCollapsableIconHidden(false));
    };
  }, [dispatch, isFetched]);

  return (
    <div className="h-full">
      {/* Notification side menu */}
      <BasicDataSidePanel notificationData={notification} setShowContentModal={handleNotificationContentModal} />
      {/* Recipients table */}
      <div className="ml-[16.688rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] h-full">
        {isLoading ? (
          <div className="absolute w-[70%] h-[70%] z-50">
            <Loader />
          </div>
        ) : data && data.notifications.length > 0 ? (
          <div className="mt-6">
            <Table
              columns={columns}
              data={data?.notifications ?? []}
              className={{
                row: '',
                cell: 'overflow-visible',
              }}
            />
          </div>
        ) : (
          <div className="h-[32rem]">
            <NoDataBoard />
          </div>
        )}
      </div>
      <ContentModal
        onCancel={() => handleNotificationContentModal(false)}
        isOpen={showContentModal}
        templateData={data?.templateBody || ''}
      />
    </div>
  );
};

export default NotificationOverviewPage;
