import { SVGProps } from 'react';

interface LayersIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const LayersIcon: React.FC<LayersIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M17.5 10.1965L10 15.1965L2.5 10.1965M17.5 13.5298L10 18.5298L2.5 13.5298M17.5 6.86313L10 11.8631L2.5 6.86313L10 1.86313L17.5 6.86313Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LayersIcon;
