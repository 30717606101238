import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { EmailMetricsDetails } from '@/models/EmailMetrics';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function geEmailMetrics(token: string | undefined, workspaceId: string, startTime: string, endTime: string) {
  const response = await axios.post<EmailMetricsDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/dashboard/details`,
    {
      workspaceId,
      startTime,
      endTime,
    },
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useGetEmailMetricsByWorkspace(startTime: string, endTime: string) {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);

  const finalStartTime = startTime.toLowerCase().includes('.')
    ? startTime.substring(0, startTime.indexOf('.'))
    : startTime;
  const finalEndTime = endTime.toLowerCase().includes('.') ? endTime.substring(0, endTime.indexOf('.')) : endTime;
  let validDate = false;
  if (!isNaN(new Date(startTime) as any) && !isNaN(new Date(endTime) as any)) {
    validDate = true;
  } else {
    validDate = false;
  }

  return useQuery<EmailMetricsDetails>({
    queryKey: [queryKeys.emailMetrics, finalStartTime, finalEndTime],
    queryFn: () =>
      geEmailMetrics(user?.signInUserSession?.idToken.jwtToken, workspace?.id ?? '', finalStartTime, finalEndTime),
    enabled: validDate,
  });
}
