import { useMutation } from 'react-query';
import axios from 'axios';

import { RegistrationData } from '@/models/RegistrationData';
import { AwsConfigApiGateway } from '@/utils/config/api';

export function useRegisterUser() {
  return useMutation((data: RegistrationData) => axios.post(`${AwsConfigApiGateway.adminApiUrl}/registration`, data), {
    onSuccess: () => {
      // Add any actions you want to perform on successful mutation
    },
    onError: () => {
      // Add any actions you want to perform if mutation fails
    },
  });
}
