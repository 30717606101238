import { useEffect, useState } from 'react';
import { Cell, Pie, PieChart as PieChartRecharts, Tooltip } from 'recharts';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface ChartDataItem {
  name: string;
  value: number;
}
interface ChartColorItem {
  name: string;
  value: string;
}

const MAX_CHART_WIDTH = 400;

const NO_DATA_COLOR = '#546583';

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    console.log(payload);
    return (
      <div
        style={{
          background: '#313B4BAA',
          border: 0,
          borderRadius: '8px',
          color: '#fff',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          columnGap: '4px',
        }}
      >
        <div className={`w-2 h-2 rounded-full`} style={{ background: `${payload[0].payload.fill}` }}></div>
        <p className="text-sm">{`${payload[0].name} : ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

const PieChart = ({
  data,
  colors,
  hasLegend = true,
}: {
  data: ChartDataItem[];
  colors?: ChartColorItem[];
  hasLegend?: boolean;
}) => {
  const [chartData, setChartData] = useState(data);
  const [chartColors, setChartColors] = useState(colors);
  useEffect(() => {
    const valuesSum = data.reduce((acc, current) => (acc += current.value), 0);
    if (valuesSum === 0) {
      setChartData([{ value: 100, name: '' }]);
      setChartColors([{ name: '/', value: NO_DATA_COLOR }]);
    }
  }, [data, colors]);

  return (
    <div className="flex flex-row justify-center text-lg font-semibold items-center lg:gap-3 3xl:gap-12 py-4">
      <div className={`${hasLegend ? 'w-[50%]' : 'w-[100%]'} flex justify-end`}>
        <PieChartRecharts width={MAX_CHART_WIDTH} height={MAX_CHART_WIDTH}>
          <Pie
            dataKey="value"
            data={chartData}
            cx={MAX_CHART_WIDTH / 2}
            cy={MAX_CHART_WIDTH / 2}
            innerRadius={90}
            outerRadius={160}
            stroke={NO_DATA_COLOR}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={chartColors?.at(index)?.value} style={{ outline: 'none' }} />
            ))}
          </Pie>
          {data.reduce((acc, current) => (acc += current.value), 0) > 0 && (
            <Tooltip reverseDirection={{ y: true }} content={<CustomTooltip />} />
          )}
        </PieChartRecharts>
      </div>
      <div className={`${hasLegend ? 'w-[50%]' : 'hidden'}`}>
        {data.map((item) => (
          <div className="flex flex-row items-center" key={item.name}>
            <div
              className={`w-3 h-3 rounded-full`}
              style={{ background: colors?.find((color) => color.name === item.name)?.value }}
            ></div>
            <div className="flex flex-row justify-between w-[60%]">
              <span className="ml-3 text-ellipsis whitespace-nowrap overflow-hidden">{item.name}</span>
            </div>
            <span className="ml-2">{item.value}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
