import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import Header from '@/component-library/layouts/Header/Header';
import { Button } from '@/component-library/primitives/Button/Button';
import { AppRoutes } from '@/utils/routes/router';

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnContactUs = () => {
    navigate(AppRoutes.contactUs);
  };

  return (
    <>
      <Header />
      <div className="top-0 left-0 right-0 bottom-0 flex flex-col justify-between h-screenWithoutHeader px-2 sm:px-6 lg:px-8 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <div className="flex flex-col sm:flex-row justify-between items-start p-2 w-full lg:max-w-[80%] 3xl:max-w-[75%] m-auto h-full">
          <div className="text-white sm:w-1/2 mt-12">
            <h1 className="text-3xl sm:text-4xl mb-6 text-primary-400 leading-9" data-testid="@homepage/title">
              {t('HomePage.title')}
            </h1>
            <h2 className="text-5xl font-extrabold mb-4 text-gray-100 leading-heading">
              <Trans
                i18nKey="HomePage.subtitle"
                components={{
                  primary: <span className="text-yellow-300" />,
                  secondary: <br />,
                }}
              />
            </h2>
            <p className="font-light mb-8 text-gray-200">
              <Trans
                i18nKey="HomePage.description"
                components={{
                  primary: <br />,
                }}
              />
            </p>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col gap-y-4">
                <span>{t('HomePage.alreadyHaveAccount')}</span>
                <NavLink
                  className="w-fit text-primary-400 hover:text-primary-500 active:text-primary-300"
                  to={AppRoutes.signIn}
                >
                  {t('HomePage.linkSignIn')}
                </NavLink>
              </div>
              <div className="flex flex-col gap-y-4">
                <span>{t('HomePage.wantToKnowMore')}</span>
                <Button variant="primary" data-cy="buttonRegister" size="small" onClick={handleOnContactUs}>
                  {t('HomePage.buttonContactUs')}
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <img src="images/landing.png" alt="Placeholder" className="w-full" />
          </div>
        </div>

        <footer className="text-gray-300 text-center mb-4">{t('HomePage.copyright')}</footer>
      </div>
    </>
  );
};
export default HomePage;
