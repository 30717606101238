import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { SubmitedPushConfig, SubmitedPushConfigs } from '@/models/PushNotificationDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function getSubmitedConfigs(token: string | undefined, workspaceId: string) {
  const response = await axios.get<SubmitedPushConfigs>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/push/config?workspaceId=${workspaceId}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetSubmitedConfigs(): UseQueryResult<SubmitedPushConfig[]> {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);

  return useQuery<SubmitedPushConfig[]>({
    queryKey: ['submitedPushConfig'],
    queryFn: () => getSubmitedConfigs(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? ''),
  });
}
