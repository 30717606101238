import { SVGProps } from 'react';

interface UploadIconIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const UploadIcon: React.FC<UploadIconIconProps> = ({ color = '#F5F7FC', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 21H18M12 17V3M12 3L17 8M12 3L7 8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default UploadIcon;
