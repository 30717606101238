interface InstagramIconProps {
  color?: string;
}

export default function InstagramIcon({ color = 'white' }: InstagramIconProps) {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7759 22.7814C20.826 22.7814 23.2986 20.3088 23.2986 17.2587C23.2986 14.2085 20.826 11.7359 17.7759 11.7359C14.7257 11.7359 12.2531 14.2085 12.2531 17.2587C12.2531 20.3088 14.7257 22.7814 17.7759 22.7814Z"
        fill={color}
        fillOpacity="0.58"
      />
      <path
        d="M25.3696 0H10.182C7.61877 0 5.16048 1.01826 3.34797 2.83076C1.53547 4.64327 0.517212 7.10156 0.517212 9.66483V24.8524C0.517212 27.4157 1.53547 29.874 3.34797 31.6865C5.16048 33.499 7.61877 34.5172 10.182 34.5172H25.3696C27.9329 34.5172 30.3912 33.499 32.2037 31.6865C34.0162 29.874 35.0345 27.4157 35.0345 24.8524V9.66483C35.0345 7.10156 34.0162 4.64327 32.2037 2.83076C30.3912 1.01826 27.9329 0 25.3696 0ZM17.7758 25.5428C16.1374 25.5428 14.5357 25.0569 13.1734 24.1466C11.8111 23.2364 10.7493 21.9426 10.1223 20.4288C9.49528 18.9151 9.33123 17.2494 9.65087 15.6425C9.97052 14.0355 10.7595 12.5594 11.9181 11.4009C13.0766 10.2423 14.5527 9.45331 16.1597 9.13366C17.7666 8.81402 19.4323 8.97807 20.946 9.60508C22.4598 10.2321 23.7536 11.2939 24.6638 12.6562C25.5741 14.0185 26.06 15.6202 26.06 17.2586C26.06 19.4557 25.1872 21.5628 23.6336 23.1164C22.08 24.67 19.9729 25.5428 17.7758 25.5428ZM26.7503 10.3552C26.3407 10.3552 25.9403 10.2337 25.5997 10.0061C25.2591 9.77857 24.9937 9.45512 24.8369 9.07669C24.6802 8.69826 24.6392 8.28184 24.7191 7.8801C24.799 7.47836 24.9962 7.10933 25.2859 6.81969C25.5755 6.53006 25.9445 6.33281 26.3463 6.2529C26.748 6.17299 27.1644 6.214 27.5429 6.37075C27.9213 6.5275 28.2447 6.79295 28.4723 7.13353C28.6999 7.47411 28.8214 7.87453 28.8214 8.28414C28.8214 8.83341 28.6032 9.36019 28.2148 9.74858C27.8264 10.137 27.2996 10.3552 26.7503 10.3552Z"
        fill={color}
        fillOpacity="0.58"
      />
    </svg>
  );
}
