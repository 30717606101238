import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionMeta, MultiValue } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreatePushNotification } from '@/api/messages/pushNotifications/useCreatePushNotification';
import { useGetSubmitedConfigs } from '@/api/messages/pushNotifications/useGetSubmittedConfigs';
import { useGetSegments } from '@/api/segments/useGetSegments';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import Chip from '@/component-library/primitives/Chip/Chip';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import GearIcon from '@/component-library/primitives/Icons/GearIcon/GearIcon';
import ImageIcon from '@/component-library/primitives/Icons/ImageIcon/ImageIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import PhoneDevice from '@/component-library/primitives/PhoneDevice/PhoneDevice';
import { RadioButton } from '@/component-library/primitives/RadioButton/RadioButton';
import Stepper from '@/component-library/primitives/Stepper/Stepper';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
// import SimpleFileUpload from '@/component-library/widgets/FileUploadInput/SimpleFileUpload';
import { Input } from '@/component-library/widgets/Input/Input';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import SelectField, { OptionType } from '@/component-library/widgets/SelectField/SelectField';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import CreatePushNotifications from '@/models/PushNotificationDetails';
import { Audience } from '@/models/PushNotificationDetails';
import { Segment } from '@/models/Segments';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { NotificationServiceEnum } from '@/modules/shared/enums/enums';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
// import { readFileListFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

const CreatePushNotification = () => {
  const createPushMutation = useCreatePushNotification();
  const { data: segmentData, isFetched } = useGetSegments();
  const workspace = useAppSelector(selectCurrentWorkspace);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [activeStep, setActiveStep] = useState(0);
  const divWhereToScroll = useRef<HTMLDivElement>(null);
  const [deliveryScheduleType, setDeliveryScheduleType] = useState('Immediately');
  const [isEveryoneShouldReceiveMsgActive, setIsEveryoneShouldReceiveMsgActive] = useState(false);
  const [isSegmentsIconHovered, setIsSegmentsIconHovered] = useState(false);
  const [isAdvancedSettingsOptionSelected, setIsAdvancedSettingsOptionSelected] = useState(true);
  const [isDeliveryScheduleIconHovered, setIsIDeliveryScheduleconHovered] = useState(false);
  const [sharedOptionsState, setSharedOptionsState] = useState<OptionType[] | undefined>([]);
  const { data: submitedPushConfigData } = useGetSubmitedConfigs();
  // const MAX_FILE_SIZE = 1048576;

  const steps = [
    t('CreatePushNotificationsPage.notificationInfoStep'),
    t('CreatePushNotificationsPage.notificationEditorStep'),
    t('CreatePushNotificationsPage.notificationPreviewStep'),
  ];

  const availableConfigs = submitedPushConfigData || [];

  const isActionAllowed = usePermissionChecker(PermissionsEnum.NOTIFICATIONS, HttpMethodsEnum.POST);

  const hasFirebaseService = availableConfigs.some((item) => item.service === NotificationServiceEnum.FIREBASE);
  const hasApnsService = availableConfigs.some((item) => item.service === NotificationServiceEnum.APNS);

  const availableSegments = segmentData?.map((segment) => {
    return {
      label: segment.name,
      value: segment.id,
    };
  });

  useEffect(() => {
    isFetched && setSharedOptionsState(availableSegments);
  }, [isFetched]);

  const sharedOptions = useRef<OptionType[]>([]);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.delivery), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return [
      yup.object({
        messageTitle: yup.string().trim().required(t('CommonErrors.messageTitleRequired')),
        segmentsShouldInclude: !isEveryoneShouldReceiveMsgActive
          ? yup
              .array()
              .required(t('CreatePushNotificationsPage.atLeastOneSegmentToReceiveMsgRequired'))
              .nullable()
              .min(1, t('CreatePushNotificationsPage.atLeastOneSegmentToReceiveMsgRequired'))
          : yup.array(),
        scheduleTime:
          deliveryScheduleType === 'Specific Time'
            ? yup
                .date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .required(t('CreatePushNotificationsPage.scheduleDateTimeRequired'))
                .min(
                  new Date(new Date().getTime() + 15 * 60000),
                  t('CreatePushNotificationsPage.scheduleDateTimeInFutureRequired'),
                )
            : yup.string(),
        eventBasedTrigger:
          deliveryScheduleType === 'Event Based'
            ? yup.string().trim().required(t('CreatePushNotificationsPage.eventNameRequired'))
            : yup.string(),
      }),
      yup.object({
        subject: yup.string().trim().required(t('CommonErrors.subjectRequired')),
        notificationText: yup.string().trim().required(t('CommonErrors.textRequired')),
        // image: yup.mixed().test('fileSize', t('CreatePushNotificationsPage.fileSizeValidation'), (value) => {
        //   if (value instanceof FileList) {
        //     if (!value || !value[0]) {
        //       return true;
        //     }
        //     const file = value[0];
        //     return file.size <= MAX_FILE_SIZE;
        //   }
        // }),
      }),
      yup.object({}),
    ];
  }, [deliveryScheduleType, isEveryoneShouldReceiveMsgActive, t]);

  const currentValidationSchema = schema[activeStep];

  const methods = useForm<CreatePushNotifications>({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
    defaultValues: {
      deliveryScheduleType: 'Immediately',
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const watchNotificationText = watch('notificationText');
  const watchSubject = watch('subject');
  // const selectedImageFile = watch('image');

  const onBack = () => {
    activeStep === 0 ? navigate(AppRoutes.delivery) : setActiveStep((prevActiveStep) => prevActiveStep - 1);
    divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onNext = async () => {
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      divWhereToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatSegmentObject = (segmentData: Segment[] | undefined, selectedOptions: Audience[] | undefined) => {
    const arrayData = [];

    if (segmentData && selectedOptions) {
      for (let i = 0; i < segmentData.length; i++) {
        for (let j = 0; j < selectedOptions.length; j++) {
          if (segmentData[i].id === selectedOptions[j].value) {
            arrayData.push(segmentData[i]);
          }
        }
      }
    }
    return arrayData.map((item) => {
      return {
        name: item.name,
        PK: item.PK,
        SK: item.SK,
      };
    });
  };

  const onSubmit = async (data: CreatePushNotifications) => {
    // const imageBase64 = await readFileListFileAsBase64(data.image);

    const notificationData = {
      workspaceId: workspace?.id ?? '',
      messageTitle: data.messageTitle.trim(),
      platform: platform,
      segmentsShouldInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldInclude),
      segmentsShouldNotInclude: isEveryoneShouldReceiveMsgActive
        ? []
        : formatSegmentObject(segmentData, data.segmentsShouldNotInclude),
      subject: data.subject.trim(),
      link: data.link.trim(),
      notificationText: data.notificationText.trim(),
      // ...(imageBase64 !== null ? { image: imageBase64 } : {}),
      deliveryScheduleType: data.deliveryScheduleType,
      specificTime:
        deliveryScheduleType === 'Specific Time' ? new Date(getValues('scheduleTime') as string).toISOString() : '',
      eventBasedTrigger: deliveryScheduleType === 'Event Based' ? data.eventBasedTrigger : '',
    };

    createPushMutation.mutate(notificationData, {
      onSuccess: () => {
        setToastInfo({ message: t('CreatePushNotificationsPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('CreatePushNotificationsPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const handleShouldEveryoneReceiveMsgToggle = (value: boolean) => {
    setIsEveryoneShouldReceiveMsgActive(value);
    if (!isEveryoneShouldReceiveMsgActive) {
      setValue('segmentsShouldInclude', []);
      setValue('segmentsShouldNotInclude', []);
      setSharedOptionsState(availableSegments);
      clearErrors('segmentsShouldInclude');
    }
  };

  const updateAvailableSegmentOptions = (multiValue: MultiValue<Audience>, action: ActionMeta<Audience>) => {
    if (sharedOptionsState) {
      if (action.removedValue) {
        const filteredSelectedOptions = sharedOptions.current.filter((i) => i.value !== action.removedValue?.value);
        sharedOptions.current = filteredSelectedOptions;
        setSharedOptionsState(availableSegments);
      } else if (action.removedValues) {
        for (let i = 0; i < sharedOptions.current.length; i++) {
          for (let j = 0; j < action.removedValues.length; j++) {
            if (sharedOptions.current[i].value === action.removedValues[j].value) {
              sharedOptions.current.splice(i, 1);
            }
          }
        }
        setSharedOptionsState(availableSegments);
      } else {
        sharedOptions.current.push(multiValue[multiValue.length - 1]);
        setSharedOptionsState(availableSegments);
      }
      for (const x of sharedOptions.current) {
        setSharedOptionsState((prev: OptionType[] | undefined) =>
          prev?.filter((item: OptionType) => item.value !== x.value),
        );
      }
    }
  };

  // const [imagePreview, setImagePreview] = useState<string | null>(null);

  // useEffect(() => {
  //   if (selectedImageFile && selectedImageFile instanceof FileList) {
  //     const file = selectedImageFile[0];
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const result = e.target?.result as string;
  //       setImagePreview(result);
  //     };
  //     if (file instanceof Blob) {
  //       reader.readAsDataURL(file);
  //     } else {
  //       setImagePreview(null);
  //     }
  //   } else {
  //     setImagePreview(null);
  //   }

  //   const redirectAfterToast = () => {
  //     let timerBeforeNavigation: NodeJS.Timeout;
  //     if (toastInfo.toastType === 'success') {
  //       timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.delivery), 2000);
  //     }
  //     return () => {
  //       clearTimeout(timerBeforeNavigation);
  //     };
  //   };

  //   redirectAfterToast();
  // }, [selectedImageFile, navigate, toastInfo]);

  const [platform, setPlatform] = useState<string>('Firebase');
  const handleChipClick = (platformName: string) => {
    setPlatform(() => platformName);
  };

  const isChipClicked = (platformName: string) => platform === platformName;

  return (
    <div className={`${createPushMutation.isLoading ? 'relative' : 'static'} mb-[150px]`}>
      {createPushMutation.isLoading && (
        <div className="absolute w-full h-[80vh] z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <div className="flex min-w-[40rem] items-start -ml-6" ref={divWhereToScroll}>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-[40rem]">
          <FormProvider {...methods}>
            <form className="space-y-6 min-w-[40rem] w-1/2">
              {/* Notification info step */}
              {activeStep === 0 && (
                <div>
                  <div className="mb-7">
                    <h1 className="text-white font-medium text-xl">
                      {t('CreatePushNotificationsPage.setUpPushNotifications')}
                    </h1>
                  </div>
                  <div className="space-y-7">
                    <Input
                      placeholder={t('CreatePushNotificationsPage.titlePlaceholder')}
                      label={t('CreatePushNotificationsPage.titleLabel')}
                      {...register('messageTitle')}
                      error={errors.messageTitle?.message as string}
                    />

                    {/* Segments Section */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">
                          {t('CreatePushNotificationsPage.segmentsLabel')}
                        </h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">
                              {t('CreatePushNotificationsPage.segmentsTooltip')}
                            </div>
                          }
                          open={isSegmentsIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsSegmentsIconHovered(true);
                            } else {
                              setIsSegmentsIconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="flex flex-row items-center gap-x-2 mb-7">
                        <span className="font-normal text-sm leading-[24px] text-gray-200">
                          {t('CreatePushNotificationsPage.everyoneShouldReceiveThisMessageLabel')}
                        </span>
                        <ToggleSwitch
                          value={isEveryoneShouldReceiveMsgActive}
                          onChange={handleShouldEveryoneReceiveMsgToggle}
                        />
                      </div>
                      <div
                        className={`space-y-3 ${
                          isEveryoneShouldReceiveMsgActive ? 'opacity-50 pointer-events-none' : ''
                        }`}
                      >
                        <SelectField
                          name="segmentsShouldInclude"
                          label={
                            <Trans
                              i18nKey="CreatePushNotificationsPage.segmentsReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldInclude?.message ? 'text-errorRed-500' : 'text-primary-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('CreatePushNotificationsPage.writeOrSelectLabel')}
                        />
                        <SelectField
                          name="segmentsShouldNotInclude"
                          label={
                            <Trans
                              i18nKey="CreatePushNotificationsPage.segmentsNotReceivingMessageLabel"
                              components={{
                                primary: (
                                  <span
                                    className={`${
                                      errors.segmentsShouldNotInclude?.message ? 'text-errorRed-500' : 'text-red-400'
                                    }`}
                                  />
                                ),
                              }}
                            />
                          }
                          control={control}
                          options={sharedOptionsState}
                          errorMsg={errors.segmentsShouldNotInclude?.message}
                          className={'mb-2'}
                          isMulti={true}
                          multiValueChipColor="red"
                          customOnChange={updateAvailableSegmentOptions}
                          placeholder={t('CreatePushNotificationsPage.writeOrSelectLabel')}
                        />
                      </div>
                      <div className="flex justify-start mt-5">
                        <Button
                          type="button"
                          variant="transparent"
                          iconLeft={
                            <span className="group-active:[&>*_path]:stroke-white">
                              <GearIcon color="#7898FB" />
                            </span>
                          }
                          className="group px-2"
                          onClick={() => navigate(AppRoutes.segments)}
                        >
                          {t('CreatePushNotificationsPage.linkManageSegments')}
                        </Button>
                      </div>
                    </section>

                    {/* Delivery schedule */}
                    <section>
                      <div className="flex flex-row items-center justify-between mb-3">
                        <h2 className="text-xs font-semibold text-yellow-500">
                          {t('CreatePushNotificationsPage.deliveryScheduleLabel')}
                        </h2>
                        <Tooltip
                          side="right"
                          content={
                            <div className="text-gray-100 text-xs font-light">
                              {t('CreatePushNotificationsPage.deliveryTooltip')}
                            </div>
                          }
                          open={isDeliveryScheduleIconHovered}
                          onOpenChange={(open: boolean) => {
                            if (open) {
                              setIsIDeliveryScheduleconHovered(true);
                            } else {
                              setIsIDeliveryScheduleconHovered(false);
                            }
                          }}
                          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                          arrowClassName="fill-custom-darkBlue w-5 h-2"
                        >
                          <button type="button">
                            <CircleHelpIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className={`flex flex-col`}>
                        <div
                          className="w-fit flex items-center h-12"
                          onClick={() => setDeliveryScheduleType('Immediately')}
                        >
                          <RadioButton
                            label={t('CreatePushNotificationsPage.sendNowLabel')}
                            {...register('deliveryScheduleType')}
                            value="Immediately"
                            className="w-fit"
                            checked={deliveryScheduleType === 'Immediately'}
                          />
                        </div>
                        <div className="flex flex-row gap-x-5 items-center h-12">
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Specific Time')}>
                            <RadioButton
                              label={t('CreatePushNotificationsPage.scheduleDateAndTimeLabel')}
                              {...register('deliveryScheduleType')}
                              value="Specific Time"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Specific Time'}
                            />
                          </div>
                          {deliveryScheduleType === 'Specific Time' && (
                            <Input
                              type="datetime-local"
                              className="!w-[15.25rem]"
                              {...register('scheduleTime')}
                              error={errors.scheduleTime?.message as string}
                            />
                          )}
                        </div>
                        <div className={`flex flex-row gap-x-5 items-center h-12`}>
                          <div className="w-fit" onClick={() => setDeliveryScheduleType('Event Based')}>
                            <RadioButton
                              label={t('CreatePushNotificationsPage.eventLabel')}
                              {...register('deliveryScheduleType')}
                              value="Event Based"
                              className="w-fit"
                              checked={deliveryScheduleType === 'Event Based'}
                            />
                          </div>
                          {deliveryScheduleType === 'Event Based' && (
                            <Input
                              placeholder={t('CreatePushNotificationsPage.eventNamePlaceholder')}
                              className="!w-[15.25rem]"
                              {...register('eventBasedTrigger')}
                              error={errors.eventBasedTrigger?.message as string}
                            />
                          )}
                        </div>
                      </div>
                    </section>

                    {/* Platform */}
                    <section>
                      <h2 className="text-xs font-semibold text-yellow-500 mb-3">
                        {t('CreatePushNotificationsPage.pushServiceLabel')}
                      </h2>
                      <div className="flex">
                        {hasFirebaseService && (
                          <Chip
                            label={'Firebase'}
                            className={`h-9  mr-2 ${
                              isChipClicked('Firebase')
                                ? 'bg-primary-400 text-gray-900'
                                : 'bg-gray-700 text-custom-aliceBlue'
                            } w-fit hover:cursor-pointer`}
                            onClick={() => handleChipClick('Firebase')}
                          />
                        )}
                        {hasApnsService && (
                          <Chip
                            label={'Apns'}
                            className={`h-9 mr-2 ${
                              isChipClicked('apns')
                                ? 'bg-primary-400 text-gray-900'
                                : 'bg-gray-700 text-custom-aliceBlue'
                            } w-fit hover:cursor-pointer`}
                            onClick={() => handleChipClick('apns')}
                          />
                        )}

                        {!hasApnsService && !hasFirebaseService && (
                          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
                            <CircleWarningIcon /> {t('CreatePushNotificationsPage.noPushServiceConfiguredMsg')}
                          </p>
                        )}
                      </div>
                      <div className="flex justify-start mt-5">
                        <Button
                          type="button"
                          variant="transparent"
                          iconLeft={
                            <span className="group-active:[&>*_path]:stroke-white">
                              <GearIcon color="#7898FB" />
                            </span>
                          }
                          className="group px-2"
                          onClick={() => navigate(AppRoutes.pushNotifications)}
                        >
                          {t('CreatePushNotificationsPage.linkConfigureService')}
                        </Button>
                      </div>
                    </section>
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('CreatePushNotificationsPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          disabled={!hasApnsService && !hasFirebaseService}
                          iconRight={
                            <span className="startFromScratchLabel group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('CreatePushNotificationsPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Notification editor step */}
              {activeStep === 1 && (
                <div className="flex justify-center">
                  <div className="flex flex-col items-center -ml-64">
                    <div className="flex flex-row gap-x-2 -mt-6">
                      <div className="3xl:w-[34.375rem] lg:w-[25rem] flex flex-col">
                        {/* Details */}
                        <section className="mt-6 space-y-4">
                          <h2 className="text-xs font-semibold text-yellow-500">
                            {t('CreatePushNotificationsPage.detailsLabel')}
                          </h2>
                          <Input
                            label={t('CreatePushNotificationsPage.subjectLabel')}
                            placeholder={t('CreatePushNotificationsPage.subjectPlaceholder')}
                            {...register('subject')}
                            error={errors.subject?.message as string}
                          />
                          <Input
                            label={t('CreatePushNotificationsPage.linkLabel')}
                            placeholder={t('CreatePushNotificationsPage.linkPlaceholder')}
                            {...register('link')}
                            error={errors.link?.message as string}
                          />
                          <div className="relative">
                            <Textarea
                              {...register('notificationText')}
                              placeholder={t('CreatePushNotificationsPage.notificationTextPlaceholder')}
                              error={errors.notificationText?.message}
                              label={t('CreatePushNotificationsPage.notificationTextLabel')}
                            />
                            <button
                              type="button"
                              className="bg-htmlEditorBtnBG p-1 h-6 rounded-[5px] flex items-center justify-center group absolute -top-1 right-0"
                            >
                              <span className="group-hover:[&>*_path]:stroke-gray-100">
                                <ImageIcon />
                              </span>
                            </button>
                          </div>
                          {/* <SimpleFileUpload
                    label="Image (optional)"
                    fileUploadLabel={t('CreatePushNotificationsPage.notificationImage')}
                    accept=".jpeg"
                    error={errors.image?.message}
                    {...register('image')}
                    defaultValue={selectedImageFile}
                      /> */}
                        </section>
                      </div>
                      <div className="3xl:w-[27.063rem] lg:w-[25rem] flex flex-col items-end">
                        <PhoneDevice isPushNotification>
                          <>
                            {(watchSubject || watchNotificationText) && (
                              <div className="absolute w-[17rem] top-[165px] left-[2.8rem] min-h-[2rem] max-h-[25rem] bg-pushNotificationBG backdrop-blur-[4.39286px] rounded-2xl px-[14px] py-3 animate-slide">
                                <div className="flex flex-row items-center justify-between mb-2">
                                  <div className="flex flex-row items-center gap-x-[6px]">
                                    <div className="flex justify-center items-center rounded-full w-[14px] h-[14px] bg-primary-400">
                                      <div className="bg-primary-700 rounded-full w-[6px] h-[6px]"></div>
                                    </div>
                                    <span className="text-primary-400 font-normal text-[10px] leading-[14px]">
                                      {t('CreatePushNotificationsPage.cyberRavenLabel')}
                                    </span>
                                    <span className="text-gray-500 font-normal text-[10px] leading-[14px]">
                                      {t('CreatePushNotificationsPage.nowLabel')}
                                    </span>
                                  </div>
                                  <ChevronIcon width="9" height="5" color="#A8A8A8" type="down" />
                                </div>
                                <div className="mb-1 font-normal text-[13px] leading-[18px] text-custom-lightGray">
                                  {watchSubject}
                                </div>
                                <div className="text-custom-pushNotificationText text-[11px] leading-[18px] font-normal max-h-[20rem] overflow-y-auto [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                                  {watchNotificationText}
                                </div>
                              </div>
                            )}
                          </>
                        </PhoneDevice>
                      </div>
                    </div>
                  </div>

                  {/* Advanced settings */}
                  <div className="absolute top-[8.25rem] right-0 px-8 h-attributesSection 3xl:w-[23rem] lg:w-[20rem] border-l border-gray-800 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                    <h2 className="text-xs font-semibold text-yellow-500 mb-4">
                      {t('CreatePushNotificationsPage.advancedSettingsLabel')}
                    </h2>
                    <div className="mb-3">
                      <div className="bg-darkBlue w-fit p-1 rounded-xl flex flex-row gap-x-2 relative">
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'primary' : 'transparent'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(true)}
                        >
                          {t('CreatePushNotificationsPage.buttonEmailAddress')}
                        </Button>
                        <Button
                          type="button"
                          variant={isAdvancedSettingsOptionSelected ? 'transparent' : 'primary'}
                          className="!h-10 3xl:!w-[9.656rem] lg:!w-[8rem]"
                          onClick={() => setIsAdvancedSettingsOptionSelected(false)}
                        >
                          {t('CreatePushNotificationsPage.buttonFiltering')}
                        </Button>
                      </div>
                    </div>
                    {isAdvancedSettingsOptionSelected ? (
                      <>
                        <SearchField withFilter={false} className="3xl:!w-[16.8rem] lg:!w-[13.5rem]" />
                        <h2 className="text-xs font-semibold text-yellow-500 mt-8">
                          {t('CreatePushNotificationsPage.resultsLabel')}
                        </h2>
                      </>
                    ) : (
                      <h2 className="text-xs font-semibold text-yellow-500 mt-8">FILTERS</h2>
                    )}
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('CreatePushNotificationsPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('CreatePushNotificationsPage.buttonPrevious')}
                        </Button>
                        <Button
                          type="button"
                          className="group pl-4 !w-[8.125rem]"
                          onClick={onNext}
                          iconRight={
                            <span className="group-hover:[&>*_path]:stroke-gray-900 group-active:[&>*_path]:stroke-white">
                              <ChevronNewIcon type="right" color="#1C222A" width="20" height="20" />
                            </span>
                          }
                        >
                          {t('CreatePushNotificationsPage.buttonNext')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Preview step */}
              {activeStep === 2 && (
                <div className="w-[31.938rem]">
                  <h1 className="text-white font-medium text-xl mb-2">
                    {t('CreatePushNotificationsPage.previewLabel')}
                  </h1>
                  <Input
                    placeholder={t('CreatePushNotificationsPage.titlePlaceholder')}
                    label={t('CreatePushNotificationsPage.titleLabel')}
                    {...register('messageTitle')}
                    error={errors.messageTitle?.message as string}
                    value={getValues('messageTitle')}
                    className="pointer-events-none"
                  />
                  <section className="mt-4 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('CreatePushNotificationsPage.audienceReceivingPushNotificationsLabel')}
                    </h2>
                    {isEveryoneShouldReceiveMsgActive && (
                      <span className="font-normal text-sm leading-[24px] text-gray-200">
                        {t('CreatePushNotificationsPage.everyoneShouldReceiveThisMessageLabel')}
                      </span>
                    )}
                    {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-primary-400">
                          {t('CreatePushNotificationsPage.belongToSegmentLabel')}
                        </span>
                        {getValues('segmentsShouldInclude') && getValues('segmentsShouldInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                      <div className="flex flex-col gap-y-3">
                        <span className="font-['Montserrat'] text-xs font-medium leading-[14px] tracking-[0.02em] text-red-400">
                          {t('CreatePushNotificationsPage.dontBelongToSegmentLabel')}
                        </span>

                        {getValues('segmentsShouldNotInclude') && getValues('segmentsShouldNotInclude').length > 0 && (
                          <div className="flex flex-row flex-wrap gap-y-2">
                            {getValues('segmentsShouldNotInclude')?.map(
                              (singleItem: { value: string; label: string }, index: number) => (
                                <React.Fragment key={index}>
                                  <Chip label={singleItem.label} className={'mx-1'} />
                                </React.Fragment>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </section>
                  <section className="mt-4 space-y-5">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('CreatePushNotificationsPage.deliveryScheduleLabel')}
                    </h2>
                    <div>
                      {getValues('deliveryScheduleType') === 'Immediately' ? (
                        <RadioButton
                          label={t('CreatePushNotificationsPage.sendNowLabel')}
                          {...register('deliveryScheduleType')}
                          value="Immediately"
                          className="w-fit pointer-events-none"
                        />
                      ) : getValues('deliveryScheduleType') === 'Event Based' ? (
                        <div className="flex flex-row gap-x-5 items-center">
                          <div className="w-fit">
                            <RadioButton
                              label={t('CreatePushNotificationsPage.eventLabel')}
                              {...register('deliveryScheduleType')}
                              value="Event Based"
                              className="w-fit pointer-events-none"
                            />
                          </div>
                          <Input
                            className="!w-[15.25rem] pointer-events-none"
                            {...register('eventBasedTrigger')}
                            error={errors.eventBasedTrigger?.message as string}
                            value={getValues('eventBasedTrigger')}
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row gap-x-5 items-center">
                          <div className="w-fit">
                            <RadioButton
                              label={t('CreatePushNotificationsPage.scheduleDateAndTimeLabel')}
                              {...register('deliveryScheduleType')}
                              value="Specific Time"
                              className="w-fit pointer-events-none"
                            />
                          </div>
                          <Input
                            type="datetime-local"
                            className="!w-[15.25rem] pointer-events-none"
                            {...register('scheduleTime')}
                            error={errors.scheduleTime?.message as string}
                            value={getValues('scheduleTime')}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  <section className="mt-4 space-y-4">
                    <h2 className="mb-4 text-xs font-semibold text-yellow-500">
                      {t('CreatePushNotificationsPage.detailsLabel')}
                    </h2>
                    {getValues('link') && (
                      <Input
                        placeholder={t('CreatePushNotificationsPage.linkPlaceholder')}
                        label={t('CreatePushNotificationsPage.linkLabel')}
                        className="pointer-events-none"
                        {...register('link')}
                        error={errors.link?.message as string}
                        value={getValues('link')}
                      />
                    )}
                  </section>
                  <div className="flex flex-col items-center mt-6">
                    <PhoneDevice isPushNotification>
                      <>
                        {(watchSubject || watchNotificationText) && (
                          <div className="absolute w-[17rem] top-[165px] left-[2.8rem] min-h-[2rem] max-h-[25rem] bg-pushNotificationBG backdrop-blur-[4.39286px] rounded-2xl px-[14px] py-3 animate-slide">
                            <div className="flex flex-row items-center justify-between mb-2">
                              <div className="flex flex-row items-center gap-x-[6px]">
                                <div className="flex justify-center items-center rounded-full w-[14px] h-[14px] bg-primary-400">
                                  <div className="bg-primary-700 rounded-full w-[6px] h-[6px]"></div>
                                </div>
                                <span className="text-primary-400 font-normal text-[10px] leading-[14px]">
                                  {t('CreatePushNotificationsPage.cyberRavenLabel')}
                                </span>
                                <span className="text-gray-500 font-normal text-[10px] leading-[14px]">
                                  {t('CreatePushNotificationsPage.nowLabel')}
                                </span>
                              </div>
                              <ChevronIcon width="9" height="5" color="#A8A8A8" type="down" />
                            </div>
                            <div className="mb-1 font-normal text-[13px] leading-[18px] text-custom-lightGray">
                              {watchSubject}
                            </div>
                            <div className="text-custom-pushNotificationText text-[11px] leading-[18px] font-normal max-h-[20rem] overflow-y-auto [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-800">
                              {watchNotificationText}
                            </div>
                          </div>
                        )}
                      </>
                    </PhoneDevice>
                  </div>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-between min-w-[40rem]">
                      <Button variant="cancel" onClick={() => navigate(AppRoutes.delivery)}>
                        {t('CreatePushNotificationsPage.linkCancel')}
                      </Button>
                      <div className="flex flex-row gap-2">
                        <Button
                          type="button"
                          variant="previous"
                          className="pr-4 !w-[8.125rem]"
                          onClick={onBack}
                          iconLeft={<ChevronNewIcon type="left" color="#F5F7FC" width="20" height="20" />}
                        >
                          {t('CreatePushNotificationsPage.buttonPrevious')}
                        </Button>
                        <Button
                          className="px-4 !w-40"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={createPushMutation.isLoading || createPushMutation.isSuccess}
                        >
                          {t('CreatePushNotificationsPage.buttonSend')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </div>
  );
};

export default CreatePushNotification;
