import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';

interface SettingsCardParams {
  label: string;
  description: string;
  icon?: React.ReactElement;
  route?: string;
  disabled?: boolean;
}

const SettingsCard = (params: SettingsCardParams) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <section
        className={`relative flex-1 min-w-[48.5%] max-w-[48.5%] h-[8rem] px-6 pt-6 pb-8 rounded-[10px] bg-darkBlue cursor-pointer ${
          params.disabled && 'opacity-100 pointer-events-none'
        }`}
        onClick={() => navigate(params.route!)}
      >
        <div className="flex flex-row items-center gap-5 h-full">
          {params.icon && params.icon}
          <div className="flex flex-col w-[80%]">
            <h2 className="text-secondary-400 text-base mb-1">{t(params.label)}</h2>
            <p className="text-gray-400 text-sm">{t(params.description)}</p>
          </div>
          <ChevronNewIcon type="right" width="40" height="40" />
        </div>
        {params.disabled && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-modalOpacityBG backdrop-blur-[1px]">
            <span className="text-gray-300">Coming soon</span>
          </div>
        )}
      </section>
    </>
  );
};

export default SettingsCard;
