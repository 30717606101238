import { t } from 'i18next';

const WorkspaceDetails = () => {
  return (
    <div className="flex justify-between p-4">
      <p className="text-white">{t('WorkspaceDetailsPage.title')}</p>
    </div>
  );
};

export default WorkspaceDetails;
