import { ReactElement, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import CloudUploadIcon from '@/component-library/primitives/Icons/CloudUploadIcon/CloudUploadIcon';
import UploadIcon from '@/component-library/primitives/Icons/UploadIcon/UploadIcon';

import SingleFileUploadWithProgress from '../SingleFileUploadWithProgress/SingleFileUploadWithProgress';

interface FileUploadInputProps {
  name: string;
  control: any;
  setValue: any;
  value: File[] | undefined;
  placeholder?: string;
  multipleFiles?: boolean;
  importError?: string;
  parseError: string[];
  setParseError: (value: string[]) => void;
  fieldClassName?: string;
  exampleFileCSVLink?: ReactElement;
}

export const FileUploadInput = ({
  importError,
  parseError,
  setParseError,
  placeholder = 'Drag and drop here',
  multipleFiles = false,
  name,
  control,
  setValue,
  fieldClassName = '',
  value,
  exampleFileCSVLink,
}: FileUploadInputProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | undefined>(value);
  const [showProgress, setShowProgress] = useState(false);

  const removeFile = (fileToBeRemoved: File, event: React.MouseEvent) => {
    event.stopPropagation();
    const newFiles = files ? [...files] : [];
    newFiles.splice(newFiles.indexOf(fileToBeRemoved), 1);
    setFiles(newFiles);
    setValue(name, newFiles);
    setParseError([]);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur } }) => (
        <Dropzone
          multiple={multipleFiles}
          noClick
          accept={{ 'text/csv': ['.csv'] }}
          onDrop={(acceptedFiles) => {
            setValue(name, acceptedFiles as unknown as File[], {
              shouldValidate: true,
            });
            setFiles(acceptedFiles);
            setShowProgress(true);
            setParseError([]);
          }}
        >
          {({ getRootProps, getInputProps, open, isDragActive }) => (
            <>
              <div onClick={open}>
                <div
                  className={`border-[1.5px] border-transparent ${
                    importError || parseError.length > 0
                      ? '!border-errorRed-500'
                      : 'bg-importMembersField bg-import-field-size bg-import-field-position bg-no-repeat'
                  } flex items-center justify-center gap-2 relative bg-darkBlue rounded-md py-2 px-4 text-custom-aliceBlue font-light focus:outline-none focus:ring-blue-500 cursor-pointer ${
                    isDragActive && 'bg-gray-900'
                  } ${fieldClassName}`}
                  {...getRootProps()}
                >
                  <input
                    {...getInputProps({
                      id: 'spreadsheet',
                      onChange,
                      onBlur,
                    })}
                  />
                  <div>
                    {files && files.length > 0 ? (
                      files.map((file: File, index: number) => {
                        return (
                          <SingleFileUploadWithProgress
                            key={index}
                            singleFile={file}
                            removeSingleFile={removeFile}
                            showProgress={showProgress}
                            removeProgressBar={setShowProgress}
                          />
                        );
                      })
                    ) : (
                      <div className="flex flex-col w-full items-center gap-y-2.5">
                        <CloudUploadIcon />
                        <p className="text-sm font-normal tracking-[0.28px] text-gray-500 mt-2">{placeholder}</p>
                        <div className="flex flex-row gap-5 items-center">
                          <hr className="w-[7.875rem] border-gray-600" />
                          <span className="text-xs font-medium tracking-[0.24px] text-gray-600">
                            {t('ImportMembersPage.orLabel')}
                          </span>
                          <hr className="w-[7.875rem] border-gray-600" />
                        </div>
                        <div className="flex flex-row items-center gap-3">
                          <UploadIcon color="#7898FB" />
                          <span className="text-sm font-medium tracking-[0.28px] text-secondary-400">
                            {t('ImportMembersPage.browseFilesLabel')}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mt-1 justify-between">
                <div>
                  {importError && (
                    <p className="text-errorRed-500 text-xs font-medium flex flex-row items-center gap-2">
                      <CircleWarningIcon /> {importError}
                    </p>
                  )}
                  {parseError.length > 0 &&
                    parseError.map((err: string, index: number) => (
                      <p key={index} className="text-errorRed-500 text-sm font-medium flex flex-row items-center gap-2">
                        <CircleWarningIcon /> {err}
                      </p>
                    ))}
                </div>
                {exampleFileCSVLink && exampleFileCSVLink}
              </div>
            </>
          )}
        </Dropzone>
      )}
    />
  );
};
