import { useTranslation } from 'react-i18next';

interface PreviewConditionsSchemeProps {
  conditions: {
    nestedLogicalOperator: boolean | null;
    conditionGroup: string | null;
    nestedConditions: {
      attribute: { value: string; label: string } | undefined;
      attributeType: { value: string; label: string } | undefined;
      operator: { value: string; label: string } | undefined;
      value: string;
    }[];
  }[];
  mainLogicalOperator: boolean;
  borderLineColor?: string;
}

const PreviewConditionsScheme: React.FC<PreviewConditionsSchemeProps> = ({
  conditions,
  mainLogicalOperator,
  borderLineColor = 'border-l-[#1F2129]',
}) => {
  const { t } = useTranslation();
  const formatOperatorString = (value: string): string => {
    const formattedOperator = `${value !== 'contains' && value !== 'doesNotContain' ? 'is' : ''} ${value
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()} ${value === 'equal' || value === 'notEqual' ? 'to' : ''}`;

    return formattedOperator;
  };
  return (
    <div className="relative">
      {conditions.length > 1 && (
        <div className={`absolute h-full left-4 border-l border-l-custom-conditionGroupBorder`}></div>
      )}
      {conditions.map((condition, index) => {
        if (condition.conditionGroup === null) {
          return (
            <div key={index} className="relative mb-3 last:mb-0 flex flex-row items-center gap-3">
              {conditions.length > 0 && (index === 0 || index === conditions.length - 1) && (
                <div
                  className={`absolute left-4 h-[50%] border-l ${borderLineColor}  ${
                    index === 0 ? 'bottom-[50%]' : index === conditions.length - 1 ? 'top-[50%]' : ''
                  }`}
                ></div>
              )}
              <div className="flex items-center">
                <div className="bg-custom-tooltipSideMenuOption rounded-[3px] w-[34px] h-[23px] flex items-center justify-center relative z-50">
                  <p className="text-xs text-primary-400 font-semibold tracking-[0.24px]">
                    {mainLogicalOperator
                      ? t('CreateSegmentPage.orConditionLabel')
                      : t('CreateSegmentPage.andCondtionsOptionLabel')}
                  </p>
                </div>
              </div>
              <div className="bg-darkBlue rounded-lg py-3 pl-5 w-full">
                <div className="flex flex-row items-center gap-2">
                  <span className="text-sm text-white font-medium tracking-[0.28px]">
                    {condition.nestedConditions[0].attribute?.label}
                  </span>
                  <span className="text-gray-400 text-sm font-normal tracking-[0.28px]">
                    {formatOperatorString(
                      condition.nestedConditions[0].operator?.label
                        ? condition.nestedConditions[0].operator?.label
                        : '',
                    )}
                  </span>
                  <span className="text-sm text-white font-medium tracking-[0.28px]">
                    {condition.nestedConditions[0].value}
                  </span>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={index} className="relative mb-3 last:mb-0 flex flex-row items-center gap-3">
              {conditions.length > 0 && (index === 0 || index === conditions.length - 1) && (
                <div
                  className={`absolute left-4 h-[50%] border-l ${borderLineColor}  ${
                    index === 0 ? 'bottom-[50%]' : index === conditions.length - 1 ? 'top-[50%]' : ''
                  }`}
                ></div>
              )}
              <div className="flex items-center">
                <div className="bg-custom-tooltipSideMenuOption rounded-[3px] w-[34px] h-[23px] flex items-center justify-center relative z-50">
                  <p className="text-xs text-primary-400 font-semibold tracking-[0.24px]">
                    {mainLogicalOperator
                      ? t('CreateSegmentPage.orConditionLabel')
                      : t('CreateSegmentPage.andCondtionsOptionLabel')}
                  </p>
                </div>
              </div>
              <div className="bg-custom-conditionGroupBG w-full flex flex-row gap-3 rounded-lg p-3 border border-custom-conditionGroupBorder">
                <div className="flex items-center">
                  <div className="bg-custom-tooltipSideMenuOption rounded-[3px] w-[34px] h-[23px] flex items-center justify-center">
                    <p className="text-xs text-primary-400 font-semibold tracking-[0.24px]">
                      {condition.nestedLogicalOperator
                        ? t('CreateSegmentPage.orConditionLabel')
                        : t('CreateSegmentPage.andCondtionsOptionLabel')}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  {condition.nestedConditions.map((nested, nestedId) => {
                    return (
                      <div key={nestedId} className="mb-3 last:mb-0">
                        <div className="bg-darkBlue rounded-lg py-3 pl-5">
                          <div className="flex flex-row items-center gap-2">
                            <span className="text-sm text-white font-medium tracking-[0.28px]">
                              {nested.attribute?.label}
                            </span>
                            <span className="text-gray-400 text-sm font-normal tracking-[0.28px]">
                              {formatOperatorString(nested.operator?.label ? nested.operator?.label : '')}
                            </span>
                            <span className="text-sm text-white font-medium tracking-[0.28px]">{nested.value}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default PreviewConditionsScheme;
