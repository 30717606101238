import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetRolePermissions } from '@/api/users/useGetRolePermissions';
import { useGetWorkspacesByTenant } from '@/api/workspaces/useGetWorkspacesByTenant';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { setPermissions } from '@/app/slices/permissionsSlice';
import Loader from '@/component-library/primitives/Loader/Loader';
import {
  selectCurrentWorkspace,
  setLoading,
  setSelectedWorkspace,
  setWorkspaces,
} from '@/modules/Dashboard/Workspaces/workspaceSlice';

type DashboardRoutesWrapperProps = {
  children: React.ReactNode;
};

const DashboardRoutesWrapper = ({ children }: DashboardRoutesWrapperProps) => {
  const { data = [], isLoading, isError, isFetching, isFetched } = useGetWorkspacesByTenant();
  const { data: rolePermissions, isFetched: permissionsFetched } = useGetRolePermissions();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const storedWorkspace = sessionStorage.getItem('workspace');
  const parsedWorkspace = storedWorkspace ? JSON.parse(storedWorkspace) : null;

  // Use the parsedWorkspace as the initial value for currentWorkspace
  const currentWorkspace = useAppSelector(selectCurrentWorkspace) || parsedWorkspace;
  useEffect(() => {
    data.length && dispatch(setWorkspaces(data));
    dispatch(setLoading(isLoading));
    currentWorkspace && data.find((workspace) => workspace.id === currentWorkspace.id)
      ? dispatch(setSelectedWorkspace(currentWorkspace))
      : dispatch(setSelectedWorkspace(data[0]));
    rolePermissions && dispatch(setPermissions(rolePermissions));
  }, [currentWorkspace, data, dispatch, isLoading, rolePermissions]);

  if (isLoading || isFetching || !isFetched || !permissionsFetched) {
    return (
      <div className="h-screen w-screen flex items-center justify-center text-white">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-screen w-screen flex items-center justify-center text-white">
        {t('CommonErrors.fetchingWorkspacesFailure')}
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className="h-screen w-screen flex items-center justify-center text-white">
        {t('CommonErrors.emptyWorkspacesResponseFailure')}
      </div>
    );
  }

  return <>{children}</>;
};

export default DashboardRoutesWrapper;
