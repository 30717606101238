/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Control, useForm, UseFormRegister } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useEditDataDrivenSegment } from '@/api/segments/useEditDataDrivenSegment';
import { useEditManualSegment } from '@/api/segments/useEditManualSegment';
import { useGetSegmentDetails } from '@/api/segments/useGetSegmentDetails';
import { useManualSegment } from '@/api/segments/useManualSegment';
import { usePrecalculateSegment } from '@/api/segments/usePrecalculateSegment';
import { Button } from '@/component-library/primitives/Button/Button';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import CloseButtonIcon from '@/component-library/primitives/Icons/CloseButtonIcon/CloseButtonIcon';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import GearIcon from '@/component-library/primitives/Icons/GearIcon/GearIcon';
import UploadIcon from '@/component-library/primitives/Icons/UploadIcon/UploadIcon';
import UsersIcon from '@/component-library/primitives/Icons/UsersIcon/UsersIcon';
import Loader from '@/component-library/primitives/Loader/Loader';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import { FileUploadInput } from '@/component-library/widgets/FileUploadInput/FileUploadInput';
import { Input } from '@/component-library/widgets/Input/Input';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import { Member } from '@/models/AudienceByWorkspace';
import {
  CreateSegmentDetails,
  EditSegmentDetails,
  ManualSegmentParams,
  ManualSegmentRequest,
  SegmentDetails,
  SegmentRule,
} from '@/models/Segments';
// import useOutsideClick from '@/modules/shared/hooks/useOutsideClick';
import { readFileAsBase64 } from '@/utils/fileUtils';
import { AppRoutes } from '@/utils/routes/router';

import ConditionsScheme from '../CreateSegment/ConditionsScheme';
import PreviewConditionsScheme from '../CreateSegment/PreviewConditionsScheme';

import AudienceModal from './AudienceModal';
import UsageBar from './UsageBar';
import useParseRuleObject from './useParseRuleObject';

const SegmentOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { id: segmentId } = useParams();
  const { data, isFetched, isLoading } = useGetSegmentDetails(segmentId);
  // const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);
  const [isUsageIconHovered, setIsUsageIconHovered] = useState(false);
  const [fileErrors, setFileErrors] = useState<never | string[]>([]);
  // const dropdownRef = useRef(null);
  const [segment, setSegment] = useState<SegmentDetails>();
  const [isNameInEditMode, setIsNameInEditMode] = useState(false);
  const [showAudienceModal, setShowAudienceModal] = useState(false);
  const [showCsvImportField, setShowCsvImportField] = useState(false);
  const manualSegmentUploadMutation = useManualSegment();
  const editManualSegmentMutation = useEditManualSegment();
  const [isRuleChanged, setIsRuleChanged] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [newManualSegmentInfo, setNewManualSegmentInfo] = useState<
    | {
        invalidEmailsInCsv: number;
        totalEmailsInCsv: number;
        totalUsersFound: number;
        fileName: string;
        audience: Member[];
      }
    | undefined
  >();

  const [showConditionsScheme, setShowConditionsScheme] = useState(false);
  const [mainLogicalOperator, conditions] = useParseRuleObject(
    segment && segment.segment.segmentType === 'data-driven' ? segment?.segment.rule : '',
  );
  const [mainLogical, setMainLogical] = useState(mainLogicalOperator);
  const precalculateSegmentMutation = usePrecalculateSegment();
  const editDataDrivenSegmentMutation = useEditDataDrivenSegment();

  const handleAudienceModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowAudienceModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowAudienceModal(state);
        });
      });
    }
  };

  // const menuOptions = [
  //   { name: 'Dummy option 1', route: '#' },
  //   { name: 'Dummy option 2', route: '#' },
  // ];

  // const toggleDropdown = () => {
  //   setIsOptionsDropdownOpen(!isOptionsDropdownOpen);
  // };

  // useOutsideClick(dropdownRef, () => setIsOptionsDropdownOpen(false));

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.segments), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  useLayoutEffect(() => {
    if (isFetched) {
      setSegment(data);
      setMainLogical(mainLogicalOperator);
      setValue('name', data?.segment.name ?? 'Undefined');
    }
  }, [data, isFetched, mainLogicalOperator]);

  const schema = useMemo(() => {
    if (segment?.segment.segmentType === 'manual') {
      return yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(t('CreateSegmentPage.nameRequired'))
          .min(1, t('CreateSegmentPage.nameTooShort', { minLength: 1 }))
          .max(50, t('CreateSegmentPage.nameTooLong', { maxLength: 50 })),
      });
    } else {
      return yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(t('CreateSegmentPage.nameRequired'))
          .min(1, t('CreateSegmentPage.nameTooShort', { minLength: 1 }))
          .max(50, t('CreateSegmentPage.nameTooLong', { maxLength: 50 })),
        conditions: yup.array().of(
          yup.object().shape({
            nestedConditions: yup
              .array()
              .of(
                yup.object().shape({
                  attribute: yup.object().required(t('CommonErrors.attributeRequired')),
                  attributeType: yup.object().required(t('CommonErrors.attributeTypeRequired')),
                  operator: yup.object().required(t('CommonErrors.operatorRequired')),
                  value: yup.string().trim().required(t('CommonErrors.valueRequired')),
                }),
              )
              .min(1)
              .required(),
          }),
        ),
      });
    }
  }, [segment, t]);

  const methods = useForm<EditSegmentDetails>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const watchAll = watch();

  useEffect(() => {
    if (conditions) {
      setValue('conditions', conditions as any[]);
    }
  }, [conditions]);

  const onCancelConditionsEdit = () => {
    setShowConditionsScheme(false);
    setSegment(data);
    setIsRuleChanged(false);
    setValue('conditions', conditions as any[]);
    setMainLogical(mainLogicalOperator);
  };

  const onStartNameEdit = () => {
    setIsNameInEditMode(true);
    setIsNameChanged(true);
  };

  const onCancelNameEdit = () => {
    setIsNameInEditMode(false);
    setIsNameChanged(false);
    clearErrors();
    setValue('name', data?.segment.name ?? 'Undefined');
  };

  const prepareManualRequest = async (): Promise<ManualSegmentRequest> => {
    const csvFile = watchAll.importFile;
    const data = {
      name: watchAll.name ?? segment?.segment.name,
      description: segment?.segment.description ?? '',
      segmentId: segmentId,
    } as ManualSegmentRequest;
    if (csvFile && csvFile.length > 0) {
      const base64Data = await readFileAsBase64(csvFile[0]);
      data.fileName = csvFile[0].name;
      data.base64Data = base64Data;
    }
    return data;
  };

  const onFileUpload = async () => {
    const sendData = await prepareManualRequest();
    if (sendData) {
      manualSegmentUploadMutation.mutate({ data: sendData, endpoint: 'review-import' } as ManualSegmentParams, {
        onSuccess: (res: any) => {
          setNewManualSegmentInfo(res.data);
          setSegment((prevData: any) => {
            return {
              ...prevData,
              audience: res.data.audience,
            };
          });
          setIsRuleChanged(true);
        },
        onError: () => {
          setFileErrors(['failed to precalculate members']);
        },
      });
    } else {
      setFileErrors(['No file selected.']);
    }
  };

  const formatRuleObject = (): SegmentRule => {
    const allConditions = watchAll.conditions?.flatMap((condition) => {
      return condition.nestedConditions;
    });
    const attributeSet = new Set();
    const uniqueAttributes = allConditions?.filter((item) => {
      const duplicate = attributeSet.has(item.attribute?.label);
      attributeSet.add(item.attribute?.label);
      return !duplicate;
    });
    const topLevelConds = watchAll.conditions
      ?.filter((condition) => {
        return condition.nestedLogicalOperator === null;
      })
      .flatMap((condition) => {
        return condition.nestedConditions;
      });

    const groupedConditions = watchAll.conditions?.filter((condition) => {
      return condition.nestedLogicalOperator !== null;
    });

    const allAttributes = uniqueAttributes?.map((item) => {
      return {
        name: item.attribute?.label,
        type: item.attributeType?.label,
      };
    });

    const topLevelConditions = topLevelConds ? topLevelConds : [];
    const conditionGroups = groupedConditions ? groupedConditions : [];

    const ruleObject = {
      rule: {
        name: watchAll.name,
        attributes: allAttributes,
        decisions: [
          {
            conditions: {
              [mainLogical ? 'any' : 'all']: [
                ...topLevelConditions.map((item) => {
                  if (item.attribute?.label === 'email' || item.attribute?.label === 'name') {
                    return {
                      fact: item.attribute?.label,
                      operator: item.operator?.label,
                      value: item.value,
                    };
                  } else {
                    return {
                      fact: 'attribute',
                      operator: item.operator?.label,
                      value: item.value,
                      path: `$.value`,
                      params: {
                        key: item.attribute?.label,
                      },
                    };
                  }
                }),
                ...conditionGroups?.map((item) => {
                  return {
                    [item.nestedLogicalOperator ? 'any' : 'all']: item.nestedConditions.map((cond) => {
                      if (cond.attribute?.label === 'email' || cond.attribute?.label === 'name') {
                        return {
                          fact: cond.attribute?.label,
                          operator: cond.operator?.label,
                          value: cond.value,
                        };
                      } else {
                        return {
                          fact: 'attribute',
                          operator: cond.operator?.label,
                          value: cond.value,
                          path: `$.value`,
                          params: {
                            key: cond.attribute?.label,
                          },
                        };
                      }
                    }),
                  };
                }),
              ],
            },
            event: {
              type: 'PartOfSegment',
              params: {},
            },
          },
        ],
      },
    };
    return ruleObject;
  };

  const onRuleApply = async () => {
    const isValid = await trigger();
    if (isValid) {
      const formattedRuleObject = formatRuleObject();
      precalculateSegmentMutation.mutate(formattedRuleObject, {
        onSuccess: (res: any) => {
          if (segment) {
            setSegment({
              segment: segment.segment,
              audience: res.data,
              usage: segment.usage,
            });
            setIsRuleChanged(true);
          }
        },
        onError: () => {
          console.log('failed to precalculate members');
        },
      });
    } else {
      setIsRuleChanged(false);
    }
  };

  useEffect(() => {
    setIsRuleChanged(false);
    setNewManualSegmentInfo(undefined);
    setFileErrors([]);
  }, [watchAll.importFile]);

  const onSubmit = async (data: EditSegmentDetails) => {
    const isValid = await trigger();
    if (isValid && segment?.segment.segmentType === 'manual') {
      const sendData = await prepareManualRequest();
      if (sendData) {
        editManualSegmentMutation.mutate({ data: sendData, endpoint: 'import' } as ManualSegmentParams, {
          onSuccess: () => {
            setToastInfo({ message: t('SegmentOverview.toastSuccess'), toastType: 'success' });
            setOpen(true);
          },
          onError: () => {
            setToastInfo({ message: t('SegmentOverview.toastError'), toastType: 'error' });
            setOpen(true);
          },
        });
      } else {
        console.error('No file selected.');
      }
    } else if (isValid && segment?.segment.segmentType === 'data-driven') {
      const sendData = {
        id: segmentId,
        name: watchAll.name ?? segment?.segment.name,
        description: segment?.segment.description,
        workspaceId: segment?.segment.workspaceId,
        segmentType: segment?.segment.segmentType,
        rule: JSON.stringify(formatRuleObject()),
      };

      editDataDrivenSegmentMutation.mutate(sendData as EditSegmentDetails, {
        onSuccess: () => {
          setToastInfo({ message: t('SegmentOverview.toastSuccess'), toastType: 'success' });
          setOpen(true);
        },
        onError: () => {
          setToastInfo({ message: t('SegmentOverview.toastError'), toastType: 'error' });
          setOpen(true);
        },
      });
    } else {
      console.log('error');
    }
  };

  const manualSegmentCount = newManualSegmentInfo ? newManualSegmentInfo.totalUsersFound : 0;

  const initialCount = segment?.audience.length ? segment?.audience.length : 0;

  return (
    <>
      {isLoading ? (
        <div className="relative mb-[150px] flex items-center justify-center w-full h-full">
          <div className="absolute w-full h-full">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="relative mb-[150px]">
          <div className="flex flex-col items-center mt-4">
            <div className="w-[60.5rem]">
              <form className="space-y-6 min-w-[60.5rem] w-1/2">
                <div>
                  <div className="flex flex-row items-center gap-2 mb-6">
                    <span>
                      <GearIcon color="#9BAFD0" />
                    </span>
                    <span className="text-gray-400 text-xs font-light">
                      {segment?.segment.segmentType
                        ? segment?.segment.segmentType[0].toUpperCase() + segment?.segment.segmentType?.slice(1)
                        : 'Undefined type'}
                    </span>
                    <span className="text-gray-50 text-xs font-medium">|</span>
                    <span className="text-gray-400 text-xs font-light">
                      <Trans
                        i18nKey="SegmentOverview.createdLabel"
                        components={{
                          primary: <span />,
                        }}
                        values={{
                          date: new Date(
                            segment?.segment.createdAt ? segment?.segment.createdAt : '',
                          ).toLocaleDateString('en-us', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }),
                          time: new Date(
                            segment?.segment.createdAt ? segment?.segment.createdAt : '',
                          ).toLocaleTimeString('en-us', {
                            hour: '2-digit',
                            minute: '2-digit',
                          }),
                        }}
                      />
                    </span>
                  </div>
                  <div className="relative flex flex-row justify-between items-center mb-6">
                    <div>
                      <div className="flex flex-row items-center gap-x-2">
                        {!isNameInEditMode ? (
                          <div className="flex flex-row items-center gap-x-2">
                            <h1 className="text-white font-medium text-xl">{segment?.segment.name ?? 'Undefined'}</h1>
                            <button type="button" className="group" onClick={onStartNameEdit}>
                              <span className="group-hover:[&>*_path]:stroke-secondary-400">
                                <EditPencilIcon width="20" height="20" />
                              </span>
                            </button>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-x-2">
                            <Input
                              {...register('name')}
                              className={`!bg-transparent`}
                              withBorder={true}
                              error={errors.name?.message as string}
                            />
                            <button type="button" onClick={onCancelNameEdit} className="group">
                              <span className="group-hover:[&>*_path]:stroke-gray-100">
                                <CloseButtonIcon />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                      <span className="text-gray-300 text-sm">{segment?.segment.description}</span>
                    </div>
                    {/* <div className="relative">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={toggleDropdown}
                        ref={dropdownRef}
                        className="group"
                      >
                        <span className="flex flex-row gap-3 justify-center items-center">
                          <span>{t('SegmentOverview.buttonOptions')}</span>
                          <span className="group-hover:[&>*_path]:fill-gray-900 group-active:[&>*_path]:fill-white">
                            <ChevronIcon color="#1C222A" type={isOptionsDropdownOpen ? 'up' : 'down'} />
                          </span>
                        </span>
                      </Button>
                      {isOptionsDropdownOpen && (
                        <div className="absolute right-0 top-12 w-fit z-10 mt-2 p-2 pb-2 px-2 bg-custom-darkBlue rounded-lg border-[0.063rem] border-gray-800 whitespace-nowrap">
                          {menuOptions.map((option, index) => (
                            <div
                              key={index}
                              className="group flex flex-row items-center gap-2 px-2 py-3 cursor-pointer rounded hover:bg-activeSideMenuOption text-custom-aliceBlue hover:text-primary-400"
                              onClick={() => navigate(option.route)}
                            >
                              <span className="font-light text-sm tracking-[0.0175rem]">{option.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div> */}
                  </div>
                  <hr className="w-full border-custom-darkBlue" />
                  <section className="mt-6 mb-6">
                    <div className="flex flex-row items-center justify-between mb-1">
                      <h2 className="text-xs font-semibold text-yellow-500">
                        {t('SegmentOverview.peopleOnTheSegmentLabel')}
                      </h2>
                      <div className="flex flex-row items-center gap-1">
                        <span>
                          <UsersIcon color="#7898FB" />
                        </span>
                        <div className="min-w-[28px] h-7 bg-numberOrPeopleInSegmentBG rounded-[5px] flex items-center justify-center text-secondary-400 text-sm">
                          {newManualSegmentInfo ? manualSegmentCount : initialCount}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row mb-4">
                      <span className="text-sm text-gray-300 font-normal after:content-['\00a0']">
                        {t('SegmentOverview.consultDetailsLabel')}
                      </span>
                      <span
                        className="text-primary-400 text-sm font-normal hover:underline cursor-pointer"
                        onClick={() => handleAudienceModal(true)}
                      >
                        {t('SegmentOverview.hereLabel')}
                      </span>
                    </div>
                    {segment?.segment.segmentType === 'manual' ? (
                      <div
                        className={`relative flex flex-col items-center overflow-hidden bg-darkBlue rounded-lg p-4 pb-6 ${
                          showCsvImportField
                            ? 'max-h-[24.688rem] transition-[max-height] duration-500'
                            : 'max-h-[5rem] transition-[max-height] duration-500'
                        }`}
                      >
                        <div
                          className="w-full flex flex-row items-center justify-between pt-3 pb-7 pr-1 cursor-pointer"
                          onClick={() => setShowCsvImportField(!showCsvImportField)}
                        >
                          <div className="flex flex-row items-center gap-x-2">
                            <span>
                              <UploadIcon color="#7898FB" />
                            </span>
                            <span className="text-sm text-secondary-400 font-medium">
                              {t('SegmentOverview.addByCsvLabel')}
                            </span>
                          </div>
                          <span>
                            <ChevronIcon color="#DDE4F3" type={showCsvImportField ? 'up' : 'down'} />
                          </span>
                        </div>
                        <FileUploadInput
                          name={'importFile'}
                          control={control}
                          value={watchAll.importFile}
                          setValue={setValue}
                          importError={errors.importFile?.message}
                          parseError={fileErrors}
                          setParseError={setFileErrors}
                          placeholder={t('ImportMembersPage.dragAndDropPlaceholder')}
                          fieldClassName="h-[10rem] overflow-y-auto !w-[58rem]"
                        />
                        <div className="w-full flex justify-end mt-16">
                          <Button
                            type="button"
                            className="px-4 !w-[9.375rem] !h-8"
                            isLoading={manualSegmentUploadMutation.isLoading}
                            onClick={onFileUpload}
                          >
                            {t('SegmentOverview.buttonUpload')}
                          </Button>
                        </div>
                      </div>
                    ) : segment?.segment.segmentType === 'data-driven' ? (
                      <>
                        {showConditionsScheme ? (
                          <div className="rounded-lg p-3 bg-darkBlueWithOpacity">
                            <div className="relative z-[50]">
                              <ToggleSwitch
                                withLabels={{
                                  left: t('CreateSegmentPage.andCondtionsOptionLabel'),
                                  right: t('CreateSegmentPage.orConditionLabel'),
                                }}
                                value={mainLogical as boolean}
                                onChange={setMainLogical}
                              />
                            </div>
                            <ConditionsScheme
                              control={control as Control<CreateSegmentDetails | EditSegmentDetails, any>}
                              register={register as UseFormRegister<CreateSegmentDetails | EditSegmentDetails>}
                              errors={errors}
                            />
                            <div className="w-full flex flex-row gap-x-2 justify-end mt-10">
                              <Button
                                type="button"
                                variant="cancel"
                                className="px-4 !w-[9.375rem] !h-8"
                                onClick={onCancelConditionsEdit}
                              >
                                {t('SegmentOverview.buttonCancel')}
                              </Button>
                              <Button
                                type="button"
                                className="px-4 !w-[9.375rem] !h-8"
                                isLoading={precalculateSegmentMutation.isLoading}
                                onClick={onRuleApply}
                              >
                                {t('SegmentOverview.buttonApplyRules')}
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="rounded-lg p-3 bg-custom-conditionGroupBG">
                            <PreviewConditionsScheme
                              conditions={conditions as any[]}
                              mainLogicalOperator={mainLogicalOperator as boolean}
                              borderLineColor="border-l-[#22242D]"
                            />
                            <div className="w-full flex justify-end mt-8">
                              <Button
                                type="button"
                                variant="secondary"
                                className="px-4 !w-[9.375rem] !h-8"
                                onClick={() => setShowConditionsScheme(true)}
                              >
                                {t('SegmentOverview.buttonEdit')}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </section>
                  <section>
                    <div className="flex flex-row items-center justify-between mb-1">
                      <h2 className="text-xs font-semibold text-yellow-500">{t('SegmentOverview.usageLabel')}</h2>
                      <Tooltip
                        side="right"
                        content={
                          <div className="text-gray-100 text-xs font-light">{t('SegmentOverview.dummyTooltip')}</div>
                        }
                        open={isUsageIconHovered}
                        onOpenChange={(open: boolean) => {
                          if (open) {
                            setIsUsageIconHovered(true);
                          } else {
                            setIsUsageIconHovered(false);
                          }
                        }}
                        contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-44 text-center"
                        arrowClassName="fill-custom-darkBlue w-5 h-2"
                      >
                        <button type="button">
                          <CircleHelpIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-x-4 gap-y-4 items-center">
                      <UsageBar
                        numberOfUsage={segment?.usage.singleSends?.length ? segment?.usage.singleSends.length : 0}
                        route={AppRoutes.delivery}
                        label={t('SegmentOverview.singleSendsLabel')}
                        description={t('SegmentOverview.singleSendsDescription')}
                      />
                      <UsageBar
                        numberOfUsage={segment?.usage.dashboard?.length ? segment?.usage.dashboard.length : 0}
                        route={AppRoutes.dashboard}
                        label={t('SegmentOverview.dashboardLabel')}
                        description={t('SegmentOverview.dashboardDescription')}
                      />
                      <UsageBar
                        numberOfUsage={segment?.usage.campaigns?.length ? segment?.usage.campaigns.length : 0}
                        route={AppRoutes.campaigns}
                        label={t('SegmentOverview.campaignsLabel')}
                        description={t('SegmentOverview.campaignsDescription')}
                      />
                      <UsageBar
                        numberOfUsage={segment?.usage.apiTriggered?.length ? segment?.usage.apiTriggered.length : 0}
                        route={AppRoutes.apiCredentials}
                        label={t('SegmentOverview.apiTriggeredBroadcastsLabel')}
                        description={t('SegmentOverview.apiTriggeredBroadcastsDescription')}
                      />
                    </div>
                  </section>
                  <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
                    <div className="flex items-center justify-end min-w-[60.5rem]">
                      <div className="flex flex-row gap-8">
                        <Button variant="cancel" onClick={() => navigate(AppRoutes.segments)}>
                          {t('SegmentOverview.buttonBack')}
                        </Button>
                        <Button
                          type="button"
                          className="px-4 !w-52"
                          onClick={handleSubmit(onSubmit)}
                          isLoading={
                            editManualSegmentMutation.isLoading ||
                            editDataDrivenSegmentMutation.isLoading ||
                            editManualSegmentMutation.isSuccess ||
                            editDataDrivenSegmentMutation.isSuccess
                          }
                          disabled={!(isRuleChanged || isNameChanged)}
                        >
                          {t('SegmentOverview.buttonEditSegment')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
            <AudienceModal
              onCancel={() => handleAudienceModal(false)}
              isOpen={showAudienceModal}
              data={segment?.audience ?? []}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SegmentOverview;
