import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreateDomain } from '@/api/settings/useCreateDomain';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import { CreateDomainRequest } from '@/models/Domain';
import { AppRoutes } from '@/utils/routes/router';

import { selectCurrentWorkspace } from '../../Workspaces/workspaceSlice';

const CreateDomain = () => {
  const createDomainMutation = useCreateDomain();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { t } = useTranslation();
  const workspace = useAppSelector(selectCurrentWorkspace);

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.domains), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        domain: yup
          .string()
          .trim()
          .required(t('CreateDomainPage.domainRequired'))
          .matches(
            /^(?:(?=[a-zA-Z0-9-]{1,255}\.?)(?!-)[a-zA-Z0-9-\*]{1,63}(?<!-)(?:\.[a-zA-Z]{2,})+|localhost)$/,
            'Invalid domain format',
          ),
        mailFrom: yup.string().trim(),
      })
      .required();
  }, [t]);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<CreateDomainRequest>({
    resolver: yupResolver(schema),
  });

  const domainValue = watch('domain');

  const onSubmit = (data: CreateDomainRequest) => {
    const sendData = {
      domain: data.domain,
      mailFrom: data.mailFrom,
      workspaceId: workspace?.id ?? '',
    };
    createDomainMutation.mutate(sendData as CreateDomainRequest, {
      onSuccess: () => {
        setToastInfo({ message: t('CreateDomainPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: (e: any) => {
        if (e.response.status === 409) {
          setError('domain', {
            type: 'manual',
            message: t('CreateDomainPage.domainExists'),
          });
        } else {
          setToastInfo({ message: t('CreateDomainPage.toastError'), toastType: 'error' });
          setOpen(true);
        }
      },
    });
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5">
        <div className="flex flex-col items-center">
          <div className="mb-8 w-[40rem]">
            <h1 className="text-white font-medium text-xl mb-2">{t('CreateDomainPage.title')}</h1>
            <p className="text-gray-300 text-sm">{t('CreateDomainPage.subtitle')}</p>
          </div>
          <form className="min-w-[40rem] w-1/2" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <Input
                label={t('CreateDomainPage.domainLabel')}
                placeholder={t('CreateDomainPage.domainPlaceholder')}
                {...register('domain')}
                error={errors.domain?.message as string}
              />
              <div className="flex items-center">
                <Input
                  label={t('CreateDomainPage.mailFromLabel')}
                  placeholder={t('CreateDomainPage.mailFromPlaceholder')}
                  {...register('mailFrom')}
                  error={errors.mailFrom?.message as string}
                />
                {domainValue && <div className="ml-4 text-custom-aliceBlue pt-6">{'.' + domainValue}</div>}
              </div>
            </div>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[40rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.domains)}>
                  {t('CreateDomainPage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  isLoading={createDomainMutation.isLoading || createDomainMutation.isSuccess}
                >
                  {t('CreateDomainPage.buttonCreateDomain')}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default CreateDomain;
