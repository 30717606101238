const HtmlEditorIcon = () => {
  return (
    <svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 16V5.7619C41 3.13198 38.868 1 36.2381 1H5.7619C3.13198 1 1 3.13198 1 5.76191V36.2381C1 38.868 3.13198 41 5.76191 41H36.2381C38.868 41 41 38.868 41 36.2381V33.5"
        stroke="#F5F7FC"
        strokeOpacity="0.18"
        strokeWidth="1.31429"
      />
      <rect x="8" y="16" width="37" height="17" rx="2" stroke="white" strokeWidth="1.31429" />
      <circle cx="5.89828" cy="6.7142" r="1.63265" fill="#F5F7FC" fillOpacity="0.18" />
      <circle cx="10.7957" cy="6.7142" r="1.63265" fill="#F5F7FC" fillOpacity="0.18" />
      <path
        d="M18.4444 21.2964L14 24.2593L18.4444 27.2223"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9999 21.2964L40.4443 24.2593L35.9999 27.2223"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4818 19.0742L25.8892 29.4446"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HtmlEditorIcon;
