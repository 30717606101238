import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { ManualSegmentParams, ManualSegmentRequest } from '@/models/Segments';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function editManualSegment(
  data: ManualSegmentRequest,
  token: string | undefined,
  workspaceId: string,
  endpoint: string,
) {
  const response = await axios.put<ManualSegmentRequest>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/segments/${endpoint}`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useEditManualSegment() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<ManualSegmentRequest, Error, ManualSegmentParams>((params: ManualSegmentParams) =>
    editManualSegment(params.data, user?.signInUserSession.idToken.jwtToken, workspace?.id ?? '', params.endpoint),
  );
}
