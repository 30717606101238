import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetTeamMembers } from '@/api/settings/useGetTeamMembers';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { AppRoutes } from '@/utils/routes/router';

import { useTeamMembersTable } from './useTeamMembersTable';

const TeamMembers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetTeamMembers();
  const { columns, deleteConfirmationModal } = useTeamMembersTable();
  const [filterValue, setFilterValue] = useState('');

  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase()),
      ),
    );
  }, [data, filterValue]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField initialValue={filterValue} setSearchValue={setFilterValue} />
        </div>
        <div className="justify-end items-center">
          <Button onClick={() => navigate(AppRoutes.createTeamMember)} className="!w-[10.5rem]">
            {t('TeamMembersPage.buttonNewTeamMember')}
          </Button>
        </div>
      </div>
      {deleteConfirmationModal}
      {isLoading ? (
        <Loader />
      ) : filteredData && filteredData.length > 0 ? (
        <Table
          columns={columns}
          data={filteredData ?? []}
          className={{
            row: 'h-[3.300rem]',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard description={t('NoDataBoard.addYourFirstTeamMemberLabel')} />
      )}
    </div>
  );
};

export default TeamMembers;
