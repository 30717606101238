import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useUpdateWorkspace } from '@/api/workspaces/useUpdateWorkspace';
import { Button } from '@/component-library/primitives/Button/Button';
import { Textarea } from '@/component-library/primitives/Textarea/Textarea';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import OptionSelector from '@/component-library/widgets/OptionSelector/OptionSelector';
import { EditWorkspaceDetails } from '@/models/WorkspaceDetails';
import queryKeys from '@/modules/shared/query/queryKeys';
import { AppRoutes } from '@/utils/routes/router';

const EditWorkspace = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const updateWorkspaceMutation = useUpdateWorkspace();
  const queryClient = useQueryClient();

  const workspaceData = location.state ? location.state.workspace : null;

  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => {
        queryClient.invalidateQueries([queryKeys.workspaces]);
        navigate(AppRoutes.workspaces);
      }, 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, queryClient, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        name: yup.string().required(t('CommonErrors.nameRequired')),
      })
      .required();
  }, []);

  const [settings, setSettings] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditWorkspaceDetails>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (workspaceData) {
      setValue('name', workspaceData.name);
      setSettings(workspaceData.settings);
      setValue('description', workspaceData.description);
    }
  }, [workspaceData, setValue]);

  const onSubmit = (data: EditWorkspaceDetails) => {
    const updatedData = {
      id: id,
      name: data.name,
      settings: settings,
      // trackEmailOpens: trackEmailOpens,
      identifier: 'id, email',
      teamMembers: 1,
      description: data.description ?? '',
    };

    updateWorkspaceMutation.mutate(updatedData as EditWorkspaceDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('EditWorkspacePage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('EditWorkspacePage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const handleSettingsSelect = (option: string) => {
    setSettings(option);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5">
        <div className="flex flex-col items-center">
          <div className="mb-8 w-[40rem]">
            <h1 className="text-white font-medium text-xl mb-2">{`${workspaceData.name} ${t(
              'EditWorkspacePage.title',
            )}`}</h1>
          </div>
          <form className="min-w-[40rem] w-1/2" onSubmit={handleSubmit(onSubmit)}>
            <section className="space-y-4 rounded-lg text-custom-aliceBlue mb-7">
              <Input
                label={t('EditWorkspacePage.nameLabel')}
                data-cy="workspaceNameEdit"
                placeholder={t('EditWorkspacePage.namePlaceholder')}
                {...register('name')}
                error={errors.name?.message as string}
              />
              <Textarea
                {...register('description')}
                placeholder={`${t('EditWorkspacePage.descriptionPlaceholder')}...`}
                label={t('EditWorkspacePage.descriptionLabel')}
                optional={true}
              />
            </section>
            <h2 className="text-xs font-semibold text-yellow-500 mb-4">
              {t('EditWorkspacePage.howToDeliverMessagesLabel')}
            </h2>{' '}
            <div className="flex justify-between space-x-4 !mb-24">
              <OptionSelector
                title={t('CreateWorkspacePage.sendMessagesNormallyOption')}
                content={t('CreateWorkspacePage.sendMessagesNormallyDescription')}
                gradientOption="1"
                isSelected={settings === t('CreateWorkspacePage.sendMessagesNormallyOption')}
                onSelect={handleSettingsSelect}
              />
              <OptionSelector
                title={t('CreateWorkspacePage.testEmailDeliveryOption')}
                content={t('CreateWorkspacePage.testEmailDeliveryDescription')}
                gradientOption="2"
                isSelected={settings === t('CreateWorkspacePage.testEmailDeliveryOption')}
                onSelect={handleSettingsSelect}
              />
              <OptionSelector
                title={t('CreateWorkspacePage.neverSendMessagesOption')}
                content={t('CreateWorkspacePage.neverSendMessagesDescription')}
                gradientOption="3"
                isSelected={settings === t('CreateWorkspacePage.neverSendMessagesOption')}
                onSelect={handleSettingsSelect}
              />
            </div>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[35rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.workspaces)}>
                  {t('EditWorkspacePage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  data-cy="saveChangesButton"
                  isLoading={updateWorkspaceMutation.isLoading || updateWorkspaceMutation.isSuccess}
                >
                  {t('EditWorkspacePage.buttonUpdateWorkspace')}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default EditWorkspace;
