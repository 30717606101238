import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Workspace } from '@/models/WorkspacesByTenant';

export interface WorkspaceState {
  workspaces: Workspace[];
  loading: boolean;
  currentWorkspace: Workspace | null;
}

const initialState: WorkspaceState = {
  workspaces: [],
  loading: false,
  currentWorkspace: null,
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaces: (state, action) => {
      state.workspaces = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedWorkspace: (state, action) => {
      state.currentWorkspace = action.payload;
    },
  },
});

export const { setWorkspaces, setLoading, setSelectedWorkspace } = workspaceSlice.actions;
export const selectWorkspaces = (state: RootState) => state.workspaces.workspaces;
export const selectCurrentWorkspace = (state: RootState) => state.workspaces.currentWorkspace;
export const selectLoading = (state: RootState) => state.workspaces.loading;
export default workspaceSlice.reducer;
