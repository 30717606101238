import React, { ChangeEventHandler, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';
import InfoIcon from '@/component-library/primitives/Icons/InfoIcon/InfoIcon';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';

import HideIcon from '../../primitives/Icons/HideIcon/HideIcon';
import ShowIcon from '../../primitives/Icons/ShowIcon/ShowIcon';

export type InputSize = 'large' | 'small';
interface InputProps {
  value?: string;
  size?: InputSize;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: string;
  type?: 'text' | 'password' | 'datetime-local' | 'number';
  className?: string;
  hasInfoIcon?: boolean;
  tooltipContent?: React.ReactNode;
  maxLength?: number;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  currencyLeft?: string;
  currencyRight?: string;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  avatar?: React.ReactElement;
  withBorder?: boolean;
  readOnly?: boolean;
}

export const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      value,
      size = 'small',
      onChange,
      onBlur,
      onFocus,
      placeholder,
      error,
      type = 'text',
      className = '',
      hasInfoIcon,
      tooltipContent,
      optional = false,
      disabled = false,
      label,
      currencyLeft,
      currencyRight,
      iconLeft,
      iconRight,
      avatar,
      withBorder = false,
      readOnly = false,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isIconHovered, setIsIconHovered] = useState(false);
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
    const [isOverflown, setIsOverflown] = useState(false);

    const { t } = useTranslation();

    const togglePasswordVisibility = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    };

    const internalRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => internalRef.current as HTMLInputElement);

    useEffect(() => {
      const element = internalRef.current!;
      setIsOverflown(element.scrollWidth > element.offsetWidth);
    }, [internalRef.current?.value]);

    return (
      <div>
        <div className={`flex flex-col relative`}>
          {label && (
            <label
              className={`${error ? 'text-errorRed-500' : 'text-gray-100'} text-xs font-medium mb-2  ${
                disabled && 'opacity-50'
              }`}
            >
              {label}{' '}
              {optional && (
                <span className={`${error ? 'text-errorRed-500' : 'text-gray-400'} text-xs font-medium`}>
                  ({t('InputField.optionalLabel')})
                </span>
              )}
            </label>
          )}
          <Tooltip
            content={internalRef.current?.value}
            open={isTooltipDisplayed}
            onOpenChange={(open) => {
              if (open && isOverflown) {
                setIsTooltipDisplayed(true);
              } else {
                setIsTooltipDisplayed(false);
              }
            }}
            contentClassName="!bg-custom-darkBlue p-3 rounded-lg z-50 text-gray-50 text-xs"
            arrowClassName="fill-custom-darkBlue w-3 h-2"
          >
            <input
              type={showPassword ? 'text' : type}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              ref={internalRef}
              disabled={disabled}
              readOnly={readOnly}
              className={`relative border  text-ellipsis  ${disabled && 'opacity-50 hover:!border-gray-600'} ${
                size === 'small' ? 'h-12' : 'h-14'
              } ${
                error && '!border-errorRed-500'
              } autofill:shadow-[inset_0_0_0px_1000px_#272933] autofill:![-webkit-text-fill-color:#f5f7fc] autofill:![caret-color:white] bg-darkBlue rounded-lg py-3 ${
                currencyLeft ? 'pl-9' : 'pl-3'
              } ${iconLeft ? '!pl-11' : 'pl-3'} ${avatar ? '!pl-16' : 'pl-3'} ${currencyRight ? '!pr-9' : 'pr-3'} ${
                type === 'password' || hasInfoIcon || iconRight ? '!pr-11' : 'pr-3'
              } text-gray-100 text-sm font-normal focus:outline-none focus:border-secondary-400 hover:border-gray-800 placeholder:focus:text-transparent placeholder:text-gray-500 placeholder:tracking-[0.018rem] ${
                internalRef.current?.value && internalRef.current?.value.length > 0
                  ? 'border-gray-600 hover:border-gray-600'
                  : 'border-transparent'
              } ${withBorder && !error && `!border-gray-600 hover:!border-gray-800 focus:!border-secondary-400`} ${
                withBorder && error && '!border-errorRed-500'
              } ${
                readOnly &&
                '!border-none hover:!border-none focus:!border-none placeholder:!text-gray-500 placeholder:!focus:text-gray-500'
              } ${className}`}
              {...rest}
            />
          </Tooltip>
          {currencyLeft && (
            <div
              className={`absolute top-1/2 left-4 text-gray-500 text-base bg-darkBlue ${
                internalRef.current?.value && internalRef.current?.value.length > 0 && '!text-gray-100'
              } ${error && '!text-errorRed-500'}`}
            >
              {currencyLeft}
            </div>
          )}
          {iconLeft && (
            <div
              className={`absolute top-1/2 left-4 bg-darkBlue ${
                internalRef.current?.value && internalRef.current?.value.length > 0 && '!text-gray-100'
              } ${error && '!text-errorRed-500'}`}
            >
              <div
                className={`[&>*_path]:stroke-gray-500 ${
                  internalRef.current?.value && internalRef.current?.value.length > 0 && '[&>*_path]:!stroke-gray-100'
                } ${error && '[&>*_path]:!stroke-errorRed-500'}`}
              >
                {iconLeft}
              </div>
            </div>
          )}
          {avatar && <div className={`absolute top-[39%] left-4 bg-darkBlue`}>{avatar}</div>}
          {currencyRight && (
            <div
              className={`absolute top-1/2 right-4 text-gray-500 text-base bg-darkBlue ${
                internalRef.current?.value && internalRef.current?.value.length > 0 && '!text-gray-100'
              } ${error && '!text-errorRed-500'}`}
            >
              {currencyRight}
            </div>
          )}
          {iconRight && (
            <div
              className={`absolute top-1/2 right-4 bg-darkBlue ${
                internalRef.current?.value && internalRef.current?.value.length > 0 && '!text-gray-100'
              } ${error && '!text-errorRed-500'}`}
            >
              <div
                className={`[&>*_path]:stroke-gray-500 ${
                  internalRef.current?.value && internalRef.current?.value.length > 0 && '[&>*_path]:!stroke-gray-100'
                } ${error && '[&>*_path]:!stroke-errorRed-500'}`}
              >
                {iconRight}
              </div>
            </div>
          )}
          {type === 'password' && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-1/2 right-4 transform focus:outline-none h-6"
            >
              {showPassword ? (
                <ShowIcon color={error ? '#D81212' : '#7085A8'} onClick={togglePasswordVisibility} />
              ) : (
                <HideIcon color={error ? '#D81212' : '#7085A8'} onClick={togglePasswordVisibility} />
              )}
            </button>
          )}
          {hasInfoIcon && (
            <Tooltip
              content={tooltipContent}
              open={isIconHovered}
              onOpenChange={(open) => {
                if (open) {
                  setIsIconHovered(true);
                } else {
                  setIsIconHovered(false);
                }
              }}
              contentClassName="bg-custom-aliceBlue p-6 rounded-lg z-50 max-w-xs"
              arrowClassName="fill-custom-aliceBlue w-5 h-2"
            >
              <button
                type="button"
                className="absolute top-1/2 right-4 transform -translate-y-1/2 focus:outline-none h-6"
              >
                <InfoIcon />
              </button>
            </Tooltip>
          )}
        </div>
        {error && (
          <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
            {error.trim().length > 0 && <CircleWarningIcon />} {error}
          </p>
        )}
      </div>
    );
  },
);
