import { SVGProps } from 'react';

interface FilesIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const FilesIcon: React.FC<FilesIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M7.5 5.19647H7.78072C7.98455 5.19647 8.08654 5.19647 8.18244 5.2195C8.26747 5.23991 8.3491 5.27367 8.42367 5.31936C8.50774 5.37087 8.57992 5.44306 8.72396 5.5871L11.2762 8.13938C11.4204 8.2835 11.492 8.35517 11.5436 8.43927C11.5893 8.51383 11.6235 8.59533 11.6439 8.68036C11.6667 8.77529 11.6667 8.87608 11.6667 9.07577V15.1965M7.5 5.19647H3.83301C3.3663 5.19647 3.13318 5.19647 2.95492 5.2873C2.79811 5.36719 2.67072 5.49459 2.59083 5.65139C2.5 5.82965 2.5 6.06318 2.5 6.52989V16.3632C2.5 16.8299 2.5 17.063 2.59083 17.2412C2.67072 17.398 2.79811 17.5258 2.95492 17.6057C3.133 17.6965 3.36585 17.6965 3.83166 17.6965L10.3342 17.6965C10.8 17.6965 11.0337 17.6965 11.2118 17.6057C11.3686 17.5258 11.4956 17.3982 11.5755 17.2414C11.6663 17.0632 11.6667 16.8297 11.6667 16.363V15.1965M7.5 5.19647V8.02981C7.5 8.49652 7.5 8.72971 7.59083 8.90797C7.67072 9.06477 7.79811 9.19251 7.95492 9.2724C8.133 9.36314 8.36584 9.36314 8.83164 9.36314H11.6663M8.33333 5.19655V4.02989C8.33333 3.56318 8.33333 3.32965 8.42416 3.15139C8.50406 2.99459 8.63145 2.86719 8.78825 2.7873C8.96651 2.69647 9.19963 2.69647 9.66634 2.69647H13.3333M13.3333 2.69647H13.6141C13.8179 2.69647 13.9199 2.69647 14.0158 2.7195C14.1008 2.73991 14.1824 2.77367 14.257 2.81936C14.3411 2.87087 14.4133 2.94307 14.5573 3.0871L17.1096 5.63938C17.2537 5.78351 17.3254 5.85517 17.3769 5.93927C17.4226 6.01383 17.4568 6.09533 17.4772 6.18036C17.5 6.27529 17.5 6.37607 17.5 6.57577V13.863C17.5 14.3297 17.4997 14.5632 17.4089 14.7414C17.329 14.8982 17.2024 15.0258 17.0456 15.1057C16.8676 15.1965 16.6342 15.1965 16.1684 15.1965H11.6667M13.3333 2.69647V5.52981C13.3333 5.99652 13.3333 6.22971 13.4242 6.40797C13.5041 6.56477 13.6314 6.69251 13.7882 6.7724C13.9663 6.86314 14.1992 6.86314 14.665 6.86314H17.4997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilesIcon;
