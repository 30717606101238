import { SVGProps } from 'react';

interface CloudUploadIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CloudUploadIcon: React.FC<CloudUploadIconProps> = ({ color = '#3B4659', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path
        d="M15.4998 20.6667V12.9167M15.4998 12.9167L11.6248 15.5M15.4998 12.9167L19.3748 15.5M29.7082 19.375C29.7082 16.5215 27.395 14.2083 24.5415 14.2083C24.511 14.2083 24.4811 14.2086 24.4507 14.2091C23.8243 9.82704 20.0551 6.45834 15.4998 6.45834C11.8875 6.45834 8.7705 8.57673 7.32223 11.639C3.955 11.8594 1.2915 14.6602 1.2915 18.0832C1.2915 21.65 4.183 24.5418 7.74984 24.5418L24.5415 24.5417C27.395 24.5417 29.7082 22.2285 29.7082 19.375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudUploadIcon;
