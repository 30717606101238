import { useQuery } from 'react-query';
import axios from 'axios';

import { RolesResponse } from '@/models/RolePermission';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

import queryKeys from '../../modules/shared/query/queryKeys';

async function getRolePermissions(token: string | undefined) {
  const response = await axios.get<RolesResponse>(`${AwsConfigApiGateway.pooledTenantApiUrl}/roles`, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data as RolesResponse;
}

export function useGetRolePermissions() {
  const { user } = useAuth();

  return useQuery<RolesResponse>({
    queryKey: [queryKeys.rolePermissions],
    queryFn: () => getRolePermissions(user?.signInUserSession?.idToken.jwtToken),
    enabled: !!user?.signInUserSession?.idToken?.jwtToken,
    refetchOnWindowFocus: false,
  });
}
