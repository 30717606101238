import { SVGProps } from 'react';

interface ExpandIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ExpandIcon: React.FC<ExpandIconProps> = ({ color = '#9BAFD0' }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33332 12.8334H1.16666V8.66675M8.66666 1.16675H12.8333V5.33341"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandIcon;
