export enum NotificationServiceEnum {
  FIREBASE = 'firebase',
  APNS = 'apns',
}

export enum HttpMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum PermissionsEnum {
  DASHBOARD = 'dashboard',
  DETAILS = 'details',
  ROLES = 'roles',
  NOTIFICATIONS = 'notifications',
  SEGMENTS = 'segments',
  TEMPLATES = 'templates',
  WORKSPACES = 'workspaces',
  AUDIENCE = 'audience',
  CAMPAIGNS = 'campaigns',
  TEAM_MEMBERS = 'team-members',
  API_CREDENTIALS = 'api-credentials',
}

export enum UserRoles {
  TENANT_ADMIN = 'TenantAdmin',
  TENANT_USER = 'TenantUser',
  TENANT_MANAGER = 'TenantManager',
  TENANT_AUTHOR = 'TenantAuthor',
}

export enum ErrorMessage {
  EMAIL_EXISTS = 'EmailExists',
  ID_EXISTS = 'IdExists',
  NO_NEW_MEMBERS = 'NoNewMembers',
}
export {};
