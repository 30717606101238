import { useTranslation } from 'react-i18next';

import { useGetAccountMetrics } from '@/api/settings/useGetAccountMetrics';
import BellRingIcon from '@/component-library/primitives/Icons/BellRingIcon/BellRingIcon';
import CloudIcon from '@/component-library/primitives/Icons/CloudIcon/CloudIcon';
import CompanyAccountIcon from '@/component-library/primitives/Icons/CompanyAccountIcon/CompanyAccountIcon';
import CreditCardIcon from '@/component-library/primitives/Icons/CreditCardIcon/CreditCardIcon';
import GlobeIcon from '@/component-library/primitives/Icons/GlobeIcon/GlobeIcon';
import LockIcon from '@/component-library/primitives/Icons/LockIcon/LockIcon';
import ShieldIcon from '@/component-library/primitives/Icons/ShieldIcon/ShieldIcon';
import TeamMembersIcon from '@/component-library/primitives/Icons/TeamMembersIcon/TeamMembersIcon';
import UserCircleIcon from '@/component-library/primitives/Icons/UserCircleIcon/UserCircleIcon';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';
import { AppRoutes } from '@/utils/routes/router';

import SettingsCard from './SettingsCard';

const dummyAccountMetrics = {
  currentMemberCount: 366,
  memberCountLimit: 5000,
  currentObjects: 73,
  objectCountLimit: 500,
  emailsSentSinceSubscriptionStart: 71864,
  emailLimit: 1000000,
  subscriptionStartDate: new Date(),
};

const Settings = () => {
  const { t } = useTranslation();
  const { data } = useGetAccountMetrics();
  const accountMetricsData = data ? data : dummyAccountMetrics;
  const isActionAllowed = usePermissionChecker(PermissionsEnum.TEAM_MEMBERS, HttpMethodsEnum.GET);

  return (
    <>
      <div className="flex flex-col items-center mt-5">
        <div>
          <div className="position: relative">
            <h1 className="text-white font-medium text-xl mb-2">{t('SettingsPage.title')}</h1>
            <p className="text-gray-300 text-sm mb-8">{t('SettingsPage.subtitle')}</p>
          </div>
          <div className="min-w-[45rem] max-w-[45rem] w-1/2 mx-auto space-y-8">
            <section className="px-6 pt-6 pb-8 shadow rounded-[10px]  bg-darkBlue text-custom-aliceBlue">
              <h2 className="text-xs font-semibold text-yellow-500 uppercase mb-6">
                {t('SettingsPage.accountSummaryLabel')}
              </h2>
              <div className="space-y-6">
                <div className="flex flex-row gap-8">
                  <div className="flex flex-row items-center gap-4">
                    <div className="w-fit px-2 h-7 bg-numberOrPeopleInSegmentBG rounded-[5px] flex items-center justify-center text-secondary-400 text-sm">
                      {`${accountMetricsData.currentMemberCount} / ${accountMetricsData.memberCountLimit}`}
                    </div>
                    <div className="text-gray-400 text-sm">{t('SettingsPage.currentPeopleCountLabel')}</div>
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <div className="w-fit px-2 h-7 bg-numberOrPeopleInSegmentBG rounded-[5px] flex items-center justify-center text-secondary-400 text-sm">
                      {`${accountMetricsData.currentObjects} / ${accountMetricsData.objectCountLimit}`}
                    </div>
                    <div className="text-gray-400 text-sm">{t('SettingsPage.currentObjectsCountLabel')}</div>
                  </div>
                </div>
                <div className="flex flex-row gap-8">
                  <div className="flex flex-row items-center gap-4">
                    <div className="w-fit px-2 h-7 bg-numberOrPeopleInSegmentBG rounded-[5px] flex items-center justify-center text-secondary-400 text-sm">
                      {`${accountMetricsData.emailsSentSinceSubscriptionStart} / ${accountMetricsData.emailLimit}`}
                    </div>
                    <div className="flex flex-row items-center gap-2 text-gray-400 text-sm">
                      {t('SettingsPage.emailsSentSinceLabel', {
                        date: accountMetricsData.subscriptionStartDate.toLocaleDateString('en-us', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        }),
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="flex flex-row flex-wrap items-center gap-5">
              {isActionAllowed && (
                <SettingsCard
                  label={'SettingsPage.teamMembersOptionLabel'}
                  description={'SettingsPage.teamMembersOptionDescription'}
                  route={AppRoutes.teamMembers}
                  icon={<TeamMembersIcon />}
                />
              )}
              <SettingsCard
                label={'SettingsPage.accountOptionLabel'}
                description={'SettingsPage.accountOptionDescription'}
                route={AppRoutes.account}
                icon={<CompanyAccountIcon />}
              />
              <SettingsCard
                label={'SettingsPage.pushNotificationsOptionLabel'}
                description={'SettingsPage.pushNotificationsOptionDescription'}
                route={AppRoutes.pushNotifications}
                icon={<BellRingIcon color="#7898FB" width="35" height="35" />}
              />
              <SettingsCard
                label={'SettingsPage.personalAccountSettingsOptionLabel'}
                description={'SettingsPage.personalAccountSettingsOptionDescription'}
                route={AppRoutes.personalAccount}
                icon={<UserCircleIcon />}
              />
              <SettingsCard
                label={'SettingsPage.domainsOptionLabel'}
                description={'SettingsPage.domainsOptionDescription'}
                route={AppRoutes.domains}
                icon={<GlobeIcon />}
              />
              <SettingsCard
                label={'SettingsPage.apiCredentialsOptionLabel'}
                description={'SettingsPage.apiCredentialsOptionDescription'}
                route={AppRoutes.apiCredentials}
                icon={<CloudIcon />}
                disabled={true}
              />
              <SettingsCard
                label={'SettingsPage.securityOptionLabel'}
                description={'SettingsPage.securityOptionDescription'}
                icon={<ShieldIcon />}
                disabled={true}
              />
              <SettingsCard
                label={'SettingsPage.plansBillingOptionLabel'}
                description={'SettingsPage.plansBillingOptionDescription'}
                icon={<CreditCardIcon />}
                disabled={true}
              />
              <SettingsCard
                label={'SettingsPage.privacyDataOptionLabel'}
                description={'SettingsPage.privacyDataOptionDescription'}
                icon={<LockIcon />}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
