/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import React from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { createColumnHelper } from '@tanstack/react-table';

import { useDisableTeamMember } from '@/api/workspaces/useDisableTeamMember';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import StopIcon from '@/component-library/primitives/Icons/StopIcon/StopIcon';
import Modal from '@/component-library/primitives/Modal/Modal';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { TeamMember } from '@/models/TeamMembers';
import queryKeys from '@/modules/shared/query/queryKeys';

export const useTeamMembersTable = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<TeamMember>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userEmailForDisable, setUserEmailForDisable] = useState<string | null>(null);
  const disableUserMutation = useDisableTeamMember();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  const handleDeleteModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowDeleteModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowDeleteModal(state);
        });
      });
    }
  };

  const handleOnEdit = () => {
    console.log('handleOnEdit');
  };

  const handleOnDisable = (teamMemberEmail: string | null) => {
    setUserEmailForDisable(teamMemberEmail);
    handleDeleteModal(true);
  };

  const handleConfirmDisable = () => {
    if (userEmailForDisable) {
      disableUserMutation.mutate(userEmailForDisable, {
        onSuccess: () => {
          setTimeout(() => {
            queryClient.setQueryData<TeamMember[]>(queryKeys.teamMembers, (prevData) => {
              if (prevData) {
                return prevData.map((member) =>
                  member.email === userEmailForDisable ? { ...member, enabled: false } : member,
                );
              }
              return [];
            });
            setToastInfo({ message: t('TeamMembersPage.toastDisableSuccess'), toastType: 'success' });
            setOpen(true);
          }, 1000);
        },
        onError: () => {
          console.log('Error disabling member');
        },
        onSettled: () => {
          setUserEmailForDisable(null);
        },
      });
    }
    handleDeleteModal(false);
  };

  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('email', {
      header: () => <div>{t('TeamMembersPage.emailColumn')}</div>,
      cell: (info) => <span className="block truncate max-w-[13rem]">{info.renderValue()}</span>,
    }),
    columnHelper.accessor('userRole', {
      header: () => <div>{t('TeamMembersPage.roleColumn')}</div>,
      cell: (info) => <span className="text-yellow-200">{info.renderValue()}</span>,
    }),
    columnHelper.accessor('enabled', {
      header: () => <div>{t('TeamMembersPage.enabledColumn')}</div>,
      cell: (info) => <span className="text-yellow-200">{info.row.original.enabled.toString()}</span>,
    }),
    columnHelper.accessor('created', {
      header: () => <div>{t('TeamMembersPage.createdAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('modified', {
      header: () => <div>{t('TeamMembersPage.modifiedAtColumn')}</div>,
      cell: (info) => (
        <div className="flex flex-col">
          <span>
            {new Date(info.renderValue() as string).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span className="text-sm">
            {new Date(info.renderValue() as string).toLocaleTimeString('en-us', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <div>{t('TeamMembersPage.statusColumn')}</div>,
      cell: (info) => <div>{info.renderValue()}</div>,
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.email;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5">...</span>
              </div>
            </div>
            {openDropdownId === rowId && (
              <div className="absolute right-0 z-10 p-2 pt-2 pb-2 px-2 shadow-md w-40 rounded-lg bg-custom-darkBlue border-[0.063rem] border-gray-800">
                <div
                  key={'0'}
                  className="group/item flex items-center gap-2 px-2 py-3 mb-1 cursor-pointer rounded-lg hover:bg-activeSideMenuOption hover:text-primary-400 text-gray-200"
                  onClick={() => handleOnEdit()}
                >
                  <span className="group-hover/item:[&>*_path]:stroke-primary-400">
                    <EditPencilIcon />
                  </span>
                  <span className="font-normal">{t('TeamMembersPage.linkEdit')}</span>
                </div>
                {info.row.original.enabled === true && (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="147" height="2" viewBox="0 0 147 1" fill="none">
                      <path d="M0 1H145.878H147" stroke="#313B4B" />
                    </svg>
                    <div
                      key={'1'}
                      className="group/item flex items-center gap-2 px-2 py-3 mt-1 cursor-pointer rounded-lg hover:bg-deleteButtonHoverBG text-red-400"
                      onClick={() => handleOnDisable(rowId)}
                    >
                      <span>
                        <StopIcon color="#ff9296" />
                      </span>
                      <span className="font-normal">{t('TeamMembersPage.linkDisable')}</span>
                    </div>
                  </>
                )}
              </div>
            )}
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
          </div>
        );
      },
    }),
  ];

  const deleteConfirmationModal = (
    <Modal
      isOpen={showDeleteModal}
      title={t('TeamMembersPage.modalTitle')}
      body={t('TeamMembersPage.modalBody')}
      cancelBtnTxt={t('TeamMembersPage.modalCancelBtn')}
      confirmBtnTxt={t('TeamMembersPage.modalConfirmBtn')}
      onCancel={() => handleDeleteModal(false)}
      onConfirm={handleConfirmDisable}
    />
  );

  return { columns, deleteConfirmationModal };
};
