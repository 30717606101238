import Select, { StylesConfig } from 'react-select';

export type SelectCustomOption = { value: string; label: string; id: string };

const customSelectStyles: StylesConfig<SelectCustomOption, false> = {
  control: (baseStyles) => ({
    ...baseStyles,
    borderStyle: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      outline: 'none',
    },
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: '#cdd7ed',
    fontSize: '16px',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '0 0.5rem',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: '#272933',
    borderRadius: '8px',
    position: 'absolute',
    right: 0,
    border: '1px solid #313b4b',
    width: '150px',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: '#272933',
    padding: '8px',
    borderRadius: '8px',
  }),
  option: (baseStyles, { isFocused, isSelected }) => ({
    ...baseStyles,
    backgroundColor: isSelected ? 'rgba(130, 230, 184, 0.10)' : 'transparent',
    color: isSelected ? '#82E6B8' : '#fafafa',
    '&:hover': {
      backgroundColor: isFocused ? (isSelected ? 'rgba(130, 230, 184, 0.10)' : 'rgba(49, 59, 75, 0.50)') : '#272933',
    },
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '0.875rem',
    borderRadius: '0.25rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: '2px',
  }),
};

const SelectCustom = ({
  options,
  filter,
  setFilter,
}: {
  options: SelectCustomOption[];
  filter: SelectCustomOption | null;
  setFilter: (_: SelectCustomOption | null) => void;
}) => {
  return (
    <Select
      className="mb-4 w-fit"
      options={options}
      styles={customSelectStyles}
      value={filter}
      onChange={(selectedValue: SelectCustomOption | null) => {
        setFilter(selectedValue);
      }}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default SelectCustom;
