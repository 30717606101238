import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateCampaign } from '@/api/campaigns/useCreateCampaign';
import { useAppSelector } from '@/app/hooks';
import { Button } from '@/component-library/primitives/Button/Button';
import Chip from '@/component-library/primitives/Chip/Chip';
import { CampaignDetails, CreateCampaignDetails } from '@/models/Campaigns';
import { Audience } from '@/models/NewEmail';
import { Segment } from '@/models/Segments';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AppRoutes } from '@/utils/routes/router';

type CreateCampaignLastStepProps = {
  selectedTriggerTitle: any;
  allSubscribedPeopleIsActive: boolean;
  goalIsActive: boolean;
  entersSegmentIsActive: boolean;
  selectedTimeOptionText: string;
  selectedActionOptionText: string;
  exitCondition: () => any;
  segmentData: Segment[] | undefined;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setToastInfo: React.Dispatch<
    React.SetStateAction<{
      message: string;
      toastType: string;
    }>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateCampaignLastStep = ({
  selectedTriggerTitle,
  allSubscribedPeopleIsActive,
  goalIsActive,
  entersSegmentIsActive,
  selectedTimeOptionText,
  selectedActionOptionText,
  exitCondition,
  segmentData,
  setActiveStep,
  setToastInfo,
  setOpen,
}: CreateCampaignLastStepProps) => {
  const methods = useFormContext<CampaignDetails>();
  const createCampaignMutation = useCreateCampaign();
  const { getValues, handleSubmit } = methods;

  const name = getValues('name');
  const description = getValues('description');
  const eventName = getValues('eventName');
  const shouldReceiveSegments = getValues('shouldReceiveSegments');
  const conversionDuration = getValues('conversionDuration');

  const navigate = useNavigate();

  const workspace = useAppSelector(selectCurrentWorkspace);

  const { t } = useTranslation();

  const formatSegmentObject = (segmentData: Segment[] | undefined, selectedOptions: Audience[] | undefined) => {
    const arrayData = [];

    if (segmentData && selectedOptions) {
      for (let i = 0; i < segmentData.length; i++) {
        for (let j = 0; j < selectedOptions.length; j++) {
          if (segmentData[i].id === selectedOptions[j].value) {
            arrayData.push(segmentData[i]);
          }
        }
      }
    }
    return arrayData.map((item) => {
      return {
        id: item.id,
        name: item.name,
        PK: item.PK,
        SK: item.SK,
      };
    });
  };

  const onSubmit = (data: CampaignDetails) => {
    const sendData = {
      name: data.name.trim(),
      description: data.description.trim(),
      workspaceId: workspace?.id,
      trigger: selectedTriggerTitle,
      eventName: data.eventName.trim(),
      shouldReceiveSegments: formatSegmentObject(segmentData, data.shouldReceiveSegments),
      subscriptionPreference: allSubscribedPeopleIsActive
        ? t('CreateCampaignPage.allSubscribedPeopleOption')
        : t('CreateCampaignPage.allSubscribedUnsubscribedPeopleOption'),
      ...(goalIsActive && {
        segmentAction: entersSegmentIsActive
          ? t('CreateCampaignPage.entersSegmentOption')
          : t('CreateCampaignPage.leavesSegmentOption'),
        conversionDuration: data.conversionDuration,
        conversionTimeOption: selectedTimeOptionText,
        conversionActionOption: selectedActionOptionText,
      }),
      exitCondition: exitCondition(),
      matchCondition: currentPeopleAndFutureAdditionsMatchIsActive
        ? t('CreateCampaignPage.currentPeopleAndFutureAdditionsOptionLabel')
        : t('CreateCampaignPage.futureAdditionsOnlyOptionLabel'),
    };

    createCampaignMutation.mutate(sendData as CreateCampaignDetails, {
      onSuccess: () => {
        setToastInfo({ message: t('CreateCampaignPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: () => {
        setToastInfo({ message: t('CreateCampaignPage.toastError'), toastType: 'error' });
        setOpen(true);
      },
    });
  };

  const [currentPeopleAndFutureAdditionsMatchIsActive, setCurrentPeopleAndFutureAdditionsMatchIsActive] =
    useState(true);
  const [futureAdditionsOnlyMatchIsActive, setFutureAdditionsOnlyMatchIsActive] = useState(false);

  return (
    <div className="space-y-6 min-w-[50rem] max-w-[50rem] w-1/2 mx-auto">
      <p className="text-custom-aliceBlue w-[85%]">{t('CreateCampaignPage.reviewCampaignDescription')}:</p>
      <section className="px-6 pt-6 pb-6 space-y-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
        <Chip label={`1. ${t('CreateCampaignPage.infoStep')}`} variant="dark" />
        <div className="flex flex-row items-center">
          <div className="mr-6">{t('CreateCampaignPage.nameLabel')}:</div>
          <Chip label={name} />
        </div>
        <div className="flex flex-row">
          <div className="mr-6 pt-2">{t('CreateCampaignPage.descriptionLabel')}:</div>
          <Chip label={description} />
        </div>
        <div className="flex justify-end mt-2">
          <Button variant="transparent" onClick={() => setActiveStep(0)}>
            {t('CreateCampaignPage.linkEdit')}
          </Button>
        </div>
      </section>
      <section className="px-6 pt-6 pb-6 space-y-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
        <Chip label={`2. ${t('CreateCampaignPage.triggersStep')}`} variant="dark" />
        <div>
          <Trans
            i18nKey="CreateCampaignPage.whenCampaignTriggeredLabel"
            components={{
              primary: <span className="font-semibold" />,
            }}
            values={{ triggerTitle: selectedTriggerTitle, triggerName: eventName }}
          />
          <p className="inline-block [&>*:last-child]:after:content-['']">
            {shouldReceiveSegments?.map((singleItem: { value: string; label: string }, index: number) => (
              <span key={index} className="font-semibold after:content-[',\00a0']">
                {singleItem.label}
              </span>
            ))}
          </p>
        </div>
        <div className="flex justify-end mt-2">
          <Button variant="transparent" onClick={() => setActiveStep(1)}>
            {t('CreateCampaignPage.linkEdit')}
          </Button>
        </div>
      </section>
      <section className="px-6 pt-6 pb-6 space-y-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
        <Chip label={`3. ${t('CreateCampaignPage.settingsStep')}`} variant="dark" />
        <p>
          <Trans
            i18nKey="CreateCampaignPage.messagesWillBeSentLabel"
            components={{
              primary: <span className="font-semibold" />,
            }}
            values={{
              subscriptionPreference: allSubscribedPeopleIsActive
                ? t('CreateCampaignPage.allSubscribedPeopleOption')
                : t('CreateCampaignPage.allSubscribedUnsubscribedPeopleOption'),
            }}
          />
        </p>
        <div className="flex justify-end mt-2">
          <Button variant="transparent" onClick={() => setActiveStep(2)}>
            {t('CreateCampaignPage.linkEdit')}
          </Button>
        </div>
      </section>
      <section className="px-6 pt-6 pb-6 space-y-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
        <Chip label={`4. ${t('CreateCampaignPage.goalAndExitStep')}`} variant="dark" />
        {goalIsActive && (
          <>
            <p>{t('CreateCampaignPage.customerMeetsGoalLabel')}:</p>
            <p>
              <Trans
                i18nKey="CreateCampaignPage.goalLabel"
                components={{
                  primary: <span className="font-semibold mt-4" />,
                }}
                values={{
                  segmentAction: entersSegmentIsActive
                    ? t('CreateCampaignPage.entersSegmentOption')
                    : t('CreateCampaignPage.leavesSegmentOption'),
                  duration: conversionDuration,
                  timeOption: selectedTimeOptionText,
                  actionOption: selectedActionOptionText,
                }}
              />
            </p>
          </>
        )}
        <p>{t('CreateCampaignPage.peopleExitCampaignWhenLabel')}:</p>
        <p>
          <Trans
            i18nKey="CreateCampaignPage.exitLabel"
            components={{
              primary: <span className="font-semibold" />,
            }}
            values={{
              exitCondition: exitCondition(),
            }}
          />
        </p>
        <div className="flex justify-end mt-2">
          <Button variant="transparent" onClick={() => setActiveStep(3)}>
            {t('CreateCampaignPage.linkEdit')}
          </Button>
        </div>
      </section>
      <div className="space-y-4">
        <div>
          <h1 className="text-custom-aliceBlue font-semibold text-xl mb-4">
            {t('CreateCampaignPage.whoShouldMatchLabel')}
          </h1>
          <p className="text-custom-aliceBlue mt-4 mb-8 w-[85%]">{t('CreateCampaignPage.whoShouldMatchDescription')}</p>
        </div>
        <div className="flex flex-row gap-10">
          <section
            className={`w-[50%] px-6 pt-4 pb-4 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue cursor-pointer border border-transparent hover:!border-primary-400 hover:bg-settingsCard ${
              currentPeopleAndFutureAdditionsMatchIsActive
                ? ' !border-primary-400 shadow-[0px_0px_13px_0px_#82E6B8]'
                : ''
            }`}
            onClick={() => {
              setCurrentPeopleAndFutureAdditionsMatchIsActive(true);
              setFutureAdditionsOnlyMatchIsActive(false);
            }}
          >
            <h2 className="text-base font-semibold w-[50%]">
              {t('CreateCampaignPage.currentPeopleAndFutureAdditionsOptionLabel')}
            </h2>
          </section>
          <section
            className={`w-[50%] px-6 pt-4 pb-4 space-y-4 shadow rounded-lg bg-custom-gray text-custom-aliceBlue cursor-pointer border border-transparent hover:!border-primary-400 hover:bg-settingsCard ${
              futureAdditionsOnlyMatchIsActive ? ' !border-primary-400 shadow-[0px_0px_13px_0px_#82E6B8]' : ''
            }`}
            onClick={() => {
              setCurrentPeopleAndFutureAdditionsMatchIsActive(false);
              setFutureAdditionsOnlyMatchIsActive(true);
            }}
          >
            <h2 className="text-base font-semibold w-[40%]">
              {t('CreateCampaignPage.futureAdditionsOnlyOptionLabel')}
            </h2>
            <p className="text-gray-200">{t('CreateCampaignPage.futureAdditionsOnlyOptionDescription')}</p>
          </section>
        </div>
      </div>

      <div className="flex justify-end">
        <Button variant="cancel" onClick={() => navigate(AppRoutes.campaigns)}>
          {t('CreateCampaignPage.linkCancel')}
        </Button>
        <Button
          className="px-4 !w-52 ml-8"
          isLoading={createCampaignMutation.isLoading || createCampaignMutation.isSuccess}
          onClick={handleSubmit(onSubmit)}
        >
          {t('CreateCampaignPage.buttonAddCampaign')}
        </Button>
      </div>
    </div>
  );
};

export default CreateCampaignLastStep;
