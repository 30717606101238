import { useQuery } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import {
  Notification,
  NotificationOverview,
  NotificationOverviews,
  NotificationsByWorkspace,
} from '@/models/NotificationsByWorkspace';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import queryKeys from '@/modules/shared/query/queryKeys';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function getNotificationsDetailsById(token: string | undefined, workspaceId: string, audienceMemberId: string) {
  const response = await axios.get<NotificationOverviews>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/notifications/${audienceMemberId}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data.data;
}

export function useGetNotificationsDetailsById(notificationId: string | undefined) {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);

  return useQuery<NotificationOverview>({
    queryKey: [queryKeys.notifications, notificationId],
    queryFn: () =>
      getNotificationsDetailsById(user?.signInUserSession.idToken.jwtToken, workspace?.id ?? '', notificationId ?? ''),
  });
}
