import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useDeleteCampaignById } from '@/api/campaigns/useDeleteCampaign';
import { useGetCampaigns } from '@/api/campaigns/useGetCampaigns';
import { Button } from '@/component-library/primitives/Button/Button';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Input } from '@/component-library/widgets/Input/Input';
import { Campaign, DeleteCampaignDetails } from '@/models/Campaigns';
import queryKeys from '@/modules/shared/query/queryKeys';
import { AppRoutes } from '@/utils/routes/router';

const DeleteCampaign = () => {
  const deleteCampaignMutation = useDeleteCampaignById();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { id: campaignId } = useParams();
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const { data } = useGetCampaigns();
  const campaignToDelete = data?.filter((campaign) => campaign.id === campaignId);
  const queryClient = useQueryClient();

  useEffect(() => {
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.campaigns), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo]);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        campaignName: yup
          .string()
          .trim()
          .oneOf([campaignToDelete && campaignToDelete[0]?.name], t('DeleteCampaignPage.campaignNameNotMatchingError'))
          .required(t('CommonErrors.campaignNameRequired')),
      })
      .required();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeleteCampaignDetails>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: DeleteCampaignDetails) => {
    if (campaignId) {
      const sendData = campaignId;

      deleteCampaignMutation.mutate(sendData, {
        onSuccess: () => {
          queryClient.setQueryData<Campaign[]>(
            queryKeys.campaigns,
            (prevData) => prevData?.filter((campaign) => campaign.id !== campaignId) || [],
          );
          setToastInfo({ message: t('DeleteCampaignPage.toastSuccess'), toastType: 'success' });
          setOpen(true);
        },
        onError: () => {
          setToastInfo({ message: t('DeleteCampaignPage.toastError'), toastType: 'error' });
          setOpen(true);
        },
      });
    }
  };

  const onBack = () => {
    navigate(AppRoutes.campaigns);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-12">
        <div>
          <div className="position: relative mb-4">
            <div className="position: absolute top-0.5 -left-10 cursor-pointer" onClick={onBack}>
              <ChevronIcon type={'left'} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
            </div>
            <h1 className="text-custom-aliceBlue font-semibold text-xl mb-3">{t('DeleteCampaignPage.title')}</h1>
            <p className="text-gray-900 py-5 px-6 bg-yellow-200 rounded-lg w-[50rem]">
              <Trans
                i18nKey="DeleteCampaignPage.bannerLabel"
                components={{
                  primary: <span className="font-bold" />,
                }}
              />
            </p>
          </div>
          <form className="space-y-6 min-w-[50rem] max-w-[50rem] w-1/2 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <section className="px-6 pt-6 pb-8 space-y-8 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
              <p>
                <Trans
                  i18nKey="DeleteCampaignPage.descriptionText"
                  components={{
                    primary: <span className="text-yellow-200" />,
                  }}
                  values={{ campaignName: campaignToDelete && campaignToDelete[0]?.name }}
                />
              </p>
              <section className="space-y-4">
                <p>{t('DeleteCampaignPage.typeNameInstructionLabel')}</p>
                <Input
                  className="text-custom-aliceBlue border-gray-700"
                  placeholder={t('DeleteCampaignPage.campaignNamePlaceholder')}
                  {...register('campaignName')}
                  error={t(errors.campaignName?.message) as string}
                />
                <p>{t('DeleteCampaignPage.caseSensitiveLabel')}</p>
              </section>
            </section>
            <div className="flex justify-end">
              <Button variant="cancel" onClick={onBack}>
                {t('DeleteCampaignPage.linkCancel')}
              </Button>
              <Button
                variant="destructive"
                className="px-4 !w-52 ml-8"
                isLoading={deleteCampaignMutation.isLoading || deleteCampaignMutation.isSuccess}
              >
                {t('DeleteCampaignPage.buttonPermanentlyDelete')}
              </Button>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
    </>
  );
};

export default DeleteCampaign;
