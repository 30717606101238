import { useTranslation } from 'react-i18next';
import { ActionMeta, MultiValue } from 'react-select';
import { createColumnHelper } from '@tanstack/react-table';

import { useGetWorkspacesById } from '@/api/workspaces/useGetWorkspaceById';
import Badge from '@/component-library/primitives/Badge/Badge';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
export interface MapFieldsImport {
  csvHeader: string;
  csvExample: (string | number)[];
  id: number;
  attribute: { value: string; label: string } | undefined;
  type: { value: string; label: string } | undefined;
  included: boolean;
}

const partialTypeOptions = [
  { label: 'String', value: '1' },
  { label: 'Number', value: '2' },
];

const dateTypeOptions = [
  { label: 'dd/mm/yyyy', value: '3' },
  { label: 'mm/dd/yyyy', value: '4' },
  { label: 'yyyy/mm/dd', value: '5' },
];

export const dataTypeOptions = [
  {
    options: partialTypeOptions,
  },
  {
    label: 'Date formats:',
    options: dateTypeOptions,
  },
];

export const useMapFieldsTable = (
  control: any,
  errors: any,
  mapFieldsData: any,
  setMapFieldsData: React.Dispatch<
    React.SetStateAction<
      {
        csvHeader: string;
        csvExample: any[];
        id: number;
        attribute: { value: string; label: string } | undefined;
        type: { value: string; label: string } | undefined;
        included: boolean;
      }[]
    >
  >,
  includeAttribute: (attributeId: number) => void,
) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<MapFieldsImport>();
  const { data: workspaceData } = useGetWorkspacesById();

  const predefinedAttributes = workspaceData?.predefinedAttributes || [];
  const coreAttributes = workspaceData?.coreAttributes || [];

  const combinedAttributesSet = new Set([...predefinedAttributes, ...coreAttributes]);

  const uniqueAttributesArray = Array.from(combinedAttributesSet);

  const attributeOptions = uniqueAttributesArray.map((attribute, index) => ({
    value: index.toString(),
    label: attribute,
  }));

  const updateMapFieldsWithAttribute = (
    multiValue: MultiValue<{ label: string; value: string }>,
    action: ActionMeta<{ label: string; value: string }>,
  ) => {
    const attributeId = Number(action.name?.split('.')[1]);
    setMapFieldsData((state) =>
      state.map((el, id) => {
        return id === attributeId ? { ...el, attribute: multiValue as any } : el;
      }),
    );
  };

  const updateMapFieldsWithType = (
    multiValue: MultiValue<{ label: string; value: string }>,
    action: ActionMeta<{ label: string; value: string }>,
  ) => {
    const typeId = Number(action.name?.split('.')[1]);
    setMapFieldsData((state) =>
      state.map((el, id) => {
        return id === typeId ? { ...el, type: multiValue as any } : el;
      }),
    );
  };

  const columns = [
    columnHelper.accessor('csvHeader', {
      header: () => <div>{t('ImportMembersPage.csvHeaderLabel')}</div>,
      cell: (info) => (
        <div
          className={` w-32 ${
            mapFieldsData[Number(info.row.id)]?.included ? 'text-gray-50' : 'text-gray-100 opacity-[0.3]'
          }`}
        >
          {info.renderValue()}
        </div>
      ),
    }),
    columnHelper.accessor('csvExample', {
      header: () => <div>{t('ImportMembersPage.csvExample')}</div>,
      cell: ({ row }) => (
        <div
          className={`flex flex-row gap-2 items-start xl:flex-wrap xl:w-[13rem] 3xl:w-[17rem] ${
            mapFieldsData[Number(row.id)]?.included ? 'text-gray-50' : 'text-gray-100 opacity-[0.3]'
          }`}
        >
          <div className="text-gray-400 xl:max-w-[13rem] 3xl:max-w-[14.3rem]">
            {row.original.csvExample[0] ? (
              row.original.csvExample[0]
            ) : (
              <span className="text-orange-400">{t('ImportMembersPage.noDataLabel')}</span>
            )}
          </div>
          <Badge
            number={row.original.csvExample[1] as number}
            className="bg-secondary-400 text-gray-900 text-[10px] font-medium !h-[1.375rem] !w-[2.188rem]"
          />
        </div>
      ),
    }),
    columnHelper.accessor('attribute', {
      header: () => <div>{t('ImportMembersPage.mappedAttribute')}</div>,
      cell: (info) => {
        return (
          <div className="mt-3 xl:w-[12rem] 3xl:w-[14rem]">
            {mapFieldsData[Number(info.row.id)]?.included ? (
              <SelectField
                name={`mappedAttributes.${info.row.id}.attribute`}
                control={control}
                options={attributeOptions}
                placeholder={t('ImportMembersPage.mapAttributePlaceholder')}
                errorMsg={errors.mappedAttributes?.[info.row.id]?.attribute?.message}
                className={`mb-2 mappedAttributesDropdown-${info.row.id}`}
                bgColor={'dark'}
                isSearchable={false}
                customOnChange={updateMapFieldsWithAttribute}
              />
            ) : (
              <div className="bg-applicationBgBlue h-[3rem] rounded-lg py-3 px-3 text-gray-500 xl:text-xs 3xl:text-sm font-normal tracking-[0.018rem] mb-2">
                {t('ImportMembersPage.thisColumnWillBeSkippedPlaceholder')}
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('type', {
      header: () => <div>{t('ImportMembersPage.typeLabel')}</div>,
      cell: (info) => {
        return (
          <div className="w-[12rem] mt-3">
            {mapFieldsData[Number(info.row.id)]?.included ? (
              <SelectField
                name={`mappedAttributes.${info.row.id}.type`}
                control={control}
                options={dataTypeOptions}
                placeholder={t('ImportMembersPage.whatShouldWeDoWithEmptyValuesPlaceholder')}
                errorMsg={errors.mappedAttributes?.[info.row.id]?.type?.message}
                className={'mb-2'}
                bgColor={'dark'}
                isSearchable={false}
                customOnChange={updateMapFieldsWithType}
              />
            ) : (
              <div className="bg-applicationBgBlue h-[3rem] rounded-lg py-3 px-3 text-gray-500 text-sm font-normal tracking-[0.018rem] mb-2">
                {t('ImportMembersPage.skippedLabel')}
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('included', {
      header: () => <div></div>,
      cell: (info) => {
        return (
          <ToggleSwitch value={info.renderValue() as boolean} onChange={() => includeAttribute(Number(info.row.id))} />
        );
      },
    }),
  ];

  return { columns };
};
