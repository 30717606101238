import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function deleteAudienceById(workspaceId: string, audienceMemberId: string, token: string | undefined) {
  const response = await axios.delete<string>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/audience/${audienceMemberId}`,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useDeleteMemberById() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<string, Error, string>((data) =>
    deleteAudienceById(workspace?.id ?? '', data, user?.signInUserSession.idToken.jwtToken),
  );
}
