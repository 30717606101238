import { useMutation } from 'react-query';
import axios from 'axios';

import { CreateWorkspaceDetails } from '@/models/WorkspaceDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createWorkspace(data: CreateWorkspaceDetails, token: string | undefined) {
  const response = await axios.post<CreateWorkspaceDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreateWorkspace() {
  const { user } = useAuth();
  return useMutation<CreateWorkspaceDetails, Error, CreateWorkspaceDetails>((data) =>
    createWorkspace(data, user?.signInUserSession.idToken.jwtToken),
  );
}
