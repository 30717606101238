import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import Breadcrumbs from '@/component-library/primitives/Breadcrumbs/Breadcrumbs';
import UserCircleIcon from '@/component-library/primitives/Icons/UserCircleIcon/UserCircleIcon';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AppRoutes } from '@/utils/routes/router';

import Avatar from '../../primitives/Avatar/Avatar';
import DropDown from '../../primitives/DropDown/DropDown';
import SignOutIcon from '../../primitives/Icons/SignOut/SignOut';
import Loader from '../../primitives/Loader/Loader';
import { selectIsCollapsableIconHidden } from '../SideMenu/sideMenuSlice';

const LoggedInHeader = ({ children }: { children: ReactNode }) => {
  const { user, signOut } = useAuth();
  const isCollapsableIconHidden = useAppSelector(selectIsCollapsableIconHidden);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    !user && navigate(AppRoutes.dashboard);
  }, [user]);

  // TODO: should be improved
  if (!user) return <Loader />;

  const options = [
    {
      icon: <UserCircleIcon color="#F2FCF8" width="24" height="24" />,
      text: t('HeaderLoggedIn.personalAccount'),
      key: 'personalAccount',
    },
    {
      icon: <SignOutIcon color="#F2FCF8" />,
      text: t('HeaderLoggedIn.optionSignOut'),
      key: 'signOut',
    },
  ];

  return (
    <div className="flex flex-col flex-1 min-w-min bg-applicationBgBlue">
      <div className="flex justify-between text-white px-[2rem] h-28 items-center">
        <div className={`${isCollapsableIconHidden && 'ml-[16.688rem]'} flex flex-row`}>
          <Breadcrumbs />
        </div>
        <div className="flex items-center space-x-6">
          <div className="flex flex-col items-start">
            <span className="text-primary-400 text-[10px] tracking-[0.025rem]">WORKSPACE:</span>
            <span className="mr-2 text-gray-300">{workspace?.name}</span>
          </div>
          <DropDown
            options={options}
            trigger={<Avatar initials={user.attributes.given_name} size="small" />}
            onSelect={(option: any) => {
              switch (option.key) {
                case 'personalAccount':
                  navigate(AppRoutes.personalAccount);
                  break;
                case 'signOut':
                  signOut();
                  break;

                default:
                  break;
              }
            }}
          />
        </div>
      </div>

      {/* Main content */}
      <div className="pb-[1rem] px-[2rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] h-loggedInScreenWithoutHeader">
        {children}
      </div>
    </div>
  );
};

export default LoggedInHeader;
