import { useEffect, useState } from 'react';
import * as d3 from 'd3';

const START_ANGLE = -Math.PI / 2 - 0.5;
const END_ANGLE = Math.PI / 2 + 0.5;
const ANGLE_PADDING = 2 * (Math.PI / 180); // 2 degrees in radian
const ANGLE_FILL_OUT = Math.abs(END_ANGLE - START_ANGLE) - ANGLE_PADDING;

const SVG_MAX_WIDTH = 200;
const SVG_MAX_HEIGHT = 120;
const CIRCLE_OUTER_RADIUS = 80;
const CIRCLE_RADIUS_DIFF = 15;
const CIRCLE_INNER_RADIUS = CIRCLE_OUTER_RADIUS - CIRCLE_RADIUS_DIFF;
const CIRCLE_FILL_PADDING = 3;

const CircleBarWithText = ({
  circleSvgId,
  fillPercentage = 0,
  color = 'blue',
  textColor,
}: {
  circleSvgId: string;
  fillPercentage?: number;
  color?: string;
  textColor?: string;
}) => {
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  useEffect(() => {
    const animateFill = () => {
      let currentPercentage = 0;
      const animationInterval = setInterval(() => {
        currentPercentage += 1;
        setAnimatedPercentage(currentPercentage);
        if (currentPercentage >= fillPercentage) {
          clearInterval(animationInterval);
          setAnimatedPercentage(fillPercentage);
        }
      }, 20);
    };

    animateFill();
    return () => {
      clearInterval(animatedPercentage);
    };
  }, [fillPercentage]);
  // to make sure we always have just one arc rendered (useful when testing and hot-reloading)
  useEffect(() => {
    d3.select(`#${circleSvgId}`).selectChildren().remove();
    const svg = d3
      .select(`#${circleSvgId}`)
      .append('g')
      .attr('transform', `translate(${SVG_MAX_WIDTH / 2}, ${SVG_MAX_HEIGHT / 2})`);

    const arcBg = d3
      .arc<unknown>()
      .outerRadius(CIRCLE_OUTER_RADIUS)
      .innerRadius(CIRCLE_INNER_RADIUS)
      .cornerRadius(20)
      .startAngle(START_ANGLE)
      .endAngle(END_ANGLE);

    const arcFill = d3
      .arc<unknown>()
      .outerRadius(CIRCLE_OUTER_RADIUS - CIRCLE_FILL_PADDING)
      .innerRadius(CIRCLE_INNER_RADIUS + CIRCLE_FILL_PADDING)
      .cornerRadius(20)
      .startAngle(START_ANGLE + ANGLE_PADDING)
      // ANGLE_FILL_OUT fills the whole semi-circle, so, multiplying it by
      // (fillPercentage / 100) and adding it to the START_ANGLE fills the circle properly
      .endAngle(START_ANGLE + ANGLE_FILL_OUT * (animatedPercentage / 100));

    svg
      .append('path')
      .attr('class', 'arc')
      .attr('d', arcBg)
      .attr('fill', '#546583')
      .attr('transform', 'translate(0,25)');

    if (fillPercentage > 0) {
      svg
        .append('path')
        .attr('class', 'arc')
        .attr('d', arcFill)
        .attr('fill', color)
        .attr('transform', 'translate(0,25)');
    }
  }, [circleSvgId, color, animatedPercentage]);

  return (
    <div className="relative mt-2">
      <div className="absolute w-[100%] top-16">
        <div className="text-4xl text-center font-semibold" style={{ color: textColor ? textColor : '' }}>
          {animatedPercentage}%
        </div>
      </div>
      <svg style={{ maxWidth: SVG_MAX_WIDTH, maxHeight: SVG_MAX_HEIGHT }} id={circleSvgId}></svg>
    </div>
  );
};

export default CircleBarWithText;
