import Badge from '../Badge/Badge';
import CircleCheckIcon from '../Icons/CircleCheckIcon/CircleCheckIcon';

interface StepperProps {
  steps: string[];
  activeStep: number;
  className?: string;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep, className }) => {
  function getBadgeClass(step: number) {
    let cls = '';
    if (activeStep === step) {
      cls += '!bg-activeSideMenuOption text-primary-400 font-medium';
    } else if (activeStep > step) {
      cls += '!bg-activeSideMenuOption text-primary-400 font-normal';
    } else {
      cls += '!bg-applicationBgBlue border border-gray-100/50 text-gray-100 opacity-[0.5] !font-light';
    }
    return cls;
  }

  function getLabelClass(step: number) {
    let cls = 'text-sm';
    if (activeStep === step) {
      cls += ' text-gray-100 font-medium';
    } else if (activeStep > step) {
      cls += ' text-primary-500 font-normal';
    } else {
      cls += ' text-gray-100 opacity-[0.5] font-light';
    }
    return cls;
  }

  return (
    <div className="flex mb-13">
      {steps.map((label, index) => (
        <div key={index}>
          <div className={`flex flex-row items-center gap-2 ${className}`}>
            <div>
              {steps.length - 1 === index && (
                <hr
                  className={`${activeStep === steps.length - 1 ? 'border-primary-400' : 'border-gray-100/50'} w-7`}
                />
              )}
              {index > 0 && index < steps.length - 1 && (
                <hr className={`${activeStep >= index ? 'border-primary-400' : 'border-gray-100/50'} w-7`} />
              )}
            </div>
            <Badge
              className={`${getBadgeClass(index)}`}
              number={index + 1}
              icon={activeStep > index ? <CircleCheckIcon /> : undefined}
            />
            <span className={`${getLabelClass(index)}`}>{label}</span>
            <div>
              {index > 0 && index < steps.length - 1 && (
                <hr className={`${activeStep >= index ? 'border-primary-400' : 'border-gray-100/50'} w-7`} />
              )}
              {index === 0 && <hr className="border-primary-400 w-7" />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
