import { SVGProps } from 'react';

interface UsersIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const UsersIcon: React.FC<UsersIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M17.5 16.8631C17.5 15.4117 16.1087 14.177 14.1667 13.7194M12.5 16.8631C12.5 15.0222 10.2614 13.5298 7.5 13.5298C4.73858 13.5298 2.5 15.0222 2.5 16.8631M12.5 11.0298C14.3409 11.0298 15.8333 9.53741 15.8333 7.69646C15.8333 5.85551 14.3409 4.36313 12.5 4.36313M7.5 11.0298C5.65905 11.0298 4.16667 9.53741 4.16667 7.69646C4.16667 5.85551 5.65905 4.36313 7.5 4.36313C9.34095 4.36313 10.8333 5.85551 10.8333 7.69646C10.8333 9.53741 9.34095 11.0298 7.5 11.0298Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
