import { useMutation } from 'react-query';
import axios from 'axios';

import SendSMSDetails, { SMSReviewResponse } from '@/models/SendSMSDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function reviewSendSMS(data: SendSMSDetails, token: string | undefined) {
  const response = await axios.post<SMSReviewResponse>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/notifications/sms/review`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useReviewSendSms() {
  const { user } = useAuth();

  const mutation = useMutation<SMSReviewResponse, Error, SendSMSDetails>((data) =>
    reviewSendSMS(data, user?.signInUserSession.idToken.jwtToken),
  );

  return mutation;
}
