interface AvatarProps {
  imageUrl?: string;
  initials: string | undefined;
  size: 'small' | 'big' | 'medium';
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ imageUrl, initials, size, className = '' }) => {
  const getSizeClasses = () => {
    if (size === 'small') {
      return 'w-8 h-8';
    }
    if (size === 'medium') {
      return 'w-14 h-14';
    }
    return 'w-20 h-20';
  };

  const getBackgroundClasses = () => {
    if (!imageUrl) {
      return 'bg-secondary-100';
    }
    return '';
  };

  const getTextClasses = () => {
    if (size === 'small') {
      return 'text-sm';
    }
    if (size === 'medium') {
      return 'text-2xl';
    }
    return 'text-3xl';
  };

  return (
    <div
      className={`flex items-center justify-center rounded-full cursor-pointer ${getSizeClasses()} ${getBackgroundClasses()} ${className}`}
      data-cy="avatar"
    >
      {imageUrl ? (
        <img src={imageUrl} alt="User Avatar" className="rounded-full h-full" />
      ) : (
        <span className={`text- text-secondary-500 font-semibold ${getTextClasses()}`}>
          {initials?.charAt(0).toUpperCase()}
        </span>
      )}
    </div>
  );
};

export default Avatar;
