import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCreateMember } from '@/api/workspaces/useAudience';
import { useGetWorkspacesById } from '@/api/workspaces/useGetWorkspaceById';
import { Button } from '@/component-library/primitives/Button/Button';
import AddPlusCircleIcon from '@/component-library/primitives/Icons/AddPlusCircleIcon/AddPlusCircleIcon';
import CircleHelpIcon from '@/component-library/primitives/Icons/CircleHelpIcon/CircleHelpIcon';
import TrashIcon from '@/component-library/primitives/Icons/TrashIcon/TrashIcon';
import { ProvideToasts, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Toast } from '@/component-library/primitives/Toast/Toast';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';
import { Input } from '@/component-library/widgets/Input/Input';
import { PhoneCodeInput } from '@/component-library/widgets/PhoneCodeInput/PhoneCodeInput';
import PredefinedAttributesContainer from '@/component-library/widgets/PredefinedAttributesContainer/PredefinedAttributesContainer';
import SelectField, { OptionType } from '@/component-library/widgets/SelectField/SelectField';
import { CreateMemberDetails, CreateMemberDetailsRequest } from '@/models/WorkspaceDetails';
import { ErrorMessage } from '@/modules/shared/enums/enums';
import { AppRoutes } from '@/utils/routes/router';

import { dataTypeOptions } from '../useMapFieldsTable';

declare module 'yup' {
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > {
    uniqueOnProperty(property: string, message?: string): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }
}

yup.addMethod(yup.array, 'uniqueOnProperty', function (property, message: 'Duplicates') {
  return this.test('uniqueOnProperty', message, (array) => {
    const arrayWithoutEmptyKeys = array?.filter((val) => val.key !== '');
    const uniqueData = Array.from(new Set(arrayWithoutEmptyKeys?.map((row) => row[property])));
    return arrayWithoutEmptyKeys?.length === uniqueData.length;
  });
});

export const schema = yup
  .object()
  .shape({
    id: yup.string().trim(),
    name: yup.string().trim().required('CommonErrors.nameRequired'),
    email: yup.string().trim().email('CommonErrors.emailFormatValidation').required('CommonErrors.emailRequired'),
    phoneNumber: yup
      .string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'CommonErrors.phoneNumberInvalid',
      ),
    attributes: yup
      .array()
      .of(
        yup.object().shape(
          {
            key: yup
              .string()
              .trim()
              .when('value', {
                is: (val: string) => Boolean(val),
                then: () => {
                  return yup.string().required('CommonErrors.keyRequired');
                },
              }),
            value: yup
              .string()
              .trim()
              .when('key', {
                is: (key: string) => Boolean(key),
                then: () => {
                  return yup.string().required('CommonErrors.valueRequired');
                },
              }),
          },
          [
            ['value', 'key'],
            ['key', 'value'],
          ],
        ),
      )
      .uniqueOnProperty('key', 'CommonErrors.duplicatedKeyAttribute'),
  })
  .required();

const CreateMember = () => {
  const createMemberMutation = useCreateMember();
  const { data: workspaceData } = useGetWorkspacesById();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });
  const [isAttributesIconHovered, setIsAttributesIconHovered] = useState(false);
  const [isOtherAttributesIconHovered, setIsOtherAttributesIconHovered] = useState(false);
  const { t } = useTranslation();
  const [selectedTypes, setSelectedTypes] = useState<Array<OptionType>>([]);
  const forbiddenKeys = ['id', 'email', 'name'];

  const defaultTypeOption = dataTypeOptions[0].options[0];

  const attributeData =
    workspaceData?.predefinedAttributes && workspaceData?.predefinedAttributes.length > 0
      ? workspaceData?.predefinedAttributes.map((attribute, index) => {
          return {
            id: index,
            label: attribute,
          };
        })
      : [];
  const [predefinedAttributes, setPredefinedAttributes] = useState(attributeData);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CreateMemberDetails>({
    defaultValues: {
      attributes: [{ key: '', value: '', type: defaultTypeOption }],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove, update } = useFieldArray({
    name: 'attributes',
    control,
  });

  useEffect(() => {
    const initialTypeValues = Array(fields.length).fill(dataTypeOptions[0].options[0]);
    setSelectedTypes(initialTypeValues);
    setPredefinedAttributes(
      workspaceData?.predefinedAttributes && workspaceData?.predefinedAttributes.length > 0
        ? workspaceData?.predefinedAttributes.map((attribute, index) => {
            return {
              id: index,
              label: attribute,
            };
          })
        : [],
    );
    let timerBeforeNavigation: NodeJS.Timeout;
    if (toastInfo.toastType === 'success') {
      timerBeforeNavigation = setTimeout(() => navigate(AppRoutes.members), 2000);
    }
    return () => {
      clearTimeout(timerBeforeNavigation);
    };
  }, [navigate, toastInfo, workspaceData]);

  const appendPredefinedAttribute = (key: string) => {
    append(
      { key: key, value: '', type: defaultTypeOption, isPredefined: true },
      { focusName: `attributes.${fields.length}.value` },
    );
    const attributes = predefinedAttributes.filter((attr) => attr.label !== key);
    setPredefinedAttributes(attributes);
  };

  const removeAttribute = (index: number, removedKey: string, isPredefined: boolean) => {
    remove(index);

    if (isPredefined) {
      const predefinedAttr = attributeData.filter((attr) => attr.label === removedKey);
      setPredefinedAttributes([...predefinedAttributes, predefinedAttr[0]]);
    }
  };

  const onSubmit = (data: CreateMemberDetails) => {
    for (let index = 0; index < data.attributes.length; index++) {
      const attribute = data.attributes[index];

      if (forbiddenKeys.includes(attribute.key.toLowerCase())) {
        setError(`attributes.${index}.key`, {
          type: 'manual',
          message: t(`CreateMemberPage.forbiddenAttributes`),
        });
        return;
      }
    }
    const attributesWithoutEmptyValues = data.attributes?.filter((val) => val.key && val.value);

    const attributesWithTransformedType = attributesWithoutEmptyValues?.map((attribute) => ({
      ...attribute,
      type: attribute.type.label.toLowerCase(),
    }));

    const sendData = {
      id: data.id,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      attributes: attributesWithTransformedType,
    };
    createMemberMutation.mutate(sendData as CreateMemberDetailsRequest, {
      onSuccess: () => {
        setToastInfo({ message: t('CreateMemberPage.toastSuccess'), toastType: 'success' });
        setOpen(true);
      },
      onError: (e: any) => {
        const error = e?.response?.data?.message?.error;
        if (error) {
          error.forEach((errorMessage: string) => {
            switch (errorMessage) {
              case ErrorMessage.EMAIL_EXISTS:
                setError('email', {
                  type: 'manual',
                  message: t(`CreateMemberPage.${error}`),
                });
                break;
              case ErrorMessage.ID_EXISTS:
                setError('id', {
                  type: 'manual',
                  message: t(`CreateMemberPage.${error}`),
                });
                break;
              // Add more cases as needed
              default:
                setToastInfo({ message: t('CreateMemberPage.toastError'), toastType: 'error' });
                setOpen(true);
                console.log('Unknown error occurred:', errorMessage);
                break;
            }
          });
        }
      },
    });
  };

  return (
    <>
      <div className="flex flex-col items-center mt-5">
        <div className="mb-64">
          <div className="relative mb-8">
            <h1 className="text-white font-medium text-xl mb-2">{t('CreateMemberPage.title')}</h1>
            <span className="text-gray-300 text-sm">{t('CreateMemberPage.subtitle')}</span>
          </div>
          <form
            className="space-y-10 min-w-[38rem] 3xl:mr-0 lg:mr-[80px] w-1/2"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="@audience/createMember/form"
          >
            <section>
              <div className="flex flex-row items-center justify-between mb-6">
                <h2 className="text-xs font-semibold text-yellow-500">{t('CreateMemberPage.attributesLabel')}</h2>
                <Tooltip
                  side="right"
                  content={
                    <div className="text-gray-100 text-xs font-light">{t('CreateMemberPage.tooltipAttributes')}</div>
                  }
                  open={isAttributesIconHovered}
                  onOpenChange={(open: boolean) => {
                    if (open) {
                      setIsAttributesIconHovered(true);
                    } else {
                      setIsAttributesIconHovered(false);
                    }
                  }}
                  contentClassName="bg-custom-darkBlue p-8 rounded-lg z-50 w-44 text-center"
                  arrowClassName="fill-custom-darkBlue w-5 h-2"
                >
                  <button type="button">
                    <CircleHelpIcon />
                  </button>
                </Tooltip>
              </div>
              <div className="flex flex-row gap-2 mb-5">
                <div className="basis-1/2">
                  <Input
                    placeholder={t('CreateMemberPage.namePlaceholder')}
                    {...register('name')}
                    error={t(errors.name?.message) as string}
                    label={t('CreateMemberPage.nameLabel')}
                    data-cy="memberFullName"
                    data-testid="@audience/createMember/name-input"
                  />
                </div>
                <div className="basis-1/2">
                  <Input
                    placeholder={t('CreateMemberPage.idPlaceholder')}
                    {...register('id')}
                    error={t(errors.id?.message) as string}
                    label={t('CreateMemberPage.idLabel')}
                    optional={true}
                  />
                </div>
              </div>
              <div className="mb-5">
                <Input
                  placeholder={t('CreateMemberPage.emailPlaceholder')}
                  {...register('email')}
                  error={t(errors.email?.message) as string}
                  label={t('CreateMemberPage.emailLabel')}
                  data-cy="memberEmail"
                  data-testid="@audience/createMember/email-input"
                />
              </div>
              <PhoneCodeInput
                placeholder={t('CreateMemberPage.phoneNumberPlaceholder')}
                name="phoneNumber"
                control={control}
                label={t('CreateMemberPage.phoneNumberLabel')}
                error={t(errors.phoneNumber?.message) as string}
                optional={true}
                data-cy="memberPhoneNumber"
              />
            </section>
            <hr className="w-full border-custom-darkBlue" />
            <section>
              <div className="flex flex-row items-center justify-between mb-5">
                <h2 className="text-xs font-semibold text-yellow-500">{t('CreateMemberPage.otherAttributesLabel')}</h2>
                <Tooltip
                  side="right"
                  content={
                    <div className="text-gray-100 text-xs font-light">
                      {t('CreateMemberPage.tooltipOtherAttributes')}
                    </div>
                  }
                  open={isOtherAttributesIconHovered}
                  onOpenChange={(open: boolean) => {
                    if (open) {
                      setIsOtherAttributesIconHovered(true);
                    } else {
                      setIsOtherAttributesIconHovered(false);
                    }
                  }}
                  contentClassName="bg-custom-darkBlue p-8 rounded-lg z-50 w-44 text-center"
                  arrowClassName="fill-custom-darkBlue w-5 h-2"
                >
                  <button type="button">
                    <CircleHelpIcon />
                  </button>
                </Tooltip>
              </div>
              {fields.map((field, index) => {
                const { onChange } = register(`attributes.${index}.key`);
                return (
                  <div key={field.id} className="relative mb-2">
                    <div className={`flex flex-row gap-2 ${fields.length > 1 ? 'w-[95%]' : 'w-full'}`}>
                      <div className="basis-1/3">
                        <Input
                          placeholder={t('CreateMemberPage.keyPlaceholder')}
                          {...register(`attributes.${index}.key`)}
                          error={t(errors.attributes?.[index]?.key?.message)}
                          label={t('CreateMemberPage.keyLabel')}
                          disabled={fields[index].isPredefined}
                          onChange={(e) => {
                            onChange(e);
                            const itemToBeRemoved = predefinedAttributes.find((attr) => attr.label === e.target.value);
                            if (itemToBeRemoved) {
                              update(index, {
                                key: e.target.value,
                                value: fields[index].value,
                                type: selectedTypes[index],
                                isPredefined: true,
                              });
                              attributeData.filter((attr) => attr.label === e.target.value).length > 0;
                              const filteredPredefinedAttributes = predefinedAttributes.filter(
                                (attr) => attr.label !== itemToBeRemoved.label,
                              );
                              setPredefinedAttributes(filteredPredefinedAttributes);
                            }
                          }}
                        />
                      </div>
                      <div className="basis-1/3">
                        <SelectField
                          key={index}
                          name={`attributes.${index}.type`}
                          control={control}
                          options={dataTypeOptions}
                          errorMsg={t(errors.attributes?.[index]?.type)}
                          bgColor={'light'}
                          isSearchable={false}
                          label={t('CreateMemberPage.typeLabel')}
                          value={selectedTypes[index]}
                          isClearable={false}
                          customOnChange={(selectedOption) => {
                            setSelectedTypes((prevSelectedTypes) => {
                              const updatedValues = [...prevSelectedTypes];
                              updatedValues[index] = selectedOption;
                              return updatedValues;
                            });
                          }}
                        />
                      </div>
                      <div className="basis-1/3">
                        <Input
                          placeholder={t('CreateMemberPage.valuePlaceholder')}
                          {...register(`attributes.${index}.value`)}
                          error={t(errors.attributes?.[index]?.value?.message)}
                          label={t('CreateMemberPage.valueLabel')}
                        />
                      </div>
                    </div>
                    {fields.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeAttribute(index, field.key, field.isPredefined)}
                        className="group absolute top-8 right-0"
                      >
                        <span className="group-hover:[&>*_path]:stroke-red-400">
                          <TrashIcon color="#9BAFD0" />
                        </span>
                      </button>
                    )}

                    {fields.length - 1 === index && (
                      <div className="flex justify-start mt-4">
                        <Button
                          variant="transparent"
                          className="group mb-[4.5rem]"
                          iconLeft={
                            <span className="group-active:[&>*_path]:stroke-secondary-100">
                              <AddPlusCircleIcon color="#7898FB" />
                            </span>
                          }
                          onClick={() => {
                            append({ key: '', value: '', type: defaultTypeOption, isPredefined: false });
                            setSelectedTypes((prevSelectedTypes) => {
                              const updatedValues = [...prevSelectedTypes];
                              updatedValues[index + 1] = dataTypeOptions[0].options[0];
                              return updatedValues;
                            });
                          }}
                        >
                          {t('CreateMemberPage.linkCreateNewAttribute')}
                        </Button>
                        {errors.attributes?.message && (
                          <p className="text-errorRed-500 mt-4 ml-2 text-center">
                            {t(errors.attributes?.message) as string}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </section>
            <div className="flex items-center justify-center fixed bottom-0 z-50 bg-bottomBarWithOpacity backdrop-blur-[8px] overflow-hidden w-screenWithoutSideMenu left-[21rem] h-24 border-t border-gray-800">
              <div className="flex items-center justify-between 3xl:min-w-[40rem] lg:min-w-[40rem]">
                <Button variant="cancel" onClick={() => navigate(AppRoutes.members)}>
                  {t('CreateMemberPage.linkCancel')}
                </Button>
                <Button
                  className="px-4 !w-52"
                  data-cy="createMemberButton"
                  isLoading={createMemberMutation.isLoading || createMemberMutation.isSuccess}
                >
                  {t('CreateMemberPage.buttonCreateAMember')}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ProvideToasts>
          <Toast
            isOpen={open}
            setOpen={setOpen}
            message={toastInfo.message}
            toastType={toastInfo.toastType as ToastType}
          />
        </ProvideToasts>
      </div>
      <div className="absolute top-[8.25rem] right-0 px-8 h-attributesSection 3xl:w-[23.625rem] lg:w-[20rem] border-l border-gray-800 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <h2 className="text-xs font-semibold text-yellow-500">{t('CreateMemberPage.predefinedAttributesLabel')}</h2>
        <div className="mt-3">
          <PredefinedAttributesContainer data={predefinedAttributes} appendAttribute={appendPredefinedAttribute} />
        </div>
      </div>
    </>
  );
};

export default CreateMember;
