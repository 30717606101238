import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  FitViewOptions,
  MarkerType,
  NodeTypes,
  OnConnect,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import DiagramNode, { CustomNode } from '@/component-library/primitives/DiagramNode/DiagramNode';

const nodeTypes: NodeTypes = {
  custom: DiagramNode,
};

const initialNodes: CustomNode[] = [
  {
    id: 'trigger-node',
    type: 'custom',
    data: { name: 'Trigger', type: 'input', className: 'bg-white h-9', emoji: '⚡' },
    position: { x: 200, y: 50 },
    deletable: false,
  },
  {
    id: 'exit-node',
    type: 'custom',
    data: { name: 'Exit', type: 'output', className: 'bg-white h-9', emoji: '🏁' },
    position: { x: 600, y: 50 },
  },
];

const proOptions = { hideAttribution: true };

const fitViewOptions: FitViewOptions = {
  padding: 1.0,
};

let id = 0;
const getId = () => `dndnode_${id++}`;

const Diagram = () => {
  const { t } = useTranslation();
  const [showDragBlockMsg, setShowDragBlockMsg] = useState(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const onConnect: OnConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            label: '+',
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: '#B4F0D4', color: '#fff', fillOpacity: 1 },
            labelStyle: { fill: '#1C222A', fontSize: '15px', fontWeight: 600 },
            type: 'step',
            style: { stroke: '#B4F0D4' },
            markerEnd: { type: MarkerType.ArrowClosed, color: '#B4F0D4' },
          },
          eds,
        ),
      ),
    [setEdges],
  );
  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      const nodeName = event.dataTransfer.getData('reactflow-nodeName');
      const nodeColor = event.dataTransfer.getData('reactflow-color');
      const nodeEmoji = event.dataTransfer.getData('reactflow-emoji');

      if (typeof nodeName === 'undefined' || !nodeName) {
        return;
      } else if (typeof nodeColor === 'undefined' || !nodeColor) {
        return;
      } else if (typeof nodeEmoji === 'undefined' || !nodeEmoji) {
        return;
      }

      if (reactFlowBounds && reactFlowInstance) {
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        const newNode: CustomNode = {
          id: getId(),
          type: 'custom',
          data: {
            name: nodeName as string,
            type: 'default',
            className: `h-9 ${nodeColor}`,
            emoji: nodeEmoji as string,
          },
          position: position,
        };

        setNodes((nds) => nds.concat(newNode));
        setShowDragBlockMsg(false);
      }
    },
    [reactFlowInstance],
  );

  return (
    <section className="relative lg:w-[50rem] lg:h-[37.5rem] 3xl:w-[66.75rem] 3xl:h-[37.5rem] px-6 py-6 shadow rounded-lg bg-custom-gray text-custom-aliceBlue">
      {showDragBlockMsg && (
        <div className="flex items-center justify-center w-[100%] h-[90%] absolute">
          <div className="flex flex-col items-center gap-2">
            <p className="text-xl">👌</p>
            <p className="text-base text-gray-300">{t('CreateCampaignPage.dragBlockLabel')}</p>
          </div>
        </div>
      )}
      <div className="reactflow-wrapper w-[100%] h-[100%]" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          nodeTypes={nodeTypes}
          className="bg-custom-gray"
          proOptions={proOptions}
          fitView
          fitViewOptions={fitViewOptions}
          connectionLineStyle={{ stroke: '#B4F0D4' }}
          deleteKeyCode={['Delete', 'Backspace']}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <Controls className="bg-white flex flex-row" position={'top-right'} />
          <Background color="#D9D9D908" variant={BackgroundVariant.Dots} gap={6} size={3} />
        </ReactFlow>
      </div>
    </section>
  );
};

export default Diagram;
