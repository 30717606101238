import { MemberOverview } from '@/models/AudienceByWorkspace';
import { SegmentMembership } from '@/models/Segments';

import AttributesSection from './AttributesSection';
import CampaignsSection from './CampaignsSection';
import DevicesSection from './DevicesSection';
import RecentActivitySection from './RecentActivitySection';
import SegmentsSection from './SegmentsSection';
import SubscriptionPreferencesSection from './SubscriptionPreferencesSection';

interface OverviewPageProps {
  memberData: MemberOverview | undefined;
  changeActiveStep: (step: string) => void;
  segmentsData: SegmentMembership | undefined;
}

const activities = [
  { type: 'Attribute Change', name: '4 attributes (2 identifiers) changed', timestamp: '2023-02-02T17:15:04.568Z' },
  { type: 'Person Created', name: 'email, ID (identifiers)', timestamp: '2023-02-01T10:30:04.568Z' },
];

const OverviewPage: React.FC<OverviewPageProps> = ({ memberData, changeActiveStep, segmentsData }) => {
  return (
    <div className="flex flex-row gap-x-6">
      <div className="flex flex-col gap-y-6 lg:w-[33%] 3xl:w-[27%]">
        <SubscriptionPreferencesSection />
        <AttributesSection memberAttributes={memberData?.attributes} changeActiveStep={changeActiveStep} />
      </div>
      <div className="flex flex-col gap-y-6 lg:w-[65.5%] 3xl:w-[71.5%]">
        <RecentActivitySection activities={activities} changeActiveStep={changeActiveStep} />
        <div className="flex flex-row gap-x-6">
          <div className="lg:w-[48%] 3xl:w-[40%]">
            <DevicesSection devices={[]} changeActiveStep={changeActiveStep} />
          </div>
          <div className="lg:w-[51%] 3xl:w-[59%]">
            <SegmentsSection changeActiveStep={changeActiveStep} segmentsData={segmentsData} />
          </div>
        </div>
        <CampaignsSection campaigns={[]} changeActiveStep={changeActiveStep} />
      </div>
    </div>
  );
};

export default OverviewPage;
