const DeviceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="60" viewBox="0 0 44 60" fill="none">
      <rect width="33" height="60" rx="5" fill="#5D6282" />
      <path d="M0 52H33V56C33 58.2091 31.2091 60 29 60H4C1.79086 60 0 58.2091 0 56V52Z" fill="#494D67" />
      <path d="M33 8H0V4C0 1.79086 1.79086 0 4 0H29C31.2091 0 33 1.79086 33 4V8Z" fill="#494D67" />
      <rect x="12" y="4" width="9" height="1" rx="0.5" fill="#5D6282" />
      <circle cx="17" cy="56" r="2" fill="#5D6282" />
      <g filter="url(#filter0_b_1442_7182)">
        <rect x="4" y="11" width="40" height="11" rx="2" fill="#9DA6E0" fillOpacity="0.6" />
      </g>
      <g filter="url(#filter1_b_1442_7182)">
        <rect x="4" y="24" width="34" height="11" rx="2" fill="#8E95C2" fillOpacity="0.6" />
      </g>
      <defs>
        <filter
          id="filter0_b_1442_7182"
          x="-8.11"
          y="-1.11"
          width="64.22"
          height="35.22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1442_7182" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1442_7182" result="shape" />
        </filter>
        <filter
          id="filter1_b_1442_7182"
          x="-8.11"
          y="11.89"
          width="58.22"
          height="35.22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.055" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1442_7182" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1442_7182" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default DeviceIcon;
