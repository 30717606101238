import React from 'react';
import { Control, FieldErrors, useFieldArray, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetWorkspacesById } from '@/api/workspaces/useGetWorkspaceById';
import AddGroupIcon from '@/component-library/primitives/Icons/AddGroupIcon/AddGroupIcon';
import AddPlusIcon from '@/component-library/primitives/Icons/AddPlusIcon/AddPlusIcon';
import CloseButtonIcon from '@/component-library/primitives/Icons/CloseButtonIcon/CloseButtonIcon';
import DragIcon from '@/component-library/primitives/Icons/DragIcon/DragIcon';
import { Input } from '@/component-library/widgets/Input/Input';
import SelectField from '@/component-library/widgets/SelectField/SelectField';
import { ToggleSwitch } from '@/component-library/widgets/ToggleSwitch/ToggleSwitch';
import { CreateSegmentDetails, EditSegmentDetails } from '@/models/Segments';

interface ConditionsSchemeProps {
  control: Control<CreateSegmentDetails | EditSegmentDetails, any> | undefined;
  register: UseFormRegister<CreateSegmentDetails | EditSegmentDetails>;
  errors: FieldErrors<CreateSegmentDetails | EditSegmentDetails>;
}

export const attributeTypeOptions = [
  { label: 'string', value: '1' },
  { label: 'number', value: '2' },
  { label: 'array', value: '3' },
  { label: 'object', value: '4' },
];

export const handleOperatorOptions = (attributeType: string | undefined) => {
  switch (attributeType) {
    case 'string':
      return [
        { label: 'equal', value: '1' },
        { label: 'notEqual', value: '2' },
      ];
    case 'number':
      return [
        { label: 'equal', value: '1' },
        { label: 'notEqual', value: '2' },
        { label: 'lessThan', value: '3' },
        { label: 'lessThanInclusive', value: '4' },
        { label: 'greaterThan', value: '5' },
        { label: 'greaterThanInclusive', value: '6' },
      ];
    case 'array':
      return [
        { label: 'contains', value: '1' },
        { label: 'doesNotContain', value: '2' },
        { label: 'in', value: '3' },
        { label: 'notIn', value: '4' },
      ];
    case 'object':
      return [
        { label: 'contains', value: '1' },
        { label: 'doesNotContain', value: '2' },
        { label: 'in', value: '3' },
        { label: 'notIn', value: '4' },
        { label: 'equal', value: '5' },
        { label: 'notEqual', value: '6' },
        { label: 'lessThan', value: '7' },
        { label: 'lessThanInclusive', value: '8' },
        { label: 'greaterThan', value: '9' },
        { label: 'greaterThanInclusive', value: '10' },
      ];
    default:
      return [];
  }
};
const ConditionsScheme: React.FC<ConditionsSchemeProps> = ({ control, register, errors }) => {
  const { t } = useTranslation();
  const { data: workspaceData } = useGetWorkspacesById();

  const predefinedAttributes = workspaceData?.predefinedAttributes || [];
  const coreAttributes = workspaceData?.coreAttributes || [];

  const combinedAttributesSet = new Set([...predefinedAttributes, ...coreAttributes]);

  const uniqueAttributesArray = Array.from(combinedAttributesSet);

  const attributeOptions = uniqueAttributesArray.map((attribute, index) => ({
    value: index.toString(),
    label: attribute,
  }));

  const { fields, append, remove, update } = useFieldArray({
    name: 'conditions',
    control,
  });

  const addCondition = () => {
    append(
      {
        nestedLogicalOperator: null,
        conditionGroup: null,
        nestedConditions: [
          {
            attribute: undefined,
            attributeType: undefined,
            operator: undefined,
            value: '',
          },
        ],
      },
      // { focusName: `conditions.${fields.length}.attribute` },
      { shouldFocus: false },
    );
  };

  const addConditionGroup = () => {
    append(
      {
        nestedLogicalOperator: false,
        conditionGroup: Math.random().toString(),
        nestedConditions: [
          {
            attribute: undefined,
            attributeType: undefined,
            operator: undefined,
            value: '',
          },
        ],
      },
      { shouldFocus: false },
    );
  };

  const addNestedCondition = (index: number) => {
    const condition = fields.find((item, id) => id === index);

    update(index, {
      nestedLogicalOperator: condition ? condition.nestedLogicalOperator : false,
      conditionGroup: condition ? condition.conditionGroup : null,
      nestedConditions: condition
        ? [
            ...condition.nestedConditions,
            {
              attribute: undefined,
              attributeType: undefined,
              operator: undefined,
              value: '',
            },
          ]
        : [],
    });
  };

  const resetOperatorOnAttributeTypeChange = (conditionId: number, nestedConditionId: number) => {
    const condition = fields.find((item, id) => id === conditionId);
    if (condition) {
      condition.nestedConditions[nestedConditionId].operator = undefined;
    }

    update(conditionId, {
      nestedLogicalOperator: condition ? condition.nestedLogicalOperator : false,
      conditionGroup: condition ? condition.conditionGroup : null,
      nestedConditions: condition ? [...condition.nestedConditions] : [],
    });
  };

  const changeLogicalOperatorInConditionGroup = (index: number) => {
    const condition = fields.find((item, id) => id === index);

    update(index, {
      nestedLogicalOperator: !condition?.nestedLogicalOperator,
      conditionGroup: condition ? condition.conditionGroup : null,
      nestedConditions: condition ? [...condition.nestedConditions] : [],
    });
  };

  const removeCondition = (index: number) => {
    remove(index);
  };

  const removeNestedCondition = (groupIndex: number, nestedConditionIndex: number) => {
    const condition = fields.find((item, id) => id === groupIndex);
    const nestedConditions = condition?.nestedConditions.filter((cond, ind) => ind !== nestedConditionIndex);

    update(groupIndex, {
      nestedLogicalOperator: condition ? condition.nestedLogicalOperator : false,
      conditionGroup: condition ? condition.conditionGroup : null,
      nestedConditions: nestedConditions ? [...nestedConditions] : [],
    });
  };

  return (
    <>
      <div className="relative mt-5 w-[55rem]">
        {fields.map((condition, conditionIndex) => {
          if (condition.conditionGroup === null) {
            const operatorOptions = handleOperatorOptions(condition?.nestedConditions?.[0].attributeType?.label);
            return (
              <div key={condition.id} className="mb-5 last:mb-0">
                <div
                  className={`absolute ${
                    conditionIndex === fields.length - 1 ? 'left-4 bottom-[31px] h-full border-l' : 'hidden'
                  } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                ></div>
                <div
                  className={`ml-8 relative flex flex-row justify-between items-center w-full py-[7px] pl-[9px] pr-[21px] bg-darkBlue rounded-[7px] `}
                >
                  <div
                    className={`absolute ${
                      conditionIndex === fields.length - 1 ? 'hidden' : '-left-4 top-0 h-[50%]'
                    } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                  ></div>
                  <div className="flex flex-row justify-start items-center gap-2">
                    <div className="cursor-move">
                      <DragIcon />
                    </div>
                    <div>
                      <SelectField
                        name={`conditions.${conditionIndex}.nestedConditions.${0}.attribute`}
                        control={control}
                        placeholder={t('CreateSegmentPage.attributePlaceholder')}
                        options={attributeOptions}
                        errorMsg={errors.conditions?.[conditionIndex]?.nestedConditions?.[0]?.attribute?.message}
                        isSearchable={false}
                        className="w-[10rem]"
                        withBorder={true}
                      />
                    </div>
                    <div>
                      <SelectField
                        name={`conditions.${conditionIndex}.nestedConditions.${0}.attributeType`}
                        control={control}
                        placeholder={t('CreateSegmentPage.typePlaceholder')}
                        options={attributeTypeOptions}
                        errorMsg={errors.conditions?.[conditionIndex]?.nestedConditions?.[0]?.attributeType?.message}
                        isSearchable={false}
                        className="w-[10rem]"
                        withBorder={true}
                        customOnChange={() => resetOperatorOnAttributeTypeChange(conditionIndex, 0)}
                      />
                    </div>
                    <div>
                      <SelectField
                        name={`conditions.${conditionIndex}.nestedConditions.${0}.operator`}
                        control={control}
                        placeholder={t('CreateSegmentPage.operatorPlaceholder')}
                        options={operatorOptions}
                        errorMsg={errors.conditions?.[conditionIndex]?.nestedConditions?.[0]?.operator?.message}
                        isSearchable={false}
                        className="w-[12.188rem]"
                        withBorder={true}
                      />
                    </div>
                    <div>
                      <Input
                        placeholder={t('CreateSegmentPage.valuePlaceholder')}
                        {...register(`conditions.${conditionIndex}.nestedConditions.${0}.value`)}
                        error={errors.conditions?.[conditionIndex]?.nestedConditions?.[0]?.value?.message}
                        withBorder={true}
                        className="w-[9rem]"
                        type={condition.nestedConditions[0].attributeType?.label === 'number' ? 'number' : 'text'}
                      />
                    </div>
                  </div>
                  {fields.length > 1 && (
                    <button type="button" onClick={() => removeCondition(conditionIndex)} className="group">
                      <span className="group-hover:[&>*_path]:stroke-gray-100">
                        <CloseButtonIcon />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            );
          } else {
            const lastGroupBoxPosition = (184 + (condition.nestedConditions.length - 1) * 74) / 2;
            return (
              <div key={condition.id} className="mb-5 last:mb-0">
                <div>
                  <div
                    className={`absolute ${
                      conditionIndex === fields.length - 1 ? `left-4 -top-[26px] border-l` : 'hidden'
                    } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                    style={conditionIndex === fields.length - 1 ? { bottom: `${lastGroupBoxPosition}px` } : {}}
                  ></div>
                  <div
                    className={`relative ml-8 border rounded-[7px] border-custom-conditionGroupBorder pt-3 pl-4 pb-5 pr-5 w-full`}
                  >
                    <div
                      className={`absolute ${
                        conditionIndex === fields.length - 1 ? 'hidden' : '-left-4 top-0 h-[50%]'
                      } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                    ></div>
                    {fields.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeCondition(conditionIndex)}
                        className="group absolute right-6 -top-[9px]"
                      >
                        <span className="group-hover:[&>*_path]:stroke-gray-100">
                          <CloseButtonIcon width="18" height="18" opacity="1" bgColor="#333746" />
                        </span>
                      </button>
                    )}
                    <ToggleSwitch
                      withLabels={{
                        left: t('CreateSegmentPage.andCondtionsOptionLabel'),
                        right: t('CreateSegmentPage.orConditionLabel'),
                      }}
                      value={condition.nestedLogicalOperator ? condition.nestedLogicalOperator : false}
                      onChange={() => changeLogicalOperatorInConditionGroup(conditionIndex)}
                    />
                    <div className="flex justify-end">
                      <div>
                        {condition.nestedConditions.map((nested, nestedIndex) => {
                          const operatorOptions = handleOperatorOptions(
                            condition?.nestedConditions?.[nestedIndex]?.attributeType?.label,
                          );
                          return (
                            <div key={nestedIndex} className="first:mt-4 mb-3">
                              <div
                                className={`absolute ${
                                  nestedIndex === condition.nestedConditions.length - 1
                                    ? 'left-8 bottom-[90px] top-[36px] border-l'
                                    : 'hidden'
                                } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                              ></div>
                              <div
                                className={`relative flex flex-row justify-between items-center w-[50.7rem] py-[7px] pl-[9px] pr-[21px] bg-darkBlue rounded-[7px]`}
                              >
                                <div
                                  className={`absolute ${
                                    nestedIndex === condition.nestedConditions.length - 1
                                      ? 'hidden'
                                      : '-left-4 top-0 h-[50%]'
                                  } w-4 border-b rounded-bl-xl border-custom-conditionGroupBorder`}
                                ></div>
                                <div className="flex flex-row justify-start items-center gap-2">
                                  <div className="cursor-move">
                                    <DragIcon />
                                  </div>
                                  <div>
                                    <SelectField
                                      name={`conditions.${conditionIndex}.nestedConditions.${nestedIndex}.attribute`}
                                      control={control}
                                      placeholder={t('CreateSegmentPage.attributePlaceholder')}
                                      options={attributeOptions}
                                      errorMsg={
                                        errors.conditions?.[conditionIndex]?.nestedConditions?.[nestedIndex]?.attribute
                                          ?.message
                                      }
                                      isSearchable={false}
                                      className="w-[10rem]"
                                      withBorder={true}
                                    />
                                  </div>
                                  <div>
                                    <SelectField
                                      name={`conditions.${conditionIndex}.nestedConditions.${nestedIndex}.attributeType`}
                                      control={control}
                                      placeholder={t('CreateSegmentPage.typePlaceholder')}
                                      options={attributeTypeOptions}
                                      errorMsg={
                                        errors.conditions?.[conditionIndex]?.nestedConditions?.[nestedIndex]
                                          ?.attributeType?.message
                                      }
                                      isSearchable={false}
                                      className="w-[10rem]"
                                      withBorder={true}
                                      customOnChange={() =>
                                        resetOperatorOnAttributeTypeChange(conditionIndex, nestedIndex)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <SelectField
                                      name={`conditions.${conditionIndex}.nestedConditions.${nestedIndex}.operator`}
                                      control={control}
                                      placeholder={t('CreateSegmentPage.operatorPlaceholder')}
                                      options={operatorOptions}
                                      errorMsg={
                                        errors.conditions?.[conditionIndex]?.nestedConditions?.[nestedIndex]?.operator
                                          ?.message
                                      }
                                      isSearchable={false}
                                      className="w-[12.188rem]"
                                      withBorder={true}
                                    />
                                  </div>
                                  <div>
                                    <Input
                                      placeholder={t('CreateSegmentPage.valuePlaceholder')}
                                      {...register(
                                        `conditions.${conditionIndex}.nestedConditions.${nestedIndex}.value`,
                                      )}
                                      error={
                                        errors.conditions?.[conditionIndex]?.nestedConditions?.[nestedIndex]?.value
                                          ?.message
                                      }
                                      withBorder={true}
                                      className="w-[9rem]"
                                      type={nested.attributeType?.label === 'number' ? 'number' : 'text'}
                                    />
                                  </div>
                                </div>
                                {condition.nestedConditions.length > 1 && (
                                  <button
                                    type="button"
                                    onClick={() => removeNestedCondition(conditionIndex, nestedIndex)}
                                    className="group"
                                  >
                                    <span className="group-hover:[&>*_path]:stroke-gray-100">
                                      <CloseButtonIcon />
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <button type="button" onClick={() => addNestedCondition(conditionIndex)} className="group">
                          <span className="group-hover:[&>*_rect]:fill-secondary-400 group-hover:[&>*_path]:stroke-gray-900">
                            <AddPlusIcon />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={`flex flex-row gap-3 mt-[11px] ml-8`}>
        <button type="button" onClick={addCondition} className="group">
          <span className="group-hover:[&>*_rect]:fill-secondary-400 group-hover:[&>*_path]:stroke-gray-900">
            <AddPlusIcon />
          </span>
        </button>
        <button
          type="button"
          className="group flex items-center justify-center w-[24px] h-[24px] rounded-[5px] bg-conditionButtonBg hover:bg-secondary-400"
          onClick={addConditionGroup}
        >
          <span className="group-hover:[&>*_path]:stroke-gray-900">
            <AddGroupIcon />
          </span>
        </button>
      </div>
    </>
  );
};

export default ConditionsScheme;
