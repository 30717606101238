const NoDataPaperPlaneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="90" viewBox="0 0 95 90" fill="none">
      <path
        d="M83.3747 101.477C90.7053 85.6544 87.7627 65.5902 73.6501 54.5464C70.614 52.1717 66.493 49.7377 62.5205 51.1558C59.1511 52.3601 57.436 57.1054 59.92 59.9192C61.0365 61.1822 62.7435 61.8334 64.4288 61.8513C70.9219 61.9153 74.8667 55.2536 75.906 49.6348C78.4204 36.062 67.4846 21.8414 53.4886 21.4354C46.6075 21.2329 40.6317 25.1518 36.6168 30.5183C31.2633 37.677 28.3705 46.4978 27.8731 55.3862C27.781 57.0561 28.3433 58.5233 28.3433 60.5521"
        stroke="#9BEBC6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeDasharray="5 5"
      />
      <path
        d="M31.0893 81.32C31.7637 81.2795 32.3562 80.8692 32.6378 80.2527L39.2147 65.8861C39.519 65.2419 39.4226 64.5159 38.9819 63.9621C38.5337 63.3982 37.548 63.1649 36.8643 63.3818L31.557 65.0666C31.0128 65.2395 30.659 65.7638 30.7033 66.334L31.2899 74.018C31.3266 74.4109 31.0308 74.7535 30.6366 74.7745C30.2496 74.8016 29.9121 74.5102 29.8754 74.1172L29.2942 66.4254C29.2514 65.8551 28.8231 65.3857 28.2585 65.2905L22.7374 64.3527C22.0848 64.2375 21.4585 64.4692 21.0394 64.9544C20.9901 65.0116 20.9347 65.0758 20.8925 65.1391C20.4836 65.7578 20.4821 66.5289 20.879 67.1581L29.4076 80.4658C29.7664 81.0373 30.4149 81.3606 31.0893 81.32Z"
        fill="#9BEBC6"
      />
    </svg>
  );
};

export default NoDataPaperPlaneIcon;
