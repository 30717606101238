import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';

import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';

type SelectBgColor = 'light' | 'dark';
type MultiValueChipColor = 'green' | 'red';
interface SelectFieldProps {
  name: string;
  label?: string | React.ReactElement;
  control: any;
  options:
    | { label: string; value: string }[]
    | { label?: string; options: { label: string; value: string }[] }[]
    | undefined;
  errorMsg: string | undefined;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  isMulti?: true | undefined;
  bgColor?: SelectBgColor;
  multiValueChipColor?: MultiValueChipColor;
  withBorder?: boolean;
  customOnChange?: (...args: any[]) => void;
  value?: any;
  isClearable?: boolean;
}

export interface OptionType {
  label: string;
  value: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  control,
  options,
  errorMsg,
  placeholder,
  className,
  disabled = false,
  isSearchable = true,
  isMulti = undefined,
  bgColor = 'light',
  withBorder = false,
  multiValueChipColor = 'green',
  customOnChange,
  value,
  isClearable = false,
}) => {
  const { t } = useTranslation();
  const backgroundColor = bgColor === 'light' ? '#272933' : '#1F212A';

  const customSelectStyles: StylesConfig<OptionType, true> = {
    control: (baseStyles, { isFocused, hasValue }) => ({
      ...baseStyles,
      borderColor: errorMsg
        ? '#D81212'
        : `${hasValue ? '#47546b' : isFocused ? '#7898FB' : withBorder ? '#47546b' : 'transparent'}`,
      backgroundColor: `${backgroundColor}`,
      boxShadow: 'none',
      '&:hover': {
        outline: 'none',
        borderColor: isFocused ? '7898FB' : '#313b4b',
      },
      cursor: 'pointer',
      borderRadius: '0.5rem',
      minHeight: '3rem',
      color: 'yellow',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: `${backgroundColor}`,
      border: '1px solid #313b4b',
      minWidth: 'fit-content',
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: `${backgroundColor}`,
      borderRadius: '8px',
      padding: '8px',
      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#272933',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
    option: (baseStyles, { isFocused, isSelected }) => ({
      ...baseStyles,
      backgroundColor: isSelected ? 'rgba(130, 230, 184, 0.10)' : 'transparent',
      color: isSelected ? '#82E6B8' : '#f5f7fc',
      '&:hover': {
        backgroundColor: isFocused
          ? isSelected
            ? 'rgba(130, 230, 184, 0.10)'
            : 'rgba(49, 59, 75, 0.50)'
          : `${backgroundColor}`,
      },
      cursor: 'pointer',
      fontWeight: '400',
      fontSize: '0.875rem',
      borderRadius: '0.25rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      marginBottom: '2px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#f5f7fc',
      fontSize: '0.875rem',
      paddingLeft: '2px',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#7085a8',
        fontWeight: '400',
        fontSize: '0.875rem',
        paddingLeft: '2px',
      };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#7085a8',
      paddingRight: '16px',
      '&:hover': {
        color: '#7085a8',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#7085a8',
      '&:hover': {
        color: '#7085a8',
      },
    }),
    input: (base) => ({
      ...base,
      color: '#f5f7fc',
      fontWeight: '400',
      fontSize: '0.875rem',
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      color: '#181A1E',
      backgroundColor: multiValueChipColor === 'green' ? '#82E6B8' : '#ff9296',
      padding: '4px',
      borderRadius: '50px',
      fontWeight: '500',
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      color: '#1C222A',
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
      '&>svg': {
        width: '18px',
        height: '18px',
        stroke: multiValueChipColor === 'green' ? '#82E6B8' : '#ff9296',
        strokeWidth: '1px',
      },
    }),
  };

  const NoOptionsMessage = () => {
    return (
      <div className="flex flex-col gap-4 items-center justify-center w-full h-full mt-6 mb-6">
        <h2 className="text-gray-50 text-center text-xl font-semibold">{t('SelectField.noResultsLabel')}</h2>
        <p className="text-gray-300 text-sm font-normal tracking-[0.28px] text-center">
          {t('SelectField.noResultsMsg')}
        </p>
      </div>
    );
  };

  return (
    <div className={`${disabled && 'opacity-50 pointer-events-none'}`}>
      {label && (
        <p className={`${errorMsg ? 'text-errorRed-500' : 'text-gray-100'} text-xs font-medium mb-2`}>{label}</p>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value: controllerValue, name, ref } }) => (
          <>
            <Select
              onBlur={onBlur}
              name={name}
              ref={ref}
              value={value !== undefined ? value : controllerValue}
              onChange={(e, action) => {
                onChange(e);
                customOnChange && customOnChange(e, action);
              }}
              className={className}
              styles={customSelectStyles}
              options={options}
              isSearchable={isSearchable}
              components={{
                IndicatorSeparator: () => null,
                NoOptionsMessage,
              }}
              isMulti={isMulti}
              isClearable={isClearable}
              placeholder={<>{placeholder ? placeholder : t('SelectField.selectPlaceholder')}</>}
            />
            {errorMsg && (
              <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
                <CircleWarningIcon /> {errorMsg}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default SelectField;
