import { useMutation } from 'react-query';
import axios from 'axios';

import { CreateInquiryRequest } from '@/models/Inquiry';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createInquiry(data: CreateInquiryRequest) {
  const response = await axios.post<CreateInquiryRequest>(`${AwsConfigApiGateway.adminApiUrl}/inquiry`, data, {
    headers: {
      'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
    },
  });
  return response.data;
}

export function useCreateInquiry() {
  return useMutation<CreateInquiryRequest, Error, CreateInquiryRequest>((data) => createInquiry(data));
}
