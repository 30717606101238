import { useMemo } from 'react';

const AXIS_COLOR = '#fff';

const YAxisComponent = ({ maxValue, numberOfTicks = 4 }: { maxValue: number; numberOfTicks?: number }) => {
  const values = useMemo(() => {
    let numberOfTicksToCreate = maxValue <= numberOfTicks ? maxValue : numberOfTicks;
    const vals = [];

    const mainMultiplier = maxValue / numberOfTicks;
    if (maxValue > 0) {
      while (numberOfTicksToCreate > 0) {
        const tickResult = maxValue <= numberOfTicks ? numberOfTicksToCreate : mainMultiplier * numberOfTicksToCreate;
        vals.push(tickResult.toFixed(0));
        numberOfTicksToCreate--;
      }
    }

    vals.push('0');

    return vals;
  }, [numberOfTicks, maxValue]);

  return (
    <div
      className="flex flex-col justify-between items-end mb-10"
      style={{ borderRight: `1px solid ${AXIS_COLOR}`, marginLeft: 10 }}
    >
      {values.map((value) => (
        <div style={{ color: AXIS_COLOR, marginRight: -2, marginBottom: -2.2 }} key={value}>
          {value} -
        </div>
      ))}
    </div>
  );
};

export default YAxisComponent;
