import { SVGProps } from 'react';

interface AppleIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

function AppleIcon({ color = '#ffffff', ...props }: AppleIconProps) {
  return (
    <svg width="60" height="80" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7275 20.8818C26.6779 15.904 30.7867 13.5165 30.9704 13.3959C28.6612 10.0196 25.0637 9.55633 23.7833 9.50361C20.7241 9.1929 17.811 11.3056 16.2591 11.3056C14.7103 11.3056 12.3125 9.54914 9.77564 9.59387C6.43847 9.64339 3.36249 11.5348 1.64519 14.5221C-1.82058 20.5343 0.758576 29.4443 4.13568 34.3239C5.78669 36.709 7.75561 39.3927 10.3388 39.2945C12.8285 39.1963 13.7678 37.6842 16.7775 37.6842C19.7871 37.6842 20.6322 39.2945 23.2657 39.2442C25.9431 39.1955 27.6404 36.8136 29.2779 34.4189C31.1733 31.6481 31.9537 28.9651 32 28.8293C31.9417 28.8022 26.781 26.8253 26.7275 20.8818Z"
        fill={color}
      />
      <path
        d="M21.7794 6.27416C23.1492 4.61117 24.0774 2.30039 23.825 0C21.8481 0.0798748 19.455 1.31394 18.0356 2.97534C16.764 4.44982 15.6522 6.79734 15.9501 9.0562C18.1555 9.22714 20.4047 7.93396 21.7794 6.27416Z"
        fill={color}
      />
    </svg>
  );
}

export default AppleIcon;
