interface FacebookIconProps {
  color?: string;
}

export default function FacebookIcon({ color = 'white' }: FacebookIconProps) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86207 0C4.4154 0 0 4.4154 0 9.86207V24.1379C0 29.5846 4.4154 34 9.86207 34H24.1379C29.5846 34 34 29.5846 34 24.1379V9.86207C34 4.4154 29.5846 0 24.1379 0H9.86207ZM22.4892 14.0528L21.818 18.4148H18.2901V28.9597C17.5192 29.0802 16.7292 29.143 15.9243 29.143C15.1195 29.143 14.3294 29.0802 13.5586 28.9597V18.4148H9.71429V14.0528H13.5586V10.7283C13.5586 6.9463 15.819 4.85726 19.2775 4.85726C20.934 4.85726 22.6667 5.15199 22.6667 5.15199V8.86558H20.7575C18.8766 8.86558 18.2901 10.0288 18.2901 11.2221V14.0528H22.4892Z"
        fill={color}
        fillOpacity="0.58"
      />
    </svg>
  );
}
