/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import React from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import ReactTimeAgo from 'react-time-ago';
import { createColumnHelper } from '@tanstack/react-table';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { useDeleteSegmentById } from '@/api/segments/useDeleteSegment';
import { useAppSelector } from '@/app/hooks';
import Badge from '@/component-library/primitives/Badge/Badge';
import ArrowDownUpIcon from '@/component-library/primitives/Icons/ArrowDownUpIcon/ArrowDownUpIcon';
import DataFlowIcon from '@/component-library/primitives/Icons/DataFlowIcon/DataFlowIcon';
import EditPencilIcon from '@/component-library/primitives/Icons/EditPencilIcon/EditPencilIcon';
import NoteSearchIcon from '@/component-library/primitives/Icons/NoteSearchIcon/NoteSearchIcon';
import SettingsFutureIcon from '@/component-library/primitives/Icons/SettingsFutureIcon/SettingsFutureIcon';
import TrashIcon from '@/component-library/primitives/Icons/TrashIcon/TrashIcon';
import UsersIcon from '@/component-library/primitives/Icons/UsersIcon/UsersIcon';
import Modal from '@/component-library/primitives/Modal/Modal';
import { ProvideToasts, Toast, ToastType } from '@/component-library/primitives/Toast/Toast';
import { Segment } from '@/models/Segments';
import { HttpMethodsEnum, PermissionsEnum } from '@/modules/shared/enums/enums';
import usePermissionChecker from '@/modules/shared/hooks/usePermissionChecker';

import queryKeys from '../../shared/query/queryKeys';
import { selectCurrentWorkspace } from '../Workspaces/workspaceSlice';

export const useSegmentsTable = () => {
  TimeAgo.setDefaultLocale(en.locale);
  TimeAgo.addLocale(en);
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Segment>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteSegmentMutation = useDeleteSegmentById();
  const [deletingSegmentId, setDeletingSegmentId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const workspace = useAppSelector(selectCurrentWorkspace);

  const isActionAllowed = usePermissionChecker(PermissionsEnum.SEGMENTS, HttpMethodsEnum.PUT);
  const [open, setOpen] = useState(false);
  const [toastInfo, setToastInfo] = useState({ message: '', toastType: '' });

  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const dropdownRefs: { [rowId: string]: React.RefObject<HTMLDivElement> } = {};

  const handleDropdownClick = (event: React.MouseEvent, rowId: string) => {
    event.stopPropagation();

    if (openDropdownId === rowId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(rowId);
    }
  };

  const handleDeleteModal = (state: boolean) => {
    // @ts-ignore
    if (!document.startViewTransition) {
      setShowDeleteModal(state);
    } else {
      // @ts-ignore
      document.startViewTransition(() => {
        flushSync(() => {
          setShowDeleteModal(state);
        });
      });
    }
  };

  const handleOnEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    console.log('handleOnEdit');
  };

  const handleOnDelete = (e: React.MouseEvent, segmentId: string | null) => {
    e.stopPropagation();
    if (!isActionAllowed) {
      setToastInfo({ message: t('CommonErrors.actionNotAllowed'), toastType: 'error' });
      setOpen(true);
      return;
    }
    setDeletingSegmentId(segmentId);
    handleDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (deletingSegmentId) {
      deleteSegmentMutation.mutate(deletingSegmentId, {
        onSuccess: () => {
          setTimeout(() => {
            queryClient.setQueryData<Segment[]>(
              [queryKeys.segments, workspace?.id],
              (prevData) => prevData?.filter((segment) => segment.id !== deletingSegmentId) || [],
            );
            setToastInfo({ message: t('SegmentsPage.toastDeleteSuccess'), toastType: 'success' });
            setOpen(true);
          }, 1000);
        },
        onError: () => {
          // TODO: handle error here, like showing an error message
          console.log('Error deleting segment');
        },
        onSettled: () => {
          setDeletingSegmentId(null);
        },
      });
    }
    handleDeleteModal(false);
  };

  useEffect(() => {
    const dropdownRefKeys = Object.keys(dropdownRefs);

    const closeDropdownsExceptCurrent = (currentRowId: string | null) => {
      dropdownRefKeys.forEach((rowId) => {
        if (rowId === currentRowId) {
          setOpenDropdownId(null);
        }
      });
    };

    const handleOutsideClick = () => {
      closeDropdownsExceptCurrent(openDropdownId);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdownId]);

  const columns = [
    columnHelper.accessor('id', {
      header: () => <div>{t('SegmentsPage.idColumn')}</div>,
      cell: (info) => <div className="h-full mt-6 block items-start truncate max-w-[8rem]">{info.renderValue()}</div>,
    }),
    columnHelper.accessor('segmentType', {
      header: () => <div>{t('SegmentsPage.typeColumn')}</div>,
      cell: (info) => (
        <div className="h-full mt-6 block items-start">
          <div className="flex flex-row items-center gap-2">
            {info.row.original.segmentType === 'manual' ? <SettingsFutureIcon /> : <DataFlowIcon color="#9BAFD0" />}
            <span className="first-letter:uppercase text-gray-50">{info.renderValue()}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('name', {
      header: () => (
        <div className="flex flex-row items-center gap-1">
          <span>{t('SegmentsPage.nameColumn')}</span>
          <span className="cursor-pointer">
            <ArrowDownUpIcon />
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="h-full mt-6 block items-start">
          <div className="flex flex-col gap-y-1">
            <div className="flex flex-row items-center gap-2">
              <span className="text-primary-400 font-bold">{info.renderValue()}</span>
              <NoteSearchIcon />
            </div>
            {info.row.original.description && (
              <span className="text-gray-100 text-xs block truncate max-w-[22rem]">
                {info.row.original.description}
              </span>
            )}
            <div className="flex flex-row gap-x-1 text-gray-400 text-xs truncate max-w-[35rem]">
              <span>{t('SegmentsPage.createdLabel')}</span>
              <span>
                on{' '}
                {new Date(info.row.original.createdAt as string).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
              <span>
                at{' '}
                {new Date(info.row.original.createdAt as string).toLocaleTimeString('en-us', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
              <span> | </span>
              <span>{t('SegmentsPage.lastEditedLabel')}</span>
              <span className="truncate">
                <ReactTimeAgo date={new Date((info.row.original.lastModifiedAt as string) ?? 0)} locale="en-US" />
              </span>
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('peopleCount', {
      header: () => <div>{t('SegmentsPage.peopleCountColumn')}</div>,
      cell: (info) => (
        <div className="h-full mt-6 block items-start">
          <div className="flex flex-row items-center gap-2">
            <UsersIcon color="#9BAFD0" />
            <span className="text-gray-50">{info.renderValue() ?? 0}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('usage', {
      header: () => <div>{t('SegmentsPage.usageColumn')}</div>,
      cell: (info) => (
        <div className="h-full mt-6 block items-start">
          <div className="flex flex-row items-center gap-2">
            <Badge
              number={(info.row.original.usage?.singleSends && info.row.original.usage?.singleSends.length) ?? 0}
              className={`${
                (info.row.original.usage?.singleSends && info.row.original.usage?.singleSends.length) ?? 0 > 0
                  ? 'bg-yellow-500'
                  : '!bg-gray-400'
              } text-gray-900 text-[10px] font-medium !h-[1.5rem] !w-[1.5rem]`}
            />
            <span className="text-gray-50">{t('SegmentsPage.singleSendsLabel')}</span>
          </div>
          <div className="flex flex-row items-center gap-2 mt-2">
            <Badge
              number={(info.row.original.usage?.campaigns && info.row.original.usage?.campaigns.length) ?? 0}
              className={`${
                (info.row.original.usage?.campaigns && info.row.original.usage?.campaigns.length) ?? 0 > 0
                  ? 'bg-yellow-500'
                  : '!bg-gray-400'
              } text-gray-900 text-[10px] font-medium !h-[1.5rem] !w-[1.5rem]`}
            />
            <span className="text-gray-50">{t('SegmentsPage.campaignsLabel')}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'actions',
      header: () => <div></div>,
      cell: (info) => {
        const rowId = info.row.original.id;
        dropdownRefs[rowId] = React.createRef();

        return (
          <div className="relative h-full mt-6 block items-start" ref={dropdownRefs[rowId]}>
            <div className="flex items-center justify-end">
              <div
                onClick={(e) => handleDropdownClick(e, rowId)}
                className="cursor-pointer text-[36px] font-normal text-gray-400 flex items-center justify-center h-8 w-8 hover:bg-actionButtonHoverBG hover:rounded-md hover:text-white active:bg-actionButtonHoverBG active:rounded-md active:text-white"
              >
                <span className="pb-5">...</span>
              </div>
            </div>
            {openDropdownId === rowId && (
              <div className="absolute right-0 z-10 p-2 pt-2 pb-2 px-2 shadow-md w-40 rounded-lg bg-custom-darkBlue border-[0.063rem] border-gray-800">
                <div
                  key={'0'}
                  className="group/item flex items-center gap-2 px-2 py-3 mb-1 cursor-pointer rounded-lg hover:bg-activeSideMenuOption hover:text-primary-400 text-gray-200"
                  onClick={(e) => handleOnEdit(e)}
                >
                  <span className="group-hover/item:[&>*_path]:stroke-primary-400">
                    <EditPencilIcon />
                  </span>
                  <span className="font-normal">{t('SegmentsPage.linkEdit')}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <path d="M0 1H145.878H147" stroke="#313B4B" />
                </svg>
                <div
                  key={'1'}
                  className="group/item flex items-center gap-2 px-2 py-3 mt-1 cursor-pointer rounded-lg hover:bg-deleteButtonHoverBG text-red-400"
                  onClick={(e) => handleOnDelete(e, info.renderValue())}
                >
                  <span>
                    <TrashIcon color="#ff9296" />
                  </span>
                  <span className="font-normal">{t('SegmentsPage.linkDelete')}</span>
                </div>
              </div>
            )}
            <ProvideToasts>
              <Toast
                isOpen={open}
                setOpen={setOpen}
                message={toastInfo.message}
                toastType={toastInfo.toastType as ToastType}
              />
            </ProvideToasts>
          </div>
        );
      },
    }),
  ];

  const deleteConfirmationModal = (
    <Modal
      isOpen={showDeleteModal}
      title={t('SegmentsPage.modalTitle')}
      body={t('SegmentsPage.modalBody')}
      cancelBtnTxt={t('SegmentsPage.modalCancelBtn')}
      confirmBtnTxt={t('SegmentsPage.modalConfirmBtn')}
      onCancel={() => handleDeleteModal(false)}
      onConfirm={handleConfirmDelete}
    />
  );

  return { columns, deleteConfirmationModal };
};
