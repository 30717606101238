interface PageNumberProps {
  number: string | number;
  className?: string;
  onClick?: () => void;
}

const PageNumber: React.FC<PageNumberProps> = ({ number, className, onClick }) => {
  const pageNumberClasses = `flex items-center justify-center w-6 h-6 rounded-full text-sm text-gray-400 cursor-pointer font-montserrat ${className}`;

  return (
    <div className={pageNumberClasses} onClick={onClick}>
      {number}
    </div>
  );
};

export default PageNumber;
