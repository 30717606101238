import { SVGProps } from 'react';

interface TextAlignIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const TextAlignIcon: React.FC<TextAlignIconProps> = ({ color = '#7898FB' }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9.5H8.5M1 6.5H13M1 3.5H8.5M1 0.5H13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TextAlignIcon;
