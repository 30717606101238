import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Button } from '@/component-library/primitives/Button/Button';
import InfoIcon from '@/component-library/primitives/Icons/InfoIcon/InfoIcon';
import { Template } from '@/models/TemplatesByTenant';

interface ModalProps {
  isOpen: boolean;
  template: Template | undefined;
  templateData: string;
  onCancel: () => void;
  setTemplateInUse: (value: Template | undefined) => void;
  setHtmlContent: (value: string) => void;
  setShowEmailTemplateModal: (value: boolean) => void;
}

const EmailTemplateModal: React.FC<ModalProps> = ({
  isOpen,
  template,
  templateData,
  onCancel,
  setTemplateInUse,
  setHtmlContent,
  setShowEmailTemplateModal,
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div
      className="fixed overflow-auto inset-0 flex flex-col items-center justify-center z-50 bg-modalOpacityBG backdrop-blur-[10px]"
      onClick={onCancel}
    >
      <div className="absolute 3xl:top-14 lg:top-7 flex flex-row items-center gap-3">
        <span>
          <InfoIcon color="#DDE4F3" />
        </span>
        <span className="font-normal text-xs leading-[14px] tracking-[0.02em] text-gray-200">
          {t('NewEmailPage.tapAnywhereToExitLabel')}
        </span>
      </div>
      <div className=" min-w-[50rem] h-fit absolute top-[15%]">
        <div className="flex w-full items-center justify-between mb-4">
          <h1 className="text-white font-normal text-2xl leading-10">{template?.name}</h1>
          <Button
            type="button"
            className="!w-[7.063rem]"
            onClick={(e) => {
              e.stopPropagation();
              setTemplateInUse(template);
              setHtmlContent(templateData);
              setShowEmailTemplateModal(false);
            }}
          >
            {t('NewEmailPage.buttonUse')}
          </Button>
        </div>
        <div className="bg-white w-full h-full p-6 rounded-lg relative overflow-auto [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-gray-200 [&::-webkit-scrollbar-thumb]:bg-gray-600">
          <div className="mt-6 editor-preview text-gray-300">{parse(templateData)}</div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateModal;
