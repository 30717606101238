import { useMutation } from 'react-query';
import axios from 'axios';

import { useAppSelector } from '@/app/hooks';
import { CreateMemberDetailsRequest } from '@/models/WorkspaceDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { selectCurrentWorkspace } from '@/modules/Dashboard/Workspaces/workspaceSlice';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function createMember(data: CreateMemberDetailsRequest, workspaceId: string, token: string | undefined) {
  const response = await axios.post<CreateMemberDetailsRequest>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces/${workspaceId}/audience`,
    data,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useCreateMember() {
  const { user } = useAuth();
  const workspace = useAppSelector(selectCurrentWorkspace);
  return useMutation<CreateMemberDetailsRequest, Error, CreateMemberDetailsRequest>((data) =>
    createMember(data, workspace?.id ?? '', user?.signInUserSession.idToken.jwtToken),
  );
}
