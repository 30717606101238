import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export interface SideMenuState {
  isCollapsed: boolean;
  isCollapsableIconHidden: boolean;
}

const initialState: SideMenuState = {
  isCollapsed: false,
  isCollapsableIconHidden: false,
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    setCollapsedSidebar: (state, action) => {
      state.isCollapsed = action.payload;
    },
    setIsCollapsableIconHidden: (state, action) => {
      state.isCollapsableIconHidden = action.payload;
    },
  },
});

export const { setCollapsedSidebar, setIsCollapsableIconHidden } = sideMenuSlice.actions;
export const selectIsCollapsed = (state: RootState) => state.sideMenu.isCollapsed;
export const selectIsCollapsableIconHidden = (state: RootState) => state.sideMenu.isCollapsableIconHidden;
export default sideMenuSlice.reducer;
