import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppleIcon from '@/component-library/primitives/Icons/AppleIcon/AppleIcon';
import ChevronIcon from '@/component-library/primitives/Icons/ChevronIcon/ChevronIcon';
import { AppRoutes } from '@/utils/routes/router';

const PushNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center mt-12">
        <div>
          <div className="position: relative">
            <div
              className="position: absolute top-0.5 -left-10 cursor-pointer"
              onClick={() => navigate(AppRoutes.settings)}
            >
              <ChevronIcon type={'left'} width={'24'} height={'24'} viewBox={'0 0 24 24'} />
            </div>
            <h1 className="text-white font-medium text-xl mb-2">{t('PushNotificationsPage.title')}</h1>
          </div>
          <p className="text-gray-300 text-sm mb-8">{t('PushNotificationsPage.subtitle')}</p>
          <div className="flex flex-row gap-x-4 justify-between w-[40rem]">
            <div
              className="w-[50%] h-[150px] rounded-xl cursor-pointer flex items-center justify-start pl-10"
              style={{
                backgroundImage: `url('/images/ios_bg.svg')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              onClick={() => navigate(AppRoutes.pushNotificationsEnableIos)}
            >
              <div className="flex flex-row gap-x-8 items-center justify-center">
                <AppleIcon />
                <span className="text-[3rem] text-white mt-2">iOS</span>
              </div>
            </div>
            <div
              className="w-[50%] h-[9.375rem] rounded-xl cursor-pointer flex items-center justify-start pl-10"
              style={{
                backgroundImage: `url('/images/android_bg.jpg')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              onClick={() => navigate(AppRoutes.pushNotificationsEnableAndroid)}
            >
              <div className="flex flex-row gap-x-8 items-center justify-center">
                <img className="w-16 h-20" src="/images/androidLogo.png" alt="android" />
                <img className="w-[7.5rem]" src="/images/android_txt.png" alt="android" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PushNotifications;
