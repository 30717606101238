import { configureStore } from '@reduxjs/toolkit';

import sideMenuSlice from '@/component-library/layouts/SideMenu/sideMenuSlice';

import workspaceSlice from '../modules/Dashboard/Workspaces/workspaceSlice';

import { rolePermissionsSlice } from './slices/permissionsSlice';

const store = configureStore({
  reducer: {
    workspaces: workspaceSlice,
    sideMenu: sideMenuSlice,
    rolePermissions: rolePermissionsSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
