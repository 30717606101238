import { SVGProps } from 'react';

interface InformationIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const InformationIcon: React.FC<InformationIconProps> = ({ color = '#EFEAFD', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.70794 6.075L6.68394 6.454L6.57594 6.956L7.17094 7.064C7.55794 7.157 7.63494 7.296 7.55094 7.683L6.57594 12.26C6.32094 13.443 6.71594 14 7.64294 14C8.36294 14 9.19694 13.668 9.57594 13.211L9.69194 12.662C9.42894 12.894 9.04194 12.987 8.78694 12.987C8.42394 12.987 8.29294 12.732 8.38494 12.283L9.70794 6.075ZM9.79894 3.32C9.79894 3.67009 9.65987 4.00583 9.41232 4.25338C9.16478 4.50093 8.82903 4.64 8.47894 4.64C8.12886 4.64 7.79311 4.50093 7.54556 4.25338C7.29801 4.00583 7.15894 3.67009 7.15894 3.32C7.15894 2.96991 7.29801 2.63417 7.54556 2.38662C7.79311 2.13907 8.12886 2 8.47894 2C8.82903 2 9.16478 2.13907 9.41232 2.38662C9.65987 2.63417 9.79894 2.96991 9.79894 3.32Z"
        fill={color}
      />
    </svg>
  );
};

export default InformationIcon;
