import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import CircleWarningIcon from '@/component-library/primitives/Icons/CircleWarningIcon/CircleWarningIcon';

import './styles.css';
import 'react-phone-input-2/lib/material.css';

export type PhoneCodeInputSize = 'large' | 'small';
interface PhoneCodeInputProps {
  placeholder: string;
  name: string;
  error?: string;
  control: any;
  size?: PhoneCodeInputSize;
  className?: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
}

export const PhoneCodeInput = ({
  error,
  control,
  placeholder,
  name,
  size = 'small',
  className,
  label,
  optional = false,
  disabled = false,
}: PhoneCodeInputProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col relative">
      {label && (
        <label
          className={`${error ? 'text-errorRed-500' : 'text-gray-100'} text-xs font-medium mb-2  ${
            disabled && 'opacity-50'
          }`}
        >
          {label}
          {optional && (
            <span className="text-gray-400 text-xs font-medium ml-1">({t('InputField.optionalLabel')})</span>
          )}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            specialLabel=""
            placeholder={placeholder}
            value={value}
            onChange={(phone) => onChange(`+${phone}`)}
            disabled={disabled}
            country="us"
            inputStyle={{
              position: 'relative',
              opacity: `${disabled ? '0.5' : '1'}`,
              border: `1px solid transparent`,
              borderRadius: '8px',
              width: '100%',
              padding: '12px 16px 12px 105px',
              background: '#272933',
              fontSize: '14px',
              color: '#EFF3FA',
              fontFamily: 'Poppins',
              fontWeight: '400',
              height: `${size === 'small' ? '48px' : '56px'}`,
              boxShadow: 'none',
              outline: 'none',
            }}
            inputClass={`focus:!border-secondary-400 hover:!border-gray-800 ${
              value && '!border-gray-600 hover:!border-gray-600'
            } ${
              disabled && 'hover:!border-gray-600'
            } placeholder:focus:!text-transparent placeholder:!text-gray-500 placeholder:!tracking-[0.018rem] autofill:!shadow-[inset_0_0_0px_1000px_#272933] autofill:![-webkit-text-fill-color:#f5f7fc]
            autofill:![caret-color:white] ${error && '!border-errorRed-500 hover:!border-errorRed-500'} ${className}`}
            buttonStyle={{
              borderRight: '1px solid #788395',
              paddingRight: '32px',
              paddingLeft: '2px',
              height: '24px',
              marginTop: '12px',
            }}
            dropdownStyle={{
              backgroundColor: '#2C323F',
              color: '#F5F7FC',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '14px',
              marginTop: '30px',
              padding: '8px',
            }}
            dropdownClass={`[&::-webkit-scrollbar]:!hidden [-ms-overflow-style:!none] [scrollbar-width:!none]`}
          />
        )}
      />
      {error && (
        <p className="text-errorRed-500 mt-2 text-xs font-medium flex flex-row items-center gap-2">
          <CircleWarningIcon /> {error}
        </p>
      )}
    </div>
  );
};
