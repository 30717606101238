import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetActivityLogsByWorkspace } from '@/api/workspaces/useGetActivityLogsByWorkspace';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { ActivityLog } from '@/models/ActivityLogsByWorkspace';

import { useActivityLogsTable } from './useActivityLogsTable';

const ActivityLogs = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetActivityLogsByWorkspace();
  const columns = useActivityLogsTable();
  const [filterValue, setFilterValue] = useState('');

  function sortFunction(a: ActivityLog, b: ActivityLog) {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA > dateB ? -1 : 1;
  }

  data?.sort(sortFunction);

  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase()),
      ),
    );
  }, [data, filterValue]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField initialValue={filterValue} setSearchValue={setFilterValue} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : filteredData && filteredData.length > 0 ? (
        <Table
          columns={columns}
          data={filteredData ?? []}
          className={{
            row: 'h-[3.300rem]',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard description={t('NoDataBoard.startUsingOurSDK')} />
      )}
    </div>
  );
};

export default ActivityLogs;
