import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Button } from '@/component-library/primitives/Button/Button';
import ExpandIcon from '@/component-library/primitives/Icons/ExpandIcon/ExpandIcon';
import { Template } from '@/models/TemplatesByTenant';

interface SingleTemplateProps {
  template: Template;
  id: number;
  templateInUse: Template | undefined;
  setTemplateInUse: (value: Template | undefined) => void;
  setHtmlContent: (value: string) => void;
  setSelectedTemplate: (value: Template | undefined) => void;
  setSelectedTemplateHtmlContent: (value: string) => void;
  setShowEmailTemplateModal: (value: boolean) => void;
}

const SingleTemplate: React.FC<SingleTemplateProps> = ({
  template,
  templateInUse,
  id,
  setTemplateInUse,
  setHtmlContent,
  setSelectedTemplate,
  setSelectedTemplateHtmlContent,
  setShowEmailTemplateModal,
}) => {
  const { t } = useTranslation();
  return (
    <section
      className={`group mb-4 break-inside-avoid w-[12.375rem] min-h-fit px-3 pt-3 pb-6 rounded-[10px] hover:opacity-100 bg-templateBG ${
        templateInUse && templateInUse.id !== template.id ? 'opacity-50' : ''
      }`}
    >
      <div
        id={`html-template-${id}`}
        className="hidden absolute bg-white !text-black editor-preview p-5 overflow-visible !w-[50rem] !min-h-[50rem]"
      >
        {parse(template.content)}
      </div>
      <div id={`image-${id}`} className="border-2 border-gray-700 rounded-lg max-h-[100%] overflow-clip relative">
        <div className="hidden w-full h-full group-hover:flex group-hover:absolute group-hover:bg-templateHoverBG group-hover:backdrop-blur-[1px] items-center justify-center">
          <Button
            type="button"
            className="!w-[7.063rem]"
            onClick={() => {
              setTemplateInUse(template);
              setHtmlContent(template.content);
            }}
          >
            {t('NewEmailPage.buttonUse')}
          </Button>
        </div>
      </div>
      <div className="flex flex-Frow justify-between mt-4">
        <h2 className="text-white text-sm font-normal leading-[14px] tracking-[0.02em] max-w-[80%]">{template.name}</h2>
        <button
          type="button"
          className="group/expand"
          onClick={() => {
            setSelectedTemplate(template);
            setSelectedTemplateHtmlContent(template.content);
            setShowEmailTemplateModal(true);
          }}
        >
          <span className="group-hover/expand:[&>*_path]:stroke-gray-100">
            <ExpandIcon />
          </span>
        </button>
      </div>
    </section>
  );
};

export default SingleTemplate;
