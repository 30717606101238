import { useTranslation } from 'react-i18next';

import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import { Input } from '@/component-library/widgets/Input/Input';

import NoDataSection from '../NoDataSection';

interface AttributesSectionProps {
  memberAttributes: { key: string; value: string }[] | undefined;
  changeActiveStep: (step: string) => void;
}

const AttributesSection: React.FC<AttributesSectionProps> = ({ memberAttributes, changeActiveStep }) => {
  const { t } = useTranslation();
  return (
    <div className="px-5 pt-5 pb-7 w-full min-h-[40rem] max-h-[40rem] rounded-[10px] bg-darkBlue">
      <div className="flex flex-row items-center justify-between mb-8">
        <h2 className="text-gray-50 text-base font-normal tracking-[0.32px]">
          {t('MemberProfilePage.attributesLabel')}
        </h2>
        <button
          onClick={() => changeActiveStep(t('MemberProfilePage.attributesTabLabel'))}
          className="group hover:bg-iconButtonHoverBG rounded-lg w-8 h-8 flex items-center justify-center"
        >
          <span className="group-hover:[&>*_path]:stroke-gray-400">
            <ChevronNewIcon type="right" color="white" />
          </span>
        </button>
      </div>
      <div className="h-[33rem] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {memberAttributes &&
          memberAttributes.length > 0 &&
          memberAttributes.map((attribute, id) => {
            return (
              <div key={id} className="relative mb-4">
                <div className="flex flex-row gap-1 w-full items-end justify-center">
                  <Input
                    className="w-full"
                    value={attribute.key}
                    label={t('MemberProfilePage.attributeNameLabel')}
                    disabled={true}
                  />
                  <div className="text-gray-400 text-sm font-normal opacity-50 h-12 flex items-center">-</div>
                  <Input
                    className="w-full"
                    value={attribute.value}
                    label={t('MemberProfilePage.valueLabel')}
                    disabled={true}
                  />
                </div>
              </div>
            );
          })}
        {memberAttributes && memberAttributes.length < 1 && (
          <div className="h-[18rem]">
            <NoDataSection />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttributesSection;
