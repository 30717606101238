import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetDomains } from '@/api/settings/useGetDomains';
import { Button } from '@/component-library/primitives/Button/Button';
import Loader from '@/component-library/primitives/Loader/Loader';
import NoDataBoard from '@/component-library/widgets/NoDataBoard/NoDataBoard';
import SearchField from '@/component-library/widgets/SearchField/SearchField';
import Table from '@/component-library/widgets/Table/Table';
import { AppRoutes } from '@/utils/routes/router';

import { useDomainsTable } from './useDomainsTable';

const Domains = () => {
  const { data, isLoading } = useGetDomains();
  const { columns } = useDomainsTable();
  const [filterValue, setFilterValue] = useState('');

  const filteredData = useMemo(() => {
    return data?.filter((row) =>
      Object.values(row).some(
        (value) => value !== null && value.toString().toLowerCase().includes(filterValue.toLowerCase()),
      ),
    );
  }, [data, filterValue]);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center mb-4">
        <div className="flex-grow justify-start">
          <SearchField initialValue={filterValue} setSearchValue={setFilterValue} />
        </div>
        {filteredData && filteredData.length > 0 && (
          <div className="justify-end">
            <Button className="!w-[10.5rem]" onClick={() => handleNavigate(AppRoutes.createDomain)}>
              {t('DomainsPage.buttonNewDomain')}
            </Button>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : filteredData && filteredData.length > 0 ? (
        <Table
          columns={columns}
          data={filteredData ?? []}
          className={{
            row: 'h-[3.300rem]',
            cell: 'overflow-visible',
          }}
        />
      ) : (
        <NoDataBoard
          description={t('NoDataBoard.addYourFirstDomain')}
          actionButton={
            <Button className="!w-[10.5rem] mt-4" onClick={() => handleNavigate(AppRoutes.createDomain)}>
              {t('DomainsPage.buttonNewDomain')}
            </Button>
          }
        />
      )}
    </div>
  );
};

export default Domains;
