import { SVGProps } from 'react';

interface PaperPlaneIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const PaperPlaneIcon: React.FC<PaperPlaneIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g clipPath="url(#clip0_152_3213)">
        <path
          d="M4.60471 9.92396H11.4582M17.0549 11.3381L4.76168 17.8462C3.65999 18.4295 3.10884 18.7212 2.74557 18.6373C2.43048 18.5646 2.17012 18.3447 2.04655 18.0459C1.90406 17.7013 2.10104 17.109 2.49536 15.926L4.32744 10.4298C4.39001 10.2421 4.42101 10.1484 4.43344 10.0524C4.44446 9.96721 4.44501 9.88106 4.43398 9.79585C4.42184 9.70201 4.3912 9.61011 4.33141 9.43077L2.49511 3.92186C2.10079 2.7389 1.90377 2.1472 2.04626 1.80261C2.16983 1.50377 2.43014 1.28343 2.74524 1.21067C3.10856 1.12677 3.65987 1.41827 4.76191 2.0017L17.0551 8.50988C17.9215 8.96855 18.3548 9.1981 18.4964 9.50404C18.6198 9.77053 18.6199 10.0777 18.4965 10.3442C18.355 10.65 17.9218 10.8793 17.0563 11.3375L17.0549 11.3381Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_3213">
          <rect width="20" height="20" fill="white" transform="translate(0 0.196457)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaperPlaneIcon;
