import { SVGProps } from 'react';

interface ChevronIconProps extends SVGProps<SVGSVGElement> {
  type: string;
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

function ChevronIcon({
  color = '#eff3fa',
  width = '14',
  height = '8',
  viewBox = '0 0 14 8',
  type,
  ...props
}: ChevronIconProps) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {type === 'down' && (
        <path
          d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7 5.93934L12.4697 0.46967C12.7626 0.176777 13.2374 0.176777 13.5303 0.46967C13.8232 0.762563 13.8232 1.23744 13.5303 1.53033L7.53033 7.53033C7.23744 7.82322 6.76256 7.82322 6.46967 7.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z"
          fill={color}
        />
      )}
      {type === 'up' && (
        <path
          d="M6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967L13.5303 6.46967C13.8232 6.76256 13.8232 7.23744 13.5303 7.53033C13.2374 7.82322 12.7626 7.82322 12.4697 7.53033L7 2.06066L1.53033 7.53033C1.23744 7.82322 0.762563 7.82322 0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967L6.46967 0.46967Z"
          fill={color}
        />
      )}
      {type === 'left' && (
        <path
          d="M7.49995 12C7.49995 11.8082 7.57326 11.6162 7.7197 11.4698L15.2197 3.9698C15.5128 3.67673 15.9873 3.67673 16.2802 3.9698C16.5731 4.26286 16.5733 4.73742 16.2802 5.0303L9.31045 12L16.2802 18.9698C16.5733 19.2629 16.5733 19.7374 16.2802 20.0303C15.9871 20.3232 15.5126 20.3234 15.2197 20.0303L7.7197 12.5303C7.57326 12.3839 7.49995 12.1919 7.49995 12Z"
          fill={color}
        />
      )}
    </svg>
  );
}

export default ChevronIcon;
