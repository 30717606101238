import { SVGProps } from 'react';

interface AddPlusIconProps extends SVGProps<SVGSVGElement> {
  bgColor?: string;
  symbolColor?: string;
}

const AddPlusIcon: React.FC<AddPlusIconProps> = ({
  bgColor = 'rgba(73, 99, 180, 0.20)',
  symbolColor = '#7898FB',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="5" fill={bgColor} />
      <path
        d="M6 12H12M12 12H18M12 12V18M12 12V6"
        stroke={symbolColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddPlusIcon;
