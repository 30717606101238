import { useMutation } from 'react-query';
import axios from 'axios';

import { EditWorkspaceDetails } from '@/models/WorkspaceDetails';
import { useAuth } from '@/modules/Auth/hooks/useAuth';
import { AwsConfigApiGateway } from '@/utils/config/api';

async function updateWorkspace(workspaceData: EditWorkspaceDetails, token: string | undefined) {
  const response = await axios.put<EditWorkspaceDetails>(
    `${AwsConfigApiGateway.pooledTenantApiUrl}/workspaces`,
    workspaceData,
    {
      headers: {
        'x-api-key': AwsConfigApiGateway.pooledTenantApiKey,
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response.data;
}

export function useUpdateWorkspace() {
  const { user } = useAuth();

  return useMutation<EditWorkspaceDetails, Error, EditWorkspaceDetails>((workspaceData) =>
    updateWorkspace(workspaceData, user?.signInUserSession.idToken.jwtToken),
  );
}
