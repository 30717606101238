import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Badge from '@/component-library/primitives/Badge/Badge';
import ChevronNewIcon from '@/component-library/primitives/Icons/ChevronNewIcon/ChevronNewIcon';
import Tooltip from '@/component-library/primitives/Tooltip/Tooltip';

interface UsageBarProps {
  numberOfUsage: number;
  route: string;
  label: string;
  description: string;
}
const UsageBar: React.FC<UsageBarProps> = ({ numberOfUsage, route, label, description }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNumberHovered, setIsNumberHovered] = useState(false);
  return (
    <div
      className={`relative w-[49%] h-[87px] p-4 bg-darkBlue rounded-[14px] ${
        numberOfUsage === 0 ? 'opacity-50 pointer-events-none' : 'cursor-pointer'
      }`}
      onClick={() => navigate(route)}
    >
      <div className="flex flex-row items-center justify-between gap-x-4 h-full">
        <Tooltip
          side="bottom"
          content={
            <div className="text-gray-100 text-xs font-light">
              <Trans
                i18nKey="SegmentOverview.tooltipNumberOfUsage"
                components={{
                  primary: <span />,
                }}
                values={{
                  number: numberOfUsage,
                }}
              />
            </div>
          }
          open={isNumberHovered}
          onOpenChange={(open: boolean) => {
            if (open) {
              setIsNumberHovered(true);
            } else {
              setIsNumberHovered(false);
            }
          }}
          contentClassName="bg-custom-darkBlue p-4 rounded-lg z-50 w-50 text-center"
          arrowClassName="fill-custom-darkBlue w-5 h-2"
        >
          <div className="cursor-pointer">
            <Badge
              number={numberOfUsage}
              className={`${
                numberOfUsage > 0 ? 'bg-yellow-500' : 'bg-secondary-400'
              } text-gray-900 text-[10px] font-medium !h-[1.5rem] !w-[1.5rem]`}
            />
          </div>
        </Tooltip>
        <div className="flex flex-col w-3/4">
          <h2 className="text-secondary-400 text-sm mb-1">{label}</h2>
          <p className="text-gray-400 text-xs tracking-[0.24px]">{description}</p>
        </div>
        <ChevronNewIcon type="right" width="40" height="40" />
      </div>
    </div>
  );
};

export default UsageBar;
