import { SVGProps } from 'react';

interface CheckListIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CheckListIcon: React.FC<CheckListIconProps> = ({ color = '#9bafd0', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M9.16671 14.3632H16.6667M6.66671 12.6965L4.58337 15.1965L3.33337 14.3632M9.16671 10.1965H16.6667M6.66671 8.52983L4.58337 11.0298L3.33337 10.1965M9.16671 6.02983H16.6667M6.66671 4.36316L4.58337 6.86316L3.33337 6.02983"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckListIcon;
